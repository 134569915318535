import { createSelector } from 'reselect';
import { AviaPassengerType, BaggageType, CheckinOrderStatus, GdsServiceVipSubType, OrderAdditionalServiceGdsServiceServiceType, TravellerFieldEnum } from '@websky/graphql';
import { convertBaggageObject, convertMealObject } from '../../../../Checkout/types';
import { getTravellerName } from '../../utils';
import { get, MEAL_RECOMMENDATION_SHOWN } from '../../../../cache';
import { checkAllSeatsAreSelected } from '../../../../SeatMap/utils';
import { parseIncludedBaggage } from '../../../../BaggagePage/utils';
import { useConfig } from '../../../../context';
import { getIncludedBaggageFromOrderTravellers } from '../../../../Checkout/utils';
import { getHasRequestedServices, getTotalPrice as getTotalPriceUtil, getUserValue } from '../../../../utils';
const getState = (state) => state;
export const getCheckinOrder = createSelector(getState, ({ order }) => order);
export const getTravellers = createSelector(getCheckinOrder, order => { var _a; return (_a = order === null || order === void 0 ? void 0 : order.travellers) !== null && _a !== void 0 ? _a : []; });
export const getIsContactsInfoEmpty = createSelector(getCheckinOrder, ({ customer }) => customer.contactInfoIsEmpty);
export const getDeprecatedPassengersState = createSelector(getTravellers, travellers => travellers.map((traveller) => {
    var _a, _b, _c;
    return ({
        id: traveller.id,
        type: traveller.type,
        values: traveller.values,
        services: (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) !== null && _c !== void 0 ? _c : [],
        seats: traveller.services.seats &&
            traveller.services.seats.map(seat => {
                var _a;
                return ({
                    id: (_a = seat.seat.seatService) === null || _a === void 0 ? void 0 : _a.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Seat,
                    segmentId: seat.segment.id,
                    isConfirmed: seat.isConfirmed,
                    row: seat.row.toString(),
                    letter: seat.letter,
                    price: seat.seat.price,
                    number: seat.seat.number,
                    rfisc: seat.seat.rfisc
                });
            })
    });
}));
export const getTravellersWithVisa = createSelector(getCheckinOrder, ({ travellers }) => travellers.filter(traveller => traveller.visaRequired && traveller.visaValues.length));
export const getPassengerIdsWithLinkedPassengers = createSelector(getCheckinOrder, order => {
    const passengerIdsWithLinkedPassengersMap = new Map();
    order.travellers.forEach(traveller => {
        var _a;
        if (traveller.linkedTraveller) {
            const linkedTravellerId = traveller.linkedTraveller.id;
            passengerIdsWithLinkedPassengersMap.set(linkedTravellerId, [
                ...((_a = passengerIdsWithLinkedPassengersMap.get(linkedTravellerId)) !== null && _a !== void 0 ? _a : []),
                traveller
            ]);
        }
    });
    passengerIdsWithLinkedPassengersMap.forEach((travellers, travellerId) => {
        travellers.forEach(linkedTraveller => {
            const mainTraveller = order.travellers.find(traveller => traveller.id === travellerId);
            if (mainTraveller) {
                const linkedTravellers = [
                    ...travellers.filter(traveller => traveller.id !== linkedTraveller.id),
                    mainTraveller
                ];
                passengerIdsWithLinkedPassengersMap.set(linkedTraveller.id, linkedTravellers);
            }
        });
    });
    return passengerIdsWithLinkedPassengersMap;
});
export const getPassengersNames = createSelector(getTravellers, (travellers) => travellers.map(traveller => ({
    id: traveller.id,
    name: getTravellerName(traveller).toString()
})));
export const getInsurance = createSelector(getCheckinOrder, order => order.additionalServices.insurances);
export const getMinInsurancePrice = createSelector(getInsurance, (insurances) => {
    var _a, _b;
    return ({
        amount: Math.min(...insurances.insurancePrograms.map(program => program.totalPrice.amount)),
        currency: (_b = (_a = insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.currency
    });
});
export const getTotalInsurancePrice = createSelector(getInsurance, insurances => {
    var _a;
    if ((_a = insurances.selectedInsurances) === null || _a === void 0 ? void 0 : _a.length) {
        return getTotalPriceUtil(insurances.selectedInsurances, insurance => insurance.passengerPrice);
    }
    return null;
});
export const getConfirmedInsurances = createSelector(getInsurance, (insurance) => insurance.selectedInsurances.filter(insurance => !!insurance.policyUrl));
export const getTotalPrice = createSelector(getCheckinOrder, ({ priceToPay }) => (Object.assign({}, priceToPay.total)));
export const getCheckinOrderId = createSelector(getCheckinOrder, ({ id }) => id);
export const getCheckinSegments = createSelector([getCheckinOrder], order => order.segments);
export const getCheckinNotStarted = createSelector(getState, ({ order }) => order.status === CheckinOrderStatus.New);
export const getCheckinIsBlockedForPayment = createSelector(getCheckinOrder, ({ isBlockedForPayment }) => isBlockedForPayment);
export const getCheckinHasStarted = createSelector(getState, ({ order }) => (order === null || order === void 0 ? void 0 : order.status) === CheckinOrderStatus.Started);
export const getPriceToPay = createSelector(getCheckinOrder, ({ priceToPay }) => priceToPay);
export const getTotalPriceToPay = createSelector(getPriceToPay, ({ total }) => total !== null && total !== void 0 ? total : { amount: 0, currency: total.currency });
export const getCurrentCurrencyFromCheckinOrder = createSelector(getTotalPrice, totalPrice => totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.currency);
export const getInsuranceMinOrTotalPrice = createSelector(getInsurance, getMinInsurancePrice, getCurrentCurrencyFromCheckinOrder, (insurances, insuranceMinPrice, currentCurrency) => {
    var _a, _b;
    const currency = (_b = (_a = insurances.insurancePrograms[0]) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.currency;
    let price;
    if (insurances.selectedInsurances.length) {
        price = {
            currency: currency !== null && currency !== void 0 ? currency : currentCurrency,
            amount: insurances.selectedInsurances.reduce((previousValue, selectedInsurance) => previousValue + selectedInsurance.insuranceProgram.totalPrice.amount, 0)
        };
        return price;
    }
    return insuranceMinPrice;
});
export const getNeedToFillContactsInfo = createSelector(getTotalPriceToPay, getIsContactsInfoEmpty, (totalPrice, contactsInfoIsEmpty) => {
    console.log(totalPrice, contactsInfoIsEmpty);
    return (totalPrice === null || totalPrice === void 0 ? void 0 : totalPrice.amount) > 0 && contactsInfoIsEmpty;
});
export const getCheckinInfoParams = createSelector(getCheckinOrder, ({ travellers }) => {
    var _a, _b;
    return ({
        secret: getUserValue(travellers[0], TravellerFieldEnum.LastName),
        id: (_b = (_a = travellers[0]) === null || _a === void 0 ? void 0 : _a.tickets[0]) === null || _b === void 0 ? void 0 : _b.number
    });
});
export const getCheckinHasCompleted = createSelector(getState, ({ order }) => order.status === CheckinOrderStatus.Confirmed);
export const isExpired = createSelector(getState, ({ order }) => order.status === CheckinOrderStatus.Expired);
export const getAllServices = createSelector(getCheckinOrder, ({ additionalServices }) => { var _a, _b; return (_b = (_a = additionalServices === null || additionalServices === void 0 ? void 0 : additionalServices.gdsServices) === null || _a === void 0 ? void 0 : _a.services) !== null && _b !== void 0 ? _b : []; });
export const makeGetGdsServicesByType = (type) => createSelector([getAllServices], checkinGdsServices => {
    return checkinGdsServices.filter(service => service.type === type);
});
export const getExtraSeatServices = createSelector(getAllServices, services => services.filter(service => service.rfisc === 'EXST' &&
    service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
    (service === null || service === void 0 ? void 0 : service.subType) ===
        GdsServiceVipSubType.ExtraSeat));
export const getUpgradeServices = createSelector(getAllServices, services => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Vip &&
    service.subType ===
        GdsServiceVipSubType.Upgrade));
export const getBaggageServices = createSelector(getAllServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Baggage));
export const getMealServices = createSelector(getAllServices, (services) => services.filter(service => service.type === OrderAdditionalServiceGdsServiceServiceType.Meal));
export const getMeals = createSelector(getMealServices, (meals) => meals.map(convertMealObject));
export const getBaggage = createSelector(getBaggageServices, (baggage) => baggage.map(convertBaggageObject));
export const getRecommendedMeals = createSelector(getMeals, getTravellers, (meals, travellers) => {
    const result = [];
    travellers.forEach(traveller => {
        var _a, _b;
        (_b = (_a = traveller.preselectedServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.forEach(service => {
            const meal = meals.find(meal => meal.id === service.serviceId);
            if (meal) {
                result.push({
                    meal,
                    travellerId: traveller.id,
                    segmentIds: service.segmentIds,
                    count: service.count
                });
            }
        });
    });
    return result;
});
export const showMealRecommendations = createSelector(getCheckinOrderId, getRecommendedMeals, getTravellers, (orderId, recommendedMeals, passengers) => !!recommendedMeals.length &&
    !passengers.some(passenger => {
        var _a, _b;
        return (_b = (_a = passenger.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.some(service => recommendedMeals.some(meal => meal.meal.id === service.serviceId));
    }) &&
    !get(`${MEAL_RECOMMENDATION_SHOWN}_${orderId}`));
export const getSeatMap = createSelector(getCheckinOrder, ({ seatMap }) => seatMap);
export const getIsSeatMapUnavailable = createSelector(getSeatMap, getTravellers, (seatMap, travellers) => {
    const hasSegmentsWithZeroAvailableSeats = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments.some(segment => !segment.availableSeatsCount);
    const allTravellersHaveNotSeats = travellers.every(traveller => { var _a, _b; return !((_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b.length); });
    return hasSegmentsWithZeroAvailableSeats && allTravellersHaveNotSeats;
});
export const getInsurances = createSelector(getCheckinOrder, ({ additionalServices }) => additionalServices.insurances);
export const getTransfer = createSelector(getCheckinOrder, ({ additionalServices }) => additionalServices.transfer);
export const hasBaggageServices = createSelector(getBaggageServices, baggage => !!baggage.length);
export const hasUpgradeServices = createSelector(getUpgradeServices, upgradeServices => upgradeServices.some(service => service.canBeAdded));
export const hasMealServices = createSelector(getMealServices, meal => !!meal.length);
export const hasSeatMap = createSelector(getSeatMap, seatMap => {
    var _a, _b, _c;
    return !!((_a = seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(segment => segment.isAnySeatExistsAndFree)) ||
        !!((_c = (_b = seatMap.additionalServices.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.length);
});
export const getHasAvailableSeats = createSelector(getSeatMap, seatMap => { var _a; return (_a = seatMap === null || seatMap === void 0 ? void 0 : seatMap.segments) === null || _a === void 0 ? void 0 : _a.some(segment => segment === null || segment === void 0 ? void 0 : segment.availableSeatsCount); });
export const hasInsurances = createSelector(getInsurances, insurances => { var _a; return !!((_a = insurances === null || insurances === void 0 ? void 0 : insurances.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length); });
export const hasTransfers = createSelector(getTransfer, transfer => { var _a, _b; return !!((_b = (_a = transfer === null || transfer === void 0 ? void 0 : transfer.aeroexpress) === null || _a === void 0 ? void 0 : _a.trips) === null || _b === void 0 ? void 0 : _b.length); });
export const getSegments = createSelector(getCheckinOrder, order => order.segments);
export const getFareFamiliesMap = createSelector(getSegments, segmentsFromOrder => {
    const fareFamiliesMap = new Map();
    segmentsFromOrder.forEach(({ id, fareFamilies }) => {
        const fareFamily = fareFamilies.length > 0 ? fareFamilies[0].fareFamily : null;
        if (fareFamily) {
            if (!fareFamiliesMap.has(fareFamily.id)) {
                fareFamiliesMap.set(fareFamily.id, {
                    fareFamily,
                    segmentIds: []
                });
            }
            fareFamiliesMap.get(fareFamily.id).segmentIds.push(id);
        }
    });
    return fareFamiliesMap;
});
export const getCheckinIncludedBaggage = createSelector(getFareFamiliesMap, getTravellers, useConfig, (fareFamiliesMap, travellers, config) => {
    var _a, _b;
    if (((_b = (_a = config === null || config === void 0 ? void 0 : config.global) === null || _a === void 0 ? void 0 : _a.companyInfo) === null || _b === void 0 ? void 0 : _b.iataCode) === 'N4') {
        // filter out all included baggage for INF and INS
        const travellersWithoutInfants = travellers.filter(traveller => traveller.type !== AviaPassengerType.INF && traveller.type !== AviaPassengerType.INS);
        return fareFamiliesMap
            ? parseIncludedBaggage(fareFamiliesMap, travellersWithoutInfants.map(({ id }) => id))
            : [];
    }
    return parseIncludedBaggage(fareFamiliesMap, travellers.map(({ id }) => id));
});
export const getCheckinBrandIncludedBaggage = createSelector(getTravellers, travellers => {
    return getIncludedBaggageFromOrderTravellers(travellers.map(traveller => (Object.assign(Object.assign({}, traveller), { needOthsSsr: null, fares: [] }))));
});
export const getSegmentsForServices = createSelector(getSegments, (segments) => segments);
export const getPriceBreakdown = createSelector(getCheckinOrder, ({ priceBreakdown }) => priceBreakdown);
export const getMealsPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, ({ priceToPay }, currentCurrency) => priceToPay.meals ? Object.assign(Object.assign({}, priceToPay.meals), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency });
export const getBaggagePrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, ({ priceToPay }, currentCurrency) => priceToPay.baggage
    ? Object.assign(Object.assign({}, priceToPay.baggage), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency });
export const getSeatsPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, ({ priceToPay }, currentCurrency) => priceToPay.seats ? Object.assign(Object.assign({}, priceToPay.seats), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency });
export const getInsurancePrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, ({ priceToPay }, currentCurrency) => priceToPay.insurance
    ? Object.assign(Object.assign({}, priceToPay.insurance), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency });
export const getAeroexpressPrice = createSelector(getCheckinOrder, getCurrentCurrencyFromCheckinOrder, ({ priceToPay }, currentCurrency) => priceToPay.aeroexpress
    ? Object.assign(Object.assign({}, priceToPay.aeroexpress), { currency: currentCurrency }) : { amount: 0, currency: currentCurrency });
export const getHasSelectedAeroexpress = createSelector(getCheckinOrder, order => { var _a, _b, _c, _d; return !!((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.length); });
export const getHasConfirmedAeroexpress = createSelector(getCheckinOrder, order => {
    var _a, _b, _c, _d;
    return !!((_d = (_c = (_b = (_a = order === null || order === void 0 ? void 0 : order.additionalServices) === null || _a === void 0 ? void 0 : _a.transfer) === null || _b === void 0 ? void 0 : _b.aeroexpress) === null || _c === void 0 ? void 0 : _c.selectedTrips) === null || _d === void 0 ? void 0 : _d.some(trip => { var _a; return (_a = trip === null || trip === void 0 ? void 0 : trip.tickets) === null || _a === void 0 ? void 0 : _a.some(ticket => ticket === null || ticket === void 0 ? void 0 : ticket.imageUrl); }));
});
export const getExtrasPrice = createSelector(getAeroexpressPrice, getInsurancePrice, getCurrentCurrencyFromCheckinOrder, (aeroexpress, insurance, currentCurrency) => ({
    amount: aeroexpress.amount + insurance.amount,
    currency: currentCurrency
}));
export const getIsAllTravellersHavePreseating = createSelector(getCheckinOrder, order => order.allTravellersHavePreseating);
export const getIsCheckinCompletedOrPreseating = createSelector(getCheckinHasCompleted, getIsAllTravellersHavePreseating, (isCheckinCompleted, isAllTravellersHavePreseating) => isCheckinCompleted || isAllTravellersHavePreseating);
export const getConfirmedServices = createSelector(getTravellers, (travellers) => {
    const result = {};
    travellers.forEach(traveller => {
        var _a, _b, _c;
        const services = (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) !== null && _c !== void 0 ? _c : [];
        services.forEach(({ confirmedCount, serviceId, segmentIds }) => {
            if (confirmedCount > 0) {
                if (!result[traveller.id]) {
                    result[traveller.id] = {};
                }
                if (!result[traveller.id][serviceId]) {
                    result[traveller.id][serviceId] = {};
                }
                segmentIds.forEach(segmentId => {
                    result[traveller.id][serviceId][segmentId] = confirmedCount;
                });
            }
        });
    });
    return result;
});
export const getAllRequiredSeatsAreSelected = createSelector(getDeprecatedPassengersState, getSeatMap, (passengersState, seatMap) => checkAllSeatsAreSelected(passengersState, seatMap.segments.filter(segment => segment.availableSeatsCount > 0).length));
export const getUpgradeBaggage = createSelector(getBaggage, getTravellers, (baggageItems, travellers) => {
    const travellersByIdMap = new Map(travellers.map(traveller => {
        var _a, _b;
        if (!((_b = (_a = traveller.services.gdsServices) === null || _a === void 0 ? void 0 : _a.services) === null || _b === void 0 ? void 0 : _b.length)) {
            return [traveller.id, null];
        }
        const travellerGdsServicesById = new Map(traveller.services.gdsServices.services.map(service => [
            service.serviceId,
            service.count > service.confirmedCount
        ]));
        return [traveller.id, travellerGdsServicesById];
    }));
    const isCanBeAddedOrRemoved = (item) => {
        const canBeRemoved = item.allowedPassengers.some(passengerId => { var _a; return (_a = travellersByIdMap.get(passengerId)) === null || _a === void 0 ? void 0 : _a.get(item.id); });
        return item.canBeAdded || canBeRemoved;
    };
    return baggageItems.filter(item => {
        return item.type === BaggageType.HandLuggageExcess && isCanBeAddedOrRemoved(item);
    });
});
export const getAdditionalBaggage = createSelector(getBaggage, items => {
    return items.filter(item => item.type === BaggageType.CheckedBaggage);
});
export const getSpecialEquipment = createSelector(getBaggage, items => {
    return items.filter(item => [
        BaggageType.SportingEquipment,
        BaggageType.PetInCabin,
        BaggageType.PetInHold,
        BaggageType.MusicalInstrument
    ].includes(item.type));
});
export const getOverweightBaggage = createSelector(getBaggage, items => items.filter(item => item.type === BaggageType.BaggageExcess));
export const getIsTravellerDataFilled = createSelector(getCheckinOrder, order => order.isTravellersDataFilled);
export const getCheckinPassengerLabels = createSelector(getPassengersNames, (passengers) => {
    const result = {};
    passengers.forEach(passenger => {
        result[passenger.id] = passenger.name;
    });
    return result;
});
export const getHasRequestedOrderServices = createSelector(getCheckinHasStarted, getTravellers, (checkinStarted, travellers) => {
    return checkinStarted && getHasRequestedServices(travellers);
});
export const getIsSkipSeatSelectionAllowed = createSelector(getCheckinOrder, order => order.isSkipSeatSelectionAllowed);
