import { __awaiter } from "tslib";
import { useSteps } from '../../../utils';
import { CheckinStep } from '../../../types';
import { useSelector } from 'react-redux';
import { getCheckinIsBlockedForPayment, getCheckinOrderId } from '../../../store/order/selectors';
import { useGetCheckinOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
export const useUnlockCheckinOrder = (setLoading) => {
    const checkinIsBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    const orderId = useSelector(getCheckinOrderId);
    const { refetch } = useGetCheckinOrderQuery({ skip: true });
    const { setStep, goToNextStep } = useSteps();
    const [resetOrderPaymentBlock] = useResetOrderPaymentBlockMutation();
    return () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        // Order is blocked for now. Let's unlock it, so users can add and remove services.
        if (checkinIsBlockedForPayment) {
            setLoading === null || setLoading === void 0 ? void 0 : setLoading(true);
            const orderIsUnlocked = (_b = (_a = (yield resetOrderPaymentBlock({
                variables: {
                    id: orderId
                }
            }))) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.ResetOrderPaymentBlock;
            if (orderIsUnlocked) {
                // Fetch new order info.
                yield refetch({ id: orderId });
                // Redirect to services page.
                setStep(CheckinStep.Baggage);
            }
            setLoading === null || setLoading === void 0 ? void 0 : setLoading(false);
        }
        else {
            goToNextStep(CheckinStep.Passengers);
        }
    });
};
