import memes from '../../../memes';
import { applyMiddleware, createStore as originalCreateStore } from 'redux';
import { upgradeModalReducer } from './reducers';
import { enableBatching } from 'redux-batched-actions';
import { detectDifferentForReturnFlight } from '../utils';
export const createStore = memes((services, segments, passengers, selectedServices, maxBaggageCount, maxBaggagePerPassengerCount, baggageTab, disabled = false) => {
    const middleware = [];
    if (process.env.NODE_ENV === 'development') {
        middleware.push(require('redux-logger').default);
    }
    const store = originalCreateStore(enableBatching(upgradeModalReducer), {
        services,
        selectedServices: selectedServices,
        differentForReturnFlight: detectDifferentForReturnFlight(segments, selectedServices),
        disabled,
        maxBaggagePerPassengerCount,
        maxBaggageCount,
        baggageTab,
        passengers,
        segmentIds: segments.map(segment => segment.id)
    }, applyMiddleware(...middleware));
    return store;
});
