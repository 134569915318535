import i18n from 'i18next';
import { validate } from '../Passenger/utils';
import { TravellerFields } from '../TravellerForm/types';
import { TravellerFieldEnum, DocTypes } from '@websky/graphql';
import { validateDisabilities } from './components/components/Disabilities/utils';
import { PassengerType } from '../enums';
import { format, parseISO } from 'date-fns';
export const requiredFillData = (passengerFormValues, customerFormValues, passengerValues, accompanyingValues) => {
    return (passengerValues.some(field => {
        return !!validate(passengerFormValues[field.name], passengerFormValues, field.input, field.validations);
    }) ||
        (accompanyingValues === null || accompanyingValues === void 0 ? void 0 : accompanyingValues.some(field => {
            return !!validate(customerFormValues[field.name], customerFormValues, field.input, field.validations);
        })) ||
        !!validateDisabilities(passengerFormValues));
};
export const getIsChildOrInfantPassenger = (passenger) => {
    if (!(passenger === null || passenger === void 0 ? void 0 : passenger.type)) {
        return false;
    }
    return [PassengerType.Child, PassengerType.Infant, PassengerType.InfantWithSeat].includes(passenger.type);
};
export const getPassengerDataLabel = (passengerValues, fieldsMap) => {
    var _a, _b, _c, _d, _e, _f;
    const labels = {};
    if (passengerValues.hasOwnProperty(TravellerFields.Gender)) {
        labels[TravellerFields.Gender] =
            passengerValues[TravellerFields.Gender] === 'M' ? i18n.t('Passenger:Male') : i18n.t('Passenger:Female');
    }
    labels[TravellerFields.BirthDate] = passengerValues[TravellerFields.BirthDate];
    if (passengerValues.hasOwnProperty(TravellerFields.Nationality)) {
        labels[TravellerFields.Nationality] = (_c = (_b = (_a = fieldsMap[TravellerFields.Nationality].validations) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.options.find(option => option.value === passengerValues[TravellerFields.Nationality])) === null || _c === void 0 ? void 0 : _c.label;
    }
    if (passengerValues.hasOwnProperty('docType')) {
        const docTypeValue = passengerValues['docType'];
        const docType = (_f = (_e = (_d = fieldsMap['docType'].validations
            .find(validation => validation.with === null ||
            validation.with.some(cond => passengerValues[cond.name] === cond.value))) === null || _d === void 0 ? void 0 : _d.options.find(option => option.value === docTypeValue)) === null || _e === void 0 ? void 0 : _e.value) !== null && _f !== void 0 ? _f : docTypeValue;
        labels['docType'] = i18n.t(`Passenger:${docType}`);
        if (labels['docType'] && passengerValues.hasOwnProperty('docNumber')) {
            labels['docNumber'] = passengerValues['docNumber'];
        }
    }
    if (passengerValues.hasOwnProperty('docExpiryDate')) {
        labels['docExpiryDate'] = passengerValues['docExpiryDate'];
    }
    if (passengerValues.hasOwnProperty('loyaltyNumber')) {
        labels['loyaltyNumber'] = passengerValues['loyaltyNumber'];
    }
    return labels;
};
export const getProfileValues = (profile) => {
    const profileValuesMap = new Map();
    profile.values.forEach(value => {
        let actualValue = value.value;
        if (value.type === TravellerFieldEnum.Gender && value.value) {
            actualValue = value.value[0];
        }
        profileValuesMap.set(value.name, actualValue);
    });
    return profileValuesMap;
};
export const getActualDocumentType = (passengers, profileValuesMap, id, document, isInternationalFlight) => {
    var _a;
    let actualDocumentType = document;
    if (!actualDocumentType) {
        if (getIsChildOrInfantPassenger(passengers[id])) {
            actualDocumentType = DocTypes.BirthRegDocument;
        }
        else {
            if (isInternationalFlight) {
                if (profileValuesMap.get(TravellerFields.Nationality) === 'RU') {
                    actualDocumentType = DocTypes.InternationalPassportRU;
                }
                else {
                    actualDocumentType = DocTypes.NationalPassport;
                }
            }
            else {
                if (((_a = passengers[id]) === null || _a === void 0 ? void 0 : _a.type) === PassengerType.Child) {
                    actualDocumentType = DocTypes.BirthRegDocument;
                }
                else {
                    if (profileValuesMap.get(TravellerFields.Nationality) === 'RU') {
                        actualDocumentType = DocTypes.Passport;
                    }
                    else {
                        actualDocumentType = DocTypes.NationalPassport;
                    }
                }
            }
        }
    }
    return actualDocumentType;
};
export const getActualPassengerValues = (passengers, profile, id, document, isInternationalFlight, isFreezeOrder) => {
    const profileValuesMap = getProfileValues(profile);
    const actualDocumentType = getActualDocumentType(passengers, profileValuesMap, id, document, isInternationalFlight);
    const documentByType = profile.documents.find(document => document.type === actualDocumentType);
    if (documentByType && !isFreezeOrder) {
        documentByType.values.forEach(doc => {
            var _a, _b;
            if (doc.value) {
                let actualValue = doc.value;
                if (doc.type === TravellerFieldEnum.DocExpiryDate) {
                    const isRequired = (_b = (_a = doc.validationRules) === null || _a === void 0 ? void 0 : _a.find(rule => { var _a, _b; return ((_b = (_a = rule.with) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.value) === documentByType.type; })) === null || _b === void 0 ? void 0 : _b.required;
                    if (isRequired) {
                        actualValue = format(parseISO(doc.value), 'dd.MM.yyyy');
                    }
                    else {
                        actualValue = '';
                    }
                }
                profileValuesMap.set(doc.name, actualValue);
            }
            else {
                profileValuesMap.set(doc.name, '');
            }
        });
    }
    return profileValuesMap;
};
export const getEditableFields = (fields, passengerId, values, dirtyFields, isChangePassengers) => {
    var _a;
    if (isChangePassengers && ((_a = Object.keys(dirtyFields !== null && dirtyFields !== void 0 ? dirtyFields : {})) === null || _a === void 0 ? void 0 : _a.length)) {
        // to remove the readonly attribute from fields with a null value
        const nullableFields = fields
            .filter(field => { var _a, _b; return !field.defaultValue && !((_b = (_a = values.passengers) === null || _a === void 0 ? void 0 : _a[passengerId]) === null || _b === void 0 ? void 0 : _b[field.name]); })
            .map(field => `passengers[${passengerId}].${field.name}`);
        const includesNullableFields = Object.keys(dirtyFields).every(key => nullableFields.includes(key));
        if (!includesNullableFields) {
            return fields.map(field => {
                const fieldName = `passengers[${passengerId}].${field.name}`;
                const isNullableField = nullableFields.includes(fieldName);
                const isDirtyField = !isNullableField && (dirtyFields === null || dirtyFields === void 0 ? void 0 : dirtyFields[fieldName]);
                return Object.assign(Object.assign({}, field), { readonly: !isDirtyField });
            });
        }
    }
    return fields;
};
export function isNameFieldType(type, types = []) {
    const isFirstName = type === TravellerFieldEnum.FirstName || types.includes(TravellerFieldEnum.FirstName);
    const isLastName = type === TravellerFieldEnum.LastName || types.includes(TravellerFieldEnum.LastName);
    const isMiddleName = type === TravellerFieldEnum.MiddleName || types.includes(TravellerFieldEnum.MiddleName);
    return isFirstName || isLastName || isMiddleName;
}
