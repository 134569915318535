import { all, put, takeLatest } from 'redux-saga/effects';
import { FILL_CHECKIN_ORDER, setCheckinOrder } from './order/actions';
import { setSelectedServices } from './selectedServices/actions';
import { saveServicesSaga } from './selectedServices/sagas';
import getOrderDataLayer from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
export function* fillOrderSagaWorker(action) {
    getOrderDataLayer(OrderType.Checkin).setOrder(action.payload);
    yield put(setCheckinOrder(action.payload));
    yield put(setSelectedServices(action.payload.travellers));
}
export function* fillOrderSaga() {
    yield takeLatest(FILL_CHECKIN_ORDER, fillOrderSagaWorker);
}
export function* rootSaga() {
    yield all([fillOrderSaga(), saveServicesSaga()]);
}
