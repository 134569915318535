import { __rest } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import { useTheme } from '../theme';
const successIcon = (React.createElement("svg", { width: "24", height: "24", viewBox: "0 0 24 24", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M9.00039 16.1996L4.80039 11.9996L3.40039 13.3996L9.00039 18.9996L21.0004 6.99961L19.6004 5.59961L9.00039 16.1996Z", fill: "white" })));
export const Button = props => {
    const { variant = 'common', size = 'common', isLoading = false, isSuccess = false, children, className } = props, rest = __rest(props, ["variant", "size", "isLoading", "isSuccess", "children", "className"]);
    const { Button: css } = useTheme('Button');
    if (isLoading || isSuccess) {
        return (React.createElement("div", { className: cn({
                [css.loader]: isLoading,
                [css.loader_outline]: variant === 'outline',
                [css.success]: isSuccess
            }, className) },
            isLoading && React.createElement("div", { className: css.loader__spinner }),
            isSuccess && successIcon));
    }
    return (React.createElement(ButtonBase, Object.assign({ className: cn(css.button, {
            [css.button_outline]: variant === 'outline',
            [css.button_flat]: variant === 'flat',
            [css.button_text]: variant === 'text',
            [css.button_small]: size === 'small',
            [css.button_large]: size === 'large'
        }, className) }, rest), children));
};
Button.displayName = 'Button';
export default Button;
