import { currentCurrency } from '../../../utils';
import { handleAction } from 'redux-actions';
import { setOrderPrice } from './actions';
const zeroPrice = {
    amount: 0,
    currency: currentCurrency
};
export const orderPriceReducer = handleAction(setOrderPrice, (state, action) => action.payload, {
    price: {
        aeroexpress: zeroPrice,
        airticket: zeroPrice,
        baggage: zeroPrice,
        charge: zeroPrice,
        insurance: zeroPrice,
        meals: zeroPrice,
        seats: zeroPrice,
        total: zeroPrice
    },
    priceToPay: {
        aeroexpress: zeroPrice,
        airticket: zeroPrice,
        baggage: zeroPrice,
        charge: zeroPrice,
        insurance: zeroPrice,
        meals: zeroPrice,
        seats: zeroPrice,
        total: zeroPrice
    }
});
