import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import { Tooltip } from '../../../index';
import { getAppleWalletIconUrl } from '../../../utils';
import SelectSegments from '../../../SelectSegments';
import Notification from '../../../Notification';
import { InfoMessageTypeEnum } from '@websky/graphql';
import { InfoDetails, FileDownload } from '../../../Icons';
const Controls = ({ className, segments, canDownloadBoardingPass, onBoardingPassDownload, onAppleWalletPassDownload, setSegmentsFailedBoardingPasses }) => {
    const { Controls: css } = useTheme('RegisteredPassenger');
    const { t } = useTranslation('RegisteredPassenger');
    const [boardingPassLoading, setBoardingPassLoading] = useState(false);
    const [appleWalletLoading, setAppleWalletLoading] = useState(false);
    const handleDownloadResult = (downloadResult, segmentId, segments) => {
        const segmentsIdsToAdd = segmentId ? [segmentId] : segments.map(segment => segment.id);
        if (!downloadResult) {
            setSegmentsFailedBoardingPasses(prevState => new Set([...prevState, ...segmentsIdsToAdd]));
        }
    };
    const onBoardingPassClickHandler = useCallback((segmentId) => () => __awaiter(void 0, void 0, void 0, function* () {
        setBoardingPassLoading(true);
        try {
            const result = yield onBoardingPassDownload(segmentId);
            handleDownloadResult(result, segmentId, segments);
        }
        catch (e) {
            console.error(e);
        }
        setBoardingPassLoading(false);
    }), [onBoardingPassDownload, segments, handleDownloadResult]);
    const onAppleWalletPassClickHandler = useCallback((segmentId) => () => __awaiter(void 0, void 0, void 0, function* () {
        setAppleWalletLoading(true);
        try {
            const result = yield onAppleWalletPassDownload(segmentId);
            handleDownloadResult(result, segmentId, segments);
        }
        catch (e) {
            console.error(e);
        }
        setAppleWalletLoading(false);
    }), [onAppleWalletPassDownload, segments, handleDownloadResult]);
    const appleWalletUrl = getAppleWalletIconUrl();
    const hasSegmentWithAstra = segments.some(segment => segment.checkinInfo.airportHasDcs);
    return (React.createElement("div", { className: cn(css.container, className) },
        canDownloadBoardingPass && onBoardingPassDownload && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: hasSegmentWithAstra ? segments : [], onClick: onBoardingPassClickHandler(null), renderTrigger: ({ onClick, isLoading }) => (React.createElement(Button, { variant: "outline", className: css.control, onClick: onClick, disabled: isLoading }, t('Boarding pass'))), renderButton: segment => {
                const canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                if (!canDownloadBoardingPass) {
                    return (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement(Button, { className: css.controlDownload, onClick: () => null, disabled: true }, FileDownload)));
                }
                return (React.createElement(Button, { className: css.controlDownload, onClick: onBoardingPassClickHandler(segment.id), isLoading: boardingPassLoading }, FileDownload));
            } })),
        canDownloadBoardingPass && onAppleWalletPassDownload && (React.createElement(SelectSegments, { title: t('Select flight for download'), segments: segments, onClick: onAppleWalletPassClickHandler(null), renderTrigger: ({ onClick, isLoading }) => (React.createElement("div", { className: cn(css.controlImg, {
                    [css.controlImg__disabled]: isLoading
                }) },
                React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: onClick }))), renderButton: segment => {
                const canDownloadBoardingPass = segment.checkinInfo.airportHasDcs;
                if (!canDownloadBoardingPass) {
                    return (React.createElement(Tooltip, { title: t('Registration available only at airport') },
                        React.createElement("div", { className: cn(css.controlImg, css.controlImg__disabled) },
                            React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: () => null }))));
                }
                return (React.createElement("div", { className: cn(css.controlImg, {
                        [css.controlImg__disabled]: appleWalletLoading
                    }) },
                    React.createElement("img", { src: appleWalletUrl, alt: "Add to Apple Wallet", onClick: onAppleWalletPassClickHandler(segment.id) })));
            } })),
        !canDownloadBoardingPass && (React.createElement(Notification, { icon: InfoDetails, status: InfoMessageTypeEnum.Info, type: "context", infoMessage: {
                text: t('You must receive the boarding pass at the airport on the registration desk.'),
                type: InfoMessageTypeEnum.Info,
                code: 'astra-info'
            } }))));
};
export default Controls;
