import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { Money } from '../../../Money';
import { BaseButton } from '../../../index';
const BaggageCard = ({ className, classNameIcon, icon, title, description, children, hasSelected, fromPrice, totalPrice, onAdd, onEdit, readOnly }) => {
    const { BaggageCard: css } = useTheme('BaggageCard');
    const { t } = useTranslation('IncludedBaggage');
    const onClick = () => (hasSelected ? onEdit === null || onEdit === void 0 ? void 0 : onEdit() : onAdd === null || onAdd === void 0 ? void 0 : onAdd());
    return (React.createElement("div", { className: cn(className, css.wrapper) },
        React.createElement("div", { className: cn(css.header, {
                [css.selected]: hasSelected
            }) },
            !hasSelected && React.createElement("div", { className: cn(css.icon, classNameIcon) }, icon),
            React.createElement("div", { className: css.info },
                title && React.createElement("div", { className: css.title, dangerouslySetInnerHTML: { __html: title } }),
                !hasSelected && description && (React.createElement("span", { className: css.description, dangerouslySetInnerHTML: { __html: description } })))),
        children,
        React.createElement("div", { className: css.footer },
            React.createElement("span", { className: css.price },
                hasSelected ? t('total') : t('from'),
                React.createElement(Money, { money: hasSelected ? totalPrice : fromPrice, roundType: "ceil" })),
            React.createElement(BaseButton, { variant: "optionEdit", className: cn(css.button, {
                    [css.button_edit]: hasSelected && !readOnly
                }), onClick: onClick }, readOnly ? t('Checkout:More details') : t(hasSelected ? 'Edit' : 'Add')))));
};
export default BaggageCard;
