import * as React from 'react';
import CheckoutAdditionalService from '../../CheckoutAdditionalService/CheckoutAdditionalService';
import { useServicePageProps } from '../hooks';
import { useUpsaleWarning } from '../../../Checkout/components/Checkout/Upsale/hooks';
import { ServiceType } from '../../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { useSelector } from 'react-redux';
import { getMealMinPrice, getMealServices, getOrder, getServicesFallbackURL, shouldRedirectToFallback } from '../../../Checkout/store/order/selectors';
import Warning from '../../../Checkout/components/Checkout/Upsale/Warning/Warning';
import ServicePage from '../../../Checkout/components/Checkout/ServicePage/ServicePage';
import ServiceCardDialog from '../../../Checkout/components/Checkout/OrderPaidServiceCards/components/ServiceCardDialog/ServiceCardDialog';
import { useTranslation } from 'react-i18next';
import { getMealsPrice } from '../../../Checkout/store/orderPrice/selectors';
import { useTheme } from '../../../theme';
import icons from '../../icons';
import { useRenderers } from '../../../renderProps';
import MealSummary from '../../../ServiceSummary/services/MealSummary';
import { getTravellersMap } from '../../../utils';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
import { useIsCanModifyServices } from '../../hooks';
const MealAdditionalService = () => {
    var _a;
    const servicePageProps = useServicePageProps(ServiceType.Meal);
    const servicesRedirectURL = useSelector(getServicesFallbackURL);
    const shouldRedirect = useSelector(shouldRedirectToFallback);
    const { showWarning, closeWarning, toggleWarningIfOrderConfirmed } = useUpsaleWarning();
    const handleClick = shouldRedirect
        ? toggleWarningIfOrderConfirmed
        : servicePageProps.toggleModal.bind(null, ServiceType.Meal);
    const { t } = useTranslation('Checkout');
    const minPrice = useSelector(getMealMinPrice);
    const issuedPrice = useSelector(getMealsPrice);
    const mealServices = useSelector(getMealServices);
    const { CheckoutAdditionalServiceMeal: css } = useTheme('CheckoutAdditionalService');
    const { onDeleteItem } = useCart();
    const isCanModifyServices = useIsCanModifyServices(mealServices);
    const order = useSelector(getOrder);
    const buttons = mealServices.length
        ? [
            {
                text: isCanModifyServices ? t('Change') : t('More details'),
                action: servicePageProps.toggleModal.bind(null, ServiceType.Meal),
                buttonType: 'change'
            }
        ]
        : [];
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    return (React.createElement(React.Fragment, null,
        React.createElement(MealSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onDeleteItem }, content => (React.createElement(ServiceWrapper, { className: css.meal, icon: icons.mealIcon, addClick: showWarning ? toggleWarningIfOrderConfirmed : handleClick, buttons: buttons, header: t('Meal'), priceFrom: minPrice, description: t('Feel the taste in flight!'), isSelected: !!content, priceTotal: issuedPrice.amount ? issuedPrice : null, addButtonText: !isCanModifyServices ? t('More details') : null }, content))),
        React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
        React.createElement(ServicePage, null, childrenProps => (React.createElement(ServiceCardDialog, Object.assign({ order: servicePageProps.order, openedModal: servicePageProps.openedModal, onClose: servicePageProps.toggleModal.bind(null, null), passengers: servicePageProps.passengers, passengerLabels: servicePageProps.passengerLabels, analyticsMeta: servicePageProps.analyticsMeta }, childrenProps))))));
};
export default MealAdditionalService;
