import * as React from 'react';
import { useSelector } from 'react-redux';
import { getSelectedServices } from '../../store/selector';
const getCount = (selectedBaggage) => selectedBaggage.reduce((count, item) => item.count + count, 0);
const DynamicBaggagePricing = props => {
    const selectedBaggageFromState = useSelector(getSelectedServices), lastStateBaggageCount = React.useRef(getCount(props.selectedServices));
    React.useEffect(() => {
        const baggageCount = getCount(selectedBaggageFromState);
        if (props.dynamicBaggagePricing && lastStateBaggageCount.current !== baggageCount) {
            props.onConfirm(selectedBaggageFromState);
        }
        lastStateBaggageCount.current = baggageCount;
    }, [props.dynamicBaggagePricing, selectedBaggageFromState]);
    return React.createElement(React.Fragment, null, props.children);
};
export default DynamicBaggagePricing;
