import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useSaveOrderServicesMutation } from '@websky/graphql';
import { TransferService } from '../../../Transfer';
import Dialog from '../../../Service/components/Dialog/Dialog';
import { useToggleable } from '../../../hooks';
import CheckoutAdditionalService from '../../index';
import SegmentTabs from '../../../SegmentTabs/SegmentTabs';
import FullScreenDialogHeader from '../../../FullscreenDialog/components/Header/Header';
import { ThemeProvider, useTheme } from '../../../theme';
import Toolbar from '../../../Toolbar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getGroundTransportationServices, getOrder } from '../../../Checkout/store/order/selectors';
import { useRenderers } from '../../../renderProps';
import { getMinPrice, getTravellersMap, initI18n, useIsMobile } from '../../../utils';
import { fillOrder } from '../../../Checkout/store/order/actions';
import TransferSummary from '../../../ServiceSummary/services/TransferSummary';
import { useCart } from '../../../Checkout/components/Checkout/Stepbar/hooks';
initI18n('Transfer');
const Transfer = props => {
    var _a;
    const { t } = useTranslation('Checkout');
    const { isOpen, close, open } = useToggleable(false);
    const order = useSelector(getOrder);
    const [activeSegment, setActiveSegment] = useState(order.flight.segments[0].segment.id);
    const [servicesToSave, setServicesToSave] = useState(null);
    const [initialStateChanged, setInitialStateChanged] = useState(false);
    const dispatch = useDispatch();
    const transferServices = useSelector(getGroundTransportationServices);
    const transferServicesIds = transferServices.map(({ id }) => id);
    const isSelected = useMemo(() => {
        var _a, _b, _c;
        const transferServicesIds = transferServices.map(({ id }) => id);
        for (const traveller of order.travellers) {
            if ((_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.some(service => transferServicesIds.includes(service.serviceId))) {
                return true;
            }
        }
        return false;
    }, [order.travellers, transferServices]);
    const orderPriceToPay = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g;
        const priceToPay = {
            amount: 0,
            currency: (_b = (_a = order === null || order === void 0 ? void 0 : order.price) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.currency
        };
        for (const traveller of order.travellers) {
            for (const service of ((_d = (_c = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _c === void 0 ? void 0 : _c.gdsServices) === null || _d === void 0 ? void 0 : _d.services) || []) {
                if (transferServicesIds.includes(service.serviceId)) {
                    const orderService = (_g = (_f = (_e = order.additionalServices) === null || _e === void 0 ? void 0 : _e.gdsServices) === null || _f === void 0 ? void 0 : _f.services) === null || _g === void 0 ? void 0 : _g.find(orderService => orderService.id === service.serviceId);
                    if (orderService) {
                        priceToPay.amount += service.segmentIds.length * orderService.price.amount;
                    }
                }
            }
        }
        return priceToPay;
    }, [transferServices, servicesToSave, order.travellers]);
    const minPrice = getMinPrice(transferServices, transferService => transferService.price);
    const [saveServices, { loading }] = useSaveOrderServicesMutation();
    const { Transfer: css } = useTheme('CheckoutAdditionalService');
    const handleSubmit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!initialStateChanged) {
            close();
            return;
        }
        const result = yield saveServices({
            variables: {
                params: servicesToSave
            }
        });
        dispatch(fillOrder(result.data.SaveOrderServices));
        close();
    }), [servicesToSave, initialStateChanged]);
    const { CheckoutAdditionalService: CustomCheckoutAdditionalService } = useRenderers();
    const ServiceWrapper = (_a = CustomCheckoutAdditionalService === null || CustomCheckoutAdditionalService === void 0 ? void 0 : CustomCheckoutAdditionalService.CheckoutAdditionalService) !== null && _a !== void 0 ? _a : CheckoutAdditionalService;
    const buttons = useMemo(() => {
        if (isSelected) {
            return [
                {
                    text: t('Change'),
                    action: open,
                    isLoading: loading,
                    buttonType: 'change',
                    variant: 'optionEdit'
                }
            ];
        }
        else {
            return [
                {
                    text: t('Add'),
                    action: open,
                    isLoading: loading,
                    buttonType: 'change',
                    variant: 'action'
                }
            ];
        }
    }, [isSelected, loading]);
    const priceToPayBeforeSave = useMemo(() => {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
        const priceToPay = {
            amount: 0,
            currency: (_b = (_a = order === null || order === void 0 ? void 0 : order.price) === null || _a === void 0 ? void 0 : _a.total) === null || _b === void 0 ? void 0 : _b.currency
        };
        for (const travellerServices of (_c = servicesToSave === null || servicesToSave === void 0 ? void 0 : servicesToSave.services) !== null && _c !== void 0 ? _c : []) {
            for (const service of (_e = (_d = travellerServices === null || travellerServices === void 0 ? void 0 : travellerServices.gdsServices) === null || _d === void 0 ? void 0 : _d.setServices) !== null && _e !== void 0 ? _e : []) {
                const relatedOrderService = (_h = (_g = (_f = order.additionalServices) === null || _f === void 0 ? void 0 : _f.gdsServices) === null || _g === void 0 ? void 0 : _g.services) === null || _h === void 0 ? void 0 : _h.find(relatedService => relatedService.id === service.serviceId);
                const relatedTraveller = order.travellers.find(traveller => traveller.id === travellerServices.travellerId);
                const relatedTravellerService = (_l = (_k = (_j = relatedTraveller === null || relatedTraveller === void 0 ? void 0 : relatedTraveller.services) === null || _j === void 0 ? void 0 : _j.gdsServices) === null || _k === void 0 ? void 0 : _k.services) === null || _l === void 0 ? void 0 : _l.find(travellerService => travellerService.serviceId === relatedOrderService.id);
                priceToPay.amount += relatedOrderService.price.amount * service.count;
                if (relatedTravellerService) {
                    priceToPay.amount -= relatedOrderService.price.amount * relatedTravellerService.confirmedCount;
                }
            }
        }
        return priceToPay;
    }, [servicesToSave]);
    const isMobile = useIsMobile();
    const { onDeleteItem } = useCart();
    return (React.createElement(React.Fragment, null,
        React.createElement(TransferSummary, { flight: order.flight, travellers: order.travellers, services: order.additionalServices, travellersById: getTravellersMap(order.travellers), onServiceDelete: onDeleteItem }, summary => (React.createElement(ServiceWrapper, { className: css.header, header: t('Transfer'), description: t('Transfer:Cheap and on time to get to your destination'), isSelected: isSelected, priceTotal: orderPriceToPay, isLoading: loading, priceFrom: minPrice, addClick: open, buttons: buttons }, summary))),
        isOpen && (React.createElement(Dialog, { isOpen: isOpen, dialogContentClassName: css.dialogContent, dialogType: 'fullscreen', hideHeader: true, onClose: close, content: React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.header },
                    React.createElement(FullScreenDialogHeader, { hideTitle: isMobile, headerClassName: css.headerInner, onClose: close, title: t('Transfer:Choose transfer'), renderBeforeCloseButton: () => (React.createElement(ThemeProvider, { value: {
                                SegmentTabs: {
                                    SegmentTabs: {
                                        inner: css.segmentTabs
                                    }
                                }
                            } },
                            React.createElement(SegmentTabs, { title: '', isCompact: isMobile, wrapperClassName: css.segmentsTabsWrapper, activeSegment: activeSegment, onSegmentClick: setActiveSegment, segments: order.flight.segments.map(segment => ({
                                    id: segment.segment.id,
                                    title: `${segment.segment.departure.airport.city.name}-${segment.segment.arrival.airport.city.name}`,
                                    isDisabled: false
                                })) }))) })),
                React.createElement(TransferService, { order: order, onChange: setServicesToSave, onStateChanged: setInitialStateChanged, activeSegment: activeSegment }),
                React.createElement(Toolbar, { totalPrice: priceToPayBeforeSave, isLoading: loading, onButtonClick: handleSubmit })) }))));
};
export default Transfer;
