import { __rest } from "tslib";
import * as React from 'react';
import { useEffect } from 'react';
import Results from '../../../Results';
import ResultsError from '../../../ResultsError';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { EmptySearchResultSegmentsError } from '../../../Engine/components/errors';
import { BaseButton } from '../../../index';
export const ResultsWrapper = React.memo((_a) => {
    var { loadResults, resultId, isLoading, error } = _a, restProps = __rest(_a, ["loadResults", "resultId", "isLoading", "error"]);
    const { t } = useTranslation();
    const history = useHistory();
    useEffect(() => {
        if (!isLoading && !restProps.searchResults && !error) {
            loadResults(resultId);
        }
    }, []);
    if (isLoading) {
        return null;
    }
    const isEmptySearchResults = !restProps.searchResults;
    const isEmptySearchResultSegments = error && error instanceof EmptySearchResultSegmentsError;
    if (isEmptySearchResults || isEmptySearchResultSegments) {
        return (React.createElement(ResultsError, { header: t('It looks like something went wrong'), body: React.createElement("div", null,
                t('Please reload the page or start a new search'),
                React.createElement("br", null),
                React.createElement("br", null),
                React.createElement(BaseButton, { variant: 'optionAction', onClick: () => {
                        history.push('/');
                    } }, t('Go to main page'))) }));
    }
    return React.createElement(Results, Object.assign({}, restProps));
});
