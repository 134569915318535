import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAeroexpress, getAvailableGdsServicesByType, getBaggageDelivery, getCanAddUpgradeFlightClass, getGroundTransportationServices, getInsurances, getOrder, getPriorityBoarding, getSmsNotificationServices, getTravellers, getCapsuleHotel } from '../../Checkout/store/order/selectors';
import { removeServiceAction, setMultipleSelectedServices, setServiceAction } from '../../Checkout/store/selectedServices/actions';
import { ServiceType } from '../../Checkout/components/Checkout/OrderPaidServiceCards/types';
import { getPassengerLabels, getPassengerState } from '../../Checkout/store/passengers/selectors';
import { getPassengerStateCompatible, getSelectedServicesState } from '../../Checkout/store/selectedServices/selectors';
import { AviaPassengerType, OrderAdditionalServiceGdsServiceServiceType, useAdditionalServicesLazyQuery, useSeatMapLazyQuery } from '@websky/graphql';
import { Money } from '../../index';
import { getPassengerPricesMap, getVipServicesToDelete } from '../utils';
import { getMinPrice, getPassengerTypeLabel, isUnconfirmedService } from '../../utils';
import { getAnimalTotalPrice } from './utils';
import { useIataCode } from '../../hooks';
import { fillOrder } from '../../Checkout/store/order/actions';
import { WEBSKY_SERVICE_OPEN_EVENT } from '../../Checkout/constants';
import Control from '../../VipServiceSelect/components/Control/Control';
import { useTheme } from '../../theme';
import cn from 'classnames';
export const useServicePageProps = (typeForListenEvent) => {
    const order = useSelector(getOrder);
    const passengers = useSelector(getPassengerStateCompatible);
    const passengerLabels = useSelector(getPassengerLabels);
    const [openedModal, setOpenedModal] = useState(null);
    const [callbackAfterClose, setCallbackAfterClose] = useState(null);
    const [analyticsMeta, setAnalyticsMeta] = useState(null);
    const toggleModal = (type) => {
        if (!!type) {
            setOpenedModal(type);
        }
        else {
            setAnalyticsMeta(null);
            setOpenedModal(null);
            if (callbackAfterClose) {
                callbackAfterClose();
            }
        }
    };
    useEffect(() => {
        const eventHandler = (event) => {
            if (event.detail.service.type === typeForListenEvent) {
                setAnalyticsMeta(event.detail.meta);
                switch (event.detail.service.type) {
                    case OrderAdditionalServiceGdsServiceServiceType.Baggage:
                        toggleModal(ServiceType.Baggage);
                        setCallbackAfterClose(() => event.detail.callback);
                        break;
                    case OrderAdditionalServiceGdsServiceServiceType.Meal:
                        toggleModal(ServiceType.Meal);
                        setCallbackAfterClose(() => event.detail.callback);
                        break;
                    default:
                        return;
                }
            }
        };
        document.addEventListener(WEBSKY_SERVICE_OPEN_EVENT, eventHandler);
        return () => document.removeEventListener(WEBSKY_SERVICE_OPEN_EVENT, eventHandler);
    }, [setCallbackAfterClose, toggleModal]);
    return {
        order,
        passengers,
        passengerLabels,
        analyticsMeta,
        openedModal,
        toggleModal
    };
};
export const useCheckoutServicesCardsAvailability = () => {
    var _a, _b;
    const aeroexpress = useSelector(getAeroexpress);
    const insurance = useSelector(getInsurances);
    const priorityBoarding = useSelector(getPriorityBoarding);
    const gdsServices = useSelector(getAvailableGdsServicesByType);
    const baggageDelivery = useSelector(getBaggageDelivery);
    const canAddUpgradeFlightClass = useSelector(getCanAddUpgradeFlightClass);
    const transferServices = useSelector(getGroundTransportationServices);
    const smsNotificationServices = useSelector(getSmsNotificationServices);
    const capsuleHotelServices = useSelector(getCapsuleHotel);
    const iataCode = useIataCode();
    return Object.assign(Object.assign({ upgradeFlightClass: canAddUpgradeFlightClass, Insurance: ((_a = insurance === null || insurance === void 0 ? void 0 : insurance.insurancePrograms) === null || _a === void 0 ? void 0 : _a.length) > 0, Aeroexpress: ((_b = aeroexpress === null || aeroexpress === void 0 ? void 0 : aeroexpress.trips) === null || _b === void 0 ? void 0 : _b.length) > 0, Transfer: (transferServices === null || transferServices === void 0 ? void 0 : transferServices.length) > 0, PriorityBoarding: (priorityBoarding === null || priorityBoarding === void 0 ? void 0 : priorityBoarding.length) > 0, BaggageDelivery: (baggageDelivery === null || baggageDelivery === void 0 ? void 0 : baggageDelivery.length) > 0, SMS: (smsNotificationServices === null || smsNotificationServices === void 0 ? void 0 : smsNotificationServices.length) > 0, CapsuleHotel: (capsuleHotelServices === null || capsuleHotelServices === void 0 ? void 0 : capsuleHotelServices.length) > 0 }, gdsServices), { Baggage: iataWithAdditionalQueryForServices.includes(iataCode) || gdsServices.Baggage, Seat: iataWithAdditionalQueryForServices.includes(iataCode) || gdsServices.Seat });
};
export const useOrderService = (orderServices, type, isAllSegments) => {
    const { t } = useTranslation('Checkout');
    const { Control: theme } = useTheme('CheckoutAdditionalService');
    const dispatch = useDispatch();
    const travellers = useSelector(getTravellers);
    const passengers = useSelector(getPassengerState);
    const selectedServices = useSelector(getSelectedServicesState);
    const [opened, setOpened] = React.useState(false);
    const onOpen = () => setOpened(true);
    const onClose = () => setOpened(false);
    const isSelected = React.useMemo(() => {
        return passengers.some(passenger => orderServices.some(service => selectedServices[passenger.id].hasOwnProperty(service.id)));
    }, [passengers, selectedServices]);
    const unconfirmedServices = React.useMemo(() => {
        var _a, _b, _c, _d;
        let services = [];
        if (Object.keys(selectedServices !== null && selectedServices !== void 0 ? selectedServices : {}).length) {
            for (const passengerId in selectedServices) {
                for (const serviceId in selectedServices[passengerId]) {
                    const traveller = travellers.find(traveller => traveller.id === passengerId);
                    if (!traveller) {
                        continue;
                    }
                    const service = (_c = (_b = (_a = traveller === null || traveller === void 0 ? void 0 : traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.find(service => service.serviceId === serviceId);
                    if (!service) {
                        continue;
                    }
                    const isUnconfirmed = (_d = service.products) === null || _d === void 0 ? void 0 : _d.some(product => isUnconfirmedService(product.status));
                    if (isUnconfirmed) {
                        services = [
                            ...services,
                            {
                                passengerId,
                                serviceId: service.serviceId,
                                segmentIds: service.segmentIds,
                                count: service.count
                            }
                        ];
                    }
                }
            }
        }
        return services;
    }, [travellers, selectedServices]);
    const onSaveHandler = (newSelectedServices) => {
        batch(() => {
            dispatch(setMultipleSelectedServices(newSelectedServices.map(selectedService => ({
                service: {
                    id: selectedService.serviceId,
                    type
                },
                serviceId: selectedService.serviceId,
                segmentIds: isAllSegments ? selectedService.segmentIds : undefined,
                segmentId: isAllSegments ? undefined : selectedService.segmentIds[0],
                passengerId: selectedService.passengerId,
                count: selectedService.count,
                allowedSegments: selectedService.allowedSegments
            }))));
        });
        onClose();
    };
    const onRemoveHandler = (services) => {
        batch(() => {
            services.forEach(service => {
                dispatch(setServiceAction({
                    service: {
                        id: service.serviceId,
                        type
                    },
                    serviceId: service.serviceId,
                    segmentId: service.segmentIds[0],
                    passengerId: service.passengerId,
                    count: 0
                }));
            });
        });
        onClose();
    };
    const onRemoveCartService = React.useCallback((_, payload) => {
        const servicePayload = payload;
        const servicesToDelete = getVipServicesToDelete(servicePayload, travellers);
        onRemoveHandler(servicesToDelete);
    }, [travellers]);
    const availablePassengers = React.useMemo(() => passengers.filter(passenger => passenger.type !== AviaPassengerType.INF), [passengers]);
    const isDifferentPrices = React.useMemo(() => new Set(orderServices.map(service => service.price.amount)).size > 1, [passengers, orderServices]);
    const passengerPrices = React.useMemo(() => getPassengerPricesMap(passengers, orderServices, isDifferentPrices), [
        passengers,
        orderServices,
        isDifferentPrices
    ]);
    const renderSubheader = React.useCallback(() => {
        return passengerPrices.map((passenger, index) => (React.createElement("span", { key: index },
            getPassengerTypeLabel(passenger.type, passenger.supplierType),
            ": ",
            isDifferentPrices && t('from'),
            React.createElement(Money, { money: passenger.price }))));
    }, [passengerPrices, isDifferentPrices]);
    const renderPassenger = useCallback(({ passenger, type: controlType, count, isConfirmed, onSetCount }) => {
        var _a;
        const price = (_a = orderServices === null || orderServices === void 0 ? void 0 : orderServices.find(service => service.id === passenger.serviceId)) === null || _a === void 0 ? void 0 : _a.price;
        return (React.createElement("div", { className: cn(theme.passenger, {
                [theme.passenger_vipService]: type === OrderAdditionalServiceGdsServiceServiceType.Vip
            }) },
            React.createElement(Control, { type: controlType, count: count, label: React.createElement("div", { className: theme.label }, passenger.name), disabled: isConfirmed, onSetCount: onSetCount(passenger) }),
            React.createElement("div", { className: theme.price }, count > 0 && React.createElement(Money, { money: price }))));
    }, [orderServices, type]);
    const minPrice = React.useMemo(() => {
        return getMinPrice(passengerPrices, item => item.price);
    }, [isSelected, passengerPrices]);
    return {
        isSelected,
        selectedServices,
        unconfirmedServices,
        renderSubheader,
        renderPassenger,
        onOpen,
        onClose,
        setOpened,
        onSaveHandler,
        onRemoveHandler,
        onRemoveCartService,
        passengers: availablePassengers,
        opened,
        getMinPrice: minPrice
    };
};
export const useAnimalService = (services, travellers) => {
    const dispatch = useDispatch();
    const servicesByRfisc = useMemo(() => {
        const servicesMap = new Map();
        services.forEach(service => {
            var _a;
            const allowedSegmentsKey = service.allowedSegments
                .reduce((acc, ids) => [...acc, ids.join('')], [])
                .join('-');
            const allowedPassengersKey = service.allowedPassengers
                .reduce((acc, ids) => [...acc, ids.join('')], [])
                .join('-');
            // key - rfisc/segmentIds/passengerIds
            const key = [service.rfisc, allowedSegmentsKey, allowedPassengersKey].join('/');
            const baggageService = service;
            servicesMap.set(key, [...((_a = servicesMap.get(key)) !== null && _a !== void 0 ? _a : []), baggageService]);
        });
        return [...servicesMap.values()].map(services => {
            let actualService = null;
            services.forEach(service => {
                if (!actualService || (actualService === null || actualService === void 0 ? void 0 : actualService.id) < service.id) {
                    actualService = service;
                }
            });
            return actualService;
        });
    }, [services]);
    const onSave = useCallback((selectedServices) => {
        const selectedServicesToAdd = selectedServices.filter(service => service.count > 0);
        const selectedServicesToRemove = selectedServices.filter(service => service.count === 0);
        if (selectedServicesToAdd.length) {
            dispatch(setMultipleSelectedServices(selectedServicesToAdd.map(service => ({
                segmentId: service.segmentId,
                allowedSegments: [service.segmentIds],
                passengerId: service.passengerId,
                serviceId: service.serviceId,
                count: service.count,
                service: {
                    id: service.serviceId,
                    segmentId: service.segmentId,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                }
            }))));
        }
        if (selectedServicesToRemove.length) {
            selectedServicesToRemove.forEach(service => {
                dispatch(removeServiceAction({
                    segmentId: service.segmentId,
                    segmentIds: service.segmentIds,
                    passengerId: service.passengerId,
                    serviceId: service.serviceId,
                    count: 0,
                    service: {
                        id: service.serviceId,
                        segmentId: service.segmentId,
                        type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                    }
                }));
            });
        }
    }, [travellers, dispatch]);
    const onClear = useCallback((selectedServices) => {
        selectedServices.forEach(service => {
            dispatch(removeServiceAction({
                segmentId: service.segmentId,
                segmentIds: service.segmentIds,
                passengerId: service.passengerId,
                serviceId: service.serviceId,
                count: 0,
                service: {
                    id: service.serviceId,
                    segmentId: service.segmentId,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage
                }
            }));
        });
    }, [dispatch]);
    const minPrice = useMemo(() => {
        if (servicesByRfisc.length) {
            return getMinPrice(servicesByRfisc, service => {
                const maxSegmentCount = Math.max(...service.allowedSegments.map(ids => ids.length));
                return Object.assign(Object.assign({}, service.price), { amount: service.price.amount * maxSegmentCount });
            });
        }
        return null;
    }, [servicesByRfisc]);
    const totalPrice = useMemo(() => getAnimalTotalPrice(services, travellers), [services, travellers]);
    return {
        servicesByRfisc,
        minPrice,
        totalPrice,
        onSave,
        onClear
    };
};
export const useAdditionalQueryForServices = (props) => {
    const order = useSelector(getOrder);
    const dispatch = useDispatch();
    const onCompleted = (data) => {
        var _a;
        (_a = props === null || props === void 0 ? void 0 : props.onCompleted) === null || _a === void 0 ? void 0 : _a.call(props, data);
    };
    const [load, { data, loading }] = useAdditionalServicesLazyQuery({
        variables: { id: order.id },
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            onCompleted(data);
        }
    });
    React.useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.AdditionalServices) {
            dispatch(fillOrder(Object.assign(Object.assign({}, order), { additionalServices: data.AdditionalServices })));
        }
    }, [data]);
    return {
        loadAndDispatch: load,
        isLoading: loading,
        data
    };
};
export const useAdditonalQueryForSeatMap = () => {
    const order = useSelector(getOrder);
    const dispatch = useDispatch();
    const [load, { data, loading }] = useSeatMapLazyQuery({
        variables: { id: order.id },
        fetchPolicy: 'no-cache'
    });
    React.useEffect(() => {
        if (data === null || data === void 0 ? void 0 : data.SeatMap) {
            dispatch(fillOrder(Object.assign(Object.assign({}, order), { flight: Object.assign(Object.assign({}, order.flight), { seatMap: Object.assign(Object.assign({}, order.flight.seatMap), data.SeatMap) }) })));
        }
    }, [data]);
    return {
        loadAndDispatch: load,
        isLoading: loading,
        data
    };
};
export const iataWithAdditionalQueryForServices = ['DV']; // https://youtrack.websky.aero/issue/WEBSKY-5717
