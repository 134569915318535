import * as React from 'react';
import MediaQuery from 'react-responsive';
import { useSelector } from 'react-redux';
import { usePaymentMethodsQuery } from '@websky/graphql';
import { getLastExchange } from '../../../store/order/selectors';
import { ExchangeStep } from '../../../types';
import { MOBILE_MIN_WIDTH } from '../../../../../utils';
import PaymentComponent from '../Payment/Payment';
import MobileToolbar from '../../../../../MobileToolbar';
import ErrorModal from '../../../../../PaymentMethodsError';
import { usePaymentMethodsError } from '../../../../../PaymentMethodsError/hooks';
import { OverrideComponent } from '../../../../../renderProps';
const Payment = props => {
    const order = useSelector(getLastExchange);
    const lastExchangeOrder = useSelector(getLastExchange);
    const goToOrder = () => props.setStep(ExchangeStep.Request);
    const baseUrl = window.location.href;
    const { data, loading, error } = usePaymentMethodsQuery({
        fetchPolicy: 'no-cache',
        variables: {
            withKaspi: true,
            withItransfer: true,
            withGpay: true,
            withCredit: true,
            withCard: true,
            withQiwi: true,
            withFps: true,
            withSbol: true,
            parameters: {
                orderId: lastExchangeOrder.id,
                backRedirectUrl: `${baseUrl}/success`,
                backRedirectUrlOnFail: `${baseUrl}/failed`
            }
        }
    });
    const paymentMethodsErrorModal = usePaymentMethodsError(error);
    React.useEffect(() => {
        props.setLoading(loading);
    }, [loading]);
    return !loading ? (React.createElement(React.Fragment, null,
        React.createElement(OverrideComponent, { componentProps: {
                exchangeOrder: order,
                successPayment: props.successPayment,
                goToOrder,
                paymentMethods: data === null || data === void 0 ? void 0 : data.PaymentMethods,
                failedPayment: props.failedPayment
            }, component: { renderExchangePayment: PaymentComponent } }),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileToolbar, { onBack: goToOrder })),
        React.createElement(ErrorModal, { open: paymentMethodsErrorModal.isOpen, error: paymentMethodsErrorModal.errorMessage, onClose: paymentMethodsErrorModal.close }))) : (React.createElement(React.Fragment, null));
};
export default Payment;
