import Input from './Input/components/Input.css';
import RadioButton from './RadioButton/components/RadioButton.css';
import Checkbox from './Checkbox/components/Checkbox.css';
import Toggle from './Toggle/components/Toggle.css';
import Dropdown from './Dropdown/components/Dropdown.css';
import Button from './Button/components/Button.css';
import CloseButton from './Button/components/CloseButton/CloseButton.css';
import IconButton from './Button/components/IconButton/IconButton.css';
import Loader from './Button/components/Loader/Loader.css';
import SexSelector from './SexSelector/components/SexSelector.css';
import ModalX from './ModalX/components/ModalX.css';
export default {
    Input,
    Dropdown,
    Button,
    CloseButton,
    IconButton,
    Loader,
    RadioButton,
    Checkbox,
    Toggle,
    SexSelector,
    ModalX
};
