import * as React from 'react';
import Money from '../../Money';
import { useContext } from 'react';
import { CartContext } from '../Cart';
import { Button } from '../../index';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../theme';
const Toolbar = ({ onClick }) => {
    const cartContext = useContext(CartContext);
    const { t } = useTranslation('Cart');
    const theme = useTheme('Cart').Toolbar;
    return (React.createElement("div", { className: theme.container },
        React.createElement("div", { className: theme.money }, cartContext.totalPrices.total.amount > 0 && (React.createElement(React.Fragment, null,
            React.createElement("span", { className: theme.from }, t('Total')),
            React.createElement(Money, { money: cartContext.totalPrices.total })))),
        React.createElement(Button, { className: theme.close, variant: "flat", onClick: onClick }, t('Close'))));
};
export default Toolbar;
