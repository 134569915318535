import * as React from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import FareGroup from '../../../FareGroup';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import CompareFares from '../../../CompareFares/components/CompareFares';
import Modal from '../../../Modal';
import FareConditions from '../../../FareConditions/components/Component';
import { OverrideComponent } from '../../../renderProps';
import FareButton from '../../../widgets/Flights/FareButton/components/FareButton';
const SelectedFareGroup = ({ className, fare, flightId, price, orderId, hideConditionsButton }) => {
    const { SelectedFareGroup: css } = useTheme('DesktopFlight');
    const { t } = useTranslation('FlightInfoModalContent');
    const [isFareCompareOpen, setIsFareCompareOpen] = useState(false);
    const [isFareConditionsOpen, setFareConditionsOpen] = useState(false);
    const openFareCompare = () => setIsFareCompareOpen(true);
    const closeFareCompare = () => setIsFareCompareOpen(false);
    const openFareConditions = () => setFareConditionsOpen(true);
    const closeFareConditions = () => setFareConditionsOpen(false);
    const renderButton = useCallback(() => React.createElement(FareButton, { className: css.button, price: price, selected: true, onClick: () => null }), [fare]);
    const renderRulesButton = useCallback(() => (React.createElement("div", { className: css.rules },
        React.createElement(Button, { className: css.button__rules, variant: "text", onClick: openFareConditions }, t('Fare rules')),
        !hideConditionsButton && (React.createElement(Button, { className: css.button__rules, variant: "text", onClick: openFareCompare }, t('SelectedFareGroup_View full conditions'))))), [fare, hideConditionsButton]);
    const renderers = useMemo(() => ({
        renderButton,
        renderRulesButton
    }), [fare]);
    return (React.createElement(React.Fragment, null,
        React.createElement(OverrideComponent, { componentProps: {
                setTooltipsOpened: () => null,
                className: cn(css.fare, className),
                titleClassName: css.title,
                optionsClassName: css.options,
                optionClassName: css.option,
                flightId: flightId,
                fare: fare,
                price: null,
                variant: 'white',
                isSelected: true,
                excludeRepeatableKeyOptions: true,
                renderProps: renderers,
                disableClassForHeaderPayFeature: true,
                showUnavailableOptions: true,
                keyFeatureClassName: css.keyFeature
            }, component: {
                FareGroup
            } }),
        React.createElement(Modal, { open: isFareCompareOpen, onClose: closeFareCompare, isRounded: true },
            React.createElement(CompareFares, { selectedFareName: fare.title, flightId: flightId, orderId: orderId })),
        React.createElement(Modal, { open: isFareConditionsOpen, maxWidth: "md", onClose: closeFareConditions, closeClassName: css.fareConditions__closeButton },
            React.createElement(FareConditions, { flightId: flightId, orderId: orderId }))));
};
export default SelectedFareGroup;
