import * as React from 'react';
import { useContext } from 'react';
import BaseComponents from './BaseComponents/theme';
import Inner from './Engine/theme';
import Button from './Button/theme';
import SelectedFare from './SelectedFare/theme';
import Input from './Input/theme';
import Counter from './Counter/theme';
import Link from './Link/theme';
import Switch from './Switch/theme';
import WarningModal from './WarningModal/theme';
import PromoLoader from './PromoLoader/theme';
import Toolbar from './Toolbar/theme';
import Money from './Money/theme';
import PhoneInput from './PhoneInput/theme';
import StatusMessage from './StatusMessage/theme';
import Tooltip from './Tooltip/theme';
import Slider from './Slider/theme';
import CloseIcon from './CloseIcon/theme';
import ServiceWithSegments from './ServiceWithSegments/theme';
import AccountModule from './Modules/Account/theme';
import Radio from './Radio/theme';
import Tabs from './Tabs/theme';
import PromoCode from './PromoCode/theme';
import Schedule from './LEO/Schedule/theme';
import Autocomplete from './Autocomplete/theme';
import FlightSelect from './FlightSelect/theme';
import PromoMessage from './PromoMessage/theme';
import Bag from './Baggage/Bag/theme';
import Passengers from './Passengers/theme';
import Refund from './Refund/theme';
import Pagination from './Pagination/theme';
import CheckoutOrderSummary from './CheckoutOrderSummary/theme';
import CustomerContactsForm from './CustomerContactsForm/theme';
import ResendTicketsForm from './ResendTicketsForm/theme';
import AdditionalServices from './AdditionalServices/theme';
import VisaForm from './VisaForm/theme';
import IncludedOptions from './Baggage/IncludedOptions/theme';
import RecaptchaPage from './RecaptchaPage/theme';
import CheckinRules from './CheckinRules/theme';
import MobileToolbar from './MobileToolbar/theme';
import PaymentResult from './PaymentResult/theme';
import Segment from './BoardingPassDesk/theme';
import Weather from './Weather/theme';
import MobileSelectedFare from './MobileSelectedFare/theme';
import SimpleLoader from './SimpleLoader/theme';
import SelectedPassenger from './SelectedPassenger/theme';
import SelectSegments from './SelectSegments/theme';
import ServiceRecommendation from './ServiceRecommendation/theme';
import Aeroexpress from './Aeroexpress/theme';
import Warnings from './Warnings/theme';
import Sidebar from './Sidebar/theme';
import SocialAuth from './SocialAuth/theme';
import MobileStepbar from './MobileStepbar/theme';
import PaymentForm from './PaymentForm/theme';
import PaymentTimeLimit from './PaymentTimeLimit/theme';
import MetasearchLoader from './MetasearchLoader/theme';
import Traveller from './Traveller/theme';
import OfferCard from './OfferCard/theme';
import CurrencyConverter from './CurrencyConverter/theme';
import Page404 from './Page404/theme';
import RetrieveBooking from './RetrieveBooking/theme';
import Amenities from './Amenities/theme';
import Datepicker from './Datepicker/theme';
import DateRangePicker from './DateRangePicker/theme';
import SearchFormDatepicker from './SearchFormDatepicker/theme';
import Lang from './Lang/theme';
import InfoCard from './InfoCard/theme';
import FullscreenDialog from './FullscreenDialog/theme';
import Modal from './Modal/theme';
import FlightPrice from './FlightPrice/theme';
import FareConditions from './FareConditions/theme';
import DocumentForm from './DocumentForm/theme';
import CompareFares from './CompareFares/theme';
import BaggageService from './BaggageService/theme';
import BaggagePage from './BaggagePage/theme';
import Airport from './Airport/theme';
import AdditionalServiceCard from './AdditionalServiceCard/theme';
import FareGroup from './FareGroup/theme';
import Meal from './Meal/theme';
import MealService from './MealService/theme';
import DesktopFlightInfo from './DesktopFlightInfo/theme';
import DesktopFlight from './DesktopFlight/theme';
import MobileFlight from './MobileFlight/theme';
import DesktopSummaryFlight from './DesktopSummaryFlight/theme';
import MobileSegmentInfo from './MobileSegmentInfo/theme';
import MobileSummaryFlight from './MobileSummaryFlight/theme';
import Contacts from './Contacts/theme';
import LoginPage from './LoginPage/theme';
import MobileAuthForm from './MobileAuthForm/theme';
import OrderSummary from './OrderSummary/theme';
import SeatMap from './SeatMap/theme';
import SuggestionModalContent from './SuggestionModalContent/theme';
import AdditionalServiceBanner from './AdditionalServiceBanner/theme';
import Stepbar from './Stepbar/theme';
import WeekCalendar from './WeekCalendar/theme';
import { defaultTheme as PriceGraph } from './PriceGraph/theme';
import Timer from './Timer/theme';
import Account from './Account/theme';
import { theme as SearchForm } from './SearchForm/theme';
import QuickSearchForm from './QuickSearchForm/theme';
import Results from './Results/theme';
import Checkout from './Checkout/theme';
import Disclaimer from './Disclaimer/theme';
import Service from './Service/theme';
import Checkin from './Modules/Checkin/theme';
import AddPassenger from './AddPassenger/theme';
import MobileSegment from './BoardingPassMob/theme';
import RegisteredPassenger from './RegisteredPassenger/theme';
import CheckoutAdditionalService from './CheckoutAdditionalService/theme';
import LoyaltyBonusStyles from './LoyaltyBonus/theme';
import Passenger from './Passenger/theme';
import SelectableSegment from './SelectableSegment/theme';
import Loyalty from './Loyalty/theme';
import Insurance from './Insurance/theme';
import Location from './Location/theme';
import FlightCard from './FlightCard/theme';
import TransferInfo from './TransferInfo/theme';
import FlightInfo from './FlightInfo/theme';
import SegmentTabs from './SegmentTabs/theme';
import DesktopFlightRoute from './DesktopFlightRoute/theme';
import FlightSortFilter from './FlightSortFilter/theme';
import OrderChangeHistoryModal from './OrderChangeHistoryModal/theme';
import FlightsSortings from './LEO/FlightsSortings/theme';
import FlightsFilters from './LEO/FlightsFilters/theme';
import FlightsListControls from './LEO/FlightsListControls/theme';
import ResultsSegments from './LEO/ResultsSegments/theme';
import TabButton from './Button/components/TabButton/theme';
import FareLock from './FareLock/theme';
import OrderCard from './OrderCard/theme';
import Exchange from './Modules/Exchange/theme';
import VipServiceSelect from './VipServiceSelect/theme';
import TravellerForm from './TravellerForm/theme';
import MilesHistory from './MilesHistory/theme';
import MilesRecovery from './MilesRecovery/theme';
import PassengerForm from './PassengerForm/theme';
import Notification from './Notification/theme';
import FfpInfoNotice from './FfpInfoNotice/theme';
import PnrHistory from './PnrHistory/theme';
import AircraftPopup from './AircraftPopup/theme';
import AddBooking from './AddBooking/theme';
import ProfileControls from './ProfileControls/theme';
import Baggage from './Baggage/Baggage/theme';
import BaggageList from './Baggage/BaggageList/theme';
import IncludedBaggage from './Baggage/IncludedBaggage/theme';
import AdditionalBaggage from './Baggage/AdditionalBaggage/theme';
import BaggageTotalPriceBlock from './Baggage/BaggageTotalPriceBlock/theme';
import BaggageCounter from './Baggage/BaggageCounter/theme';
import UpgradeBaggageDialog from './Baggage/UpgradeBaggageDialog/theme';
import SpecialEquipment from './Baggage/SpecialEquipment/theme';
import BaggageCard from './Baggage/BaggageCard/theme';
import OverweightBaggage from './Baggage/OverweightBaggage/theme';
import LocationAutocomplete from './LocationAutocomplete/theme';
import ScheduleForm from './ScheduleForm/theme';
import CoreSchedule from './Schedule/theme';
import PassengersDetails from './PassengersDetails/theme';
import AdditionalServiceWrapper from './AdditionalServiceWrapper/theme';
import InsurancePopup from './InsurancePopup/theme';
import SmsNotifications from './CheckoutAdditionalService/components/SmsNotifications/theme';
import PriorityBoardingPopup from './PriorityBoardingPopup/theme';
import DoubleSeatsModal from './DoubleSeatsModal/theme';
import ActualizationOk from './Checkout/components/Checkout/Actualization/components/ActualizationOk/theme';
import Forms from './Forms/theme';
import CheckinStart from './CheckinStart/theme';
import CheckinConditions from './CheckinConditions/theme';
import MobileHeader from './MobileHeader/theme';
import SubsidySuggestPopup from './SubsidySuggestPopup/theme';
import SubsidyRejectPopup from './SubsidyRejectPopup/theme';
import UserCard from './UserCard/theme';
import TravelCompanions from './TravelCompanions/theme';
import SlideBottom from './SlideBottom/theme';
import UpsaleModal from './UpsaleServicesModal/theme';
import Subscriptions from './Modules/Subscriptions/theme';
import SubscriptionsLanding from './Modules/Subscriptions/components/Landing/theme';
import TransitTooltip from './TransitTooltip/theme';
import ServiceSummary from './ServiceSummary/theme';
import Cart from './Cart/theme';
import TicketExample from './TicketExample/theme';
import LoyaltyInfo from './LoyaltyInfo/theme';
import SureModal from './SureModal/theme';
import InfoMessages from './InfoMessages/theme';
import WxPagination from './WxPagination/theme';
import SecureAccess from './SecureAccess/theme';
import html from './html/theme';
import ResultsError from './ResultsError/theme';
import FlightStatus from './Modules/FlightStatus/theme';
import Communications from './Communications/theme';
import PaymentMethodsError from './PaymentMethodsError/theme';
import InsurancePage from './InsurancePage/theme';
import ModalConfirm from './ModalConfirm/theme';
import Badge from './Badge/theme';
import EditPassenger from './EditPassenger/theme';
import TicketRequest from './TicketRequest/theme';
import Consents from './Consents/theme';
import Transfer from './Transfer/theme';
import RefundSummary from './RefundSummary/theme';
import UpgradeFlightClass from './UpgradeFlightClass/theme';
import AIPurchasePredictionService from './AIPurchasePredictionService/theme';
import AgreementCheckboxWrapper from './AgreementCheckboxWrapper/theme';
import AircraftLivery from './AircraftLivery/theme';
import BookingPreview from './BookingPreview/theme';
import Flights from './widgets/Flights/theme';
import Navigation from './widgets/navigation/theme';
import CardExtra from './CardExtra/theme';
import { mergeCSS } from './utils';
const theme = {
    // Elements
    Link,
    Button,
    Counter,
    Input,
    Switch,
    Slider,
    Money,
    WarningModal,
    PromoLoader,
    Toolbar,
    Radio,
    WxPagination,
    Tabs,
    ModalConfirm,
    Badge,
    // Widgets
    UpgradeFlightClass,
    LocationAutocomplete,
    ServiceWithSegments,
    FlightsSortings,
    FlightsFilters,
    FlightsListControls,
    ResultsSegments,
    TabButton,
    CloseIcon,
    TransferInfo,
    FlightInfo,
    FlightCard,
    Location,
    SelectableSegment,
    RecaptchaPage,
    Bag,
    AdditionalServices,
    ResendTicketsForm,
    IncludedOptions,
    MobileToolbar,
    PaymentResult,
    PaymentMethodsError,
    MobileAuthForm,
    CheckinRules,
    Segment,
    Weather,
    StatusMessage,
    SimpleLoader,
    ServiceRecommendation,
    AddPassenger,
    MobileSegment,
    MobileSelectedFare,
    Aeroexpress,
    Pagination,
    RegisteredPassenger,
    SelectedPassenger,
    SelectSegments,
    Warnings,
    AdditionalServiceBanner,
    Sidebar,
    MobileStepbar,
    PaymentForm,
    PaymentTimeLimit,
    Stepbar,
    SocialAuth,
    WeekCalendar,
    MetasearchLoader,
    VisaForm,
    OfferCard,
    Timer,
    Traveller,
    OrderSummary,
    PhoneInput,
    CurrencyConverter,
    Page404,
    RetrieveBooking,
    Amenities,
    Datepicker,
    SearchFormDatepicker,
    DateRangePicker,
    Lang,
    InfoCard,
    FullscreenDialog,
    FlightPrice,
    LoginPage,
    FareConditions,
    DocumentForm,
    CompareFares,
    BaggageService,
    BaggagePage,
    Airport,
    AircraftPopup,
    AdditionalServiceCard,
    FareGroup,
    Meal,
    MealService,
    DesktopFlightInfo,
    DesktopFlight,
    MobileFlight,
    DesktopSummaryFlight,
    MobileSummaryFlight,
    Contacts,
    Results,
    SearchForm,
    QuickSearchForm,
    SeatMap,
    PriceGraph,
    Account,
    Checkout,
    SuggestionModalContent,
    MobileSegmentInfo,
    Modal,
    Disclaimer,
    Service,
    Passenger,
    Checkin,
    CheckoutAdditionalService,
    LoyaltyBonusStyles,
    CheckoutOrderSummary,
    Loyalty,
    Passengers,
    Autocomplete,
    Insurance,
    Tooltip,
    SegmentTabs,
    DesktopFlightRoute,
    SelectedFare,
    PromoMessage,
    FlightSortFilter,
    PromoCode,
    CustomerContactsForm,
    OrderCard,
    FlightSelect,
    Schedule,
    OrderChangeHistoryModal,
    Inner,
    FareLock,
    VipServiceSelect,
    MilesRecovery,
    MilesHistory,
    ActualizationOk,
    Exchange,
    Refund,
    Notification,
    PassengerForm,
    FfpInfoNotice,
    PnrHistory,
    AddBooking,
    ProfileControls,
    AccountModule,
    TravellerForm,
    Forms,
    Baggage,
    BaggageList,
    BaggageTotalPriceBlock,
    BaggageCounter,
    IncludedBaggage,
    AdditionalBaggage,
    UpgradeBaggageDialog,
    SpecialEquipment,
    BaggageCard,
    OverweightBaggage,
    AdditionalServiceWrapper,
    CheckinStart,
    CheckinConditions,
    MobileHeader,
    ScheduleForm,
    CoreSchedule,
    SubsidySuggestPopup,
    SubsidyRejectPopup,
    UserCard,
    TravelCompanions,
    PassengersDetails,
    UpsaleModal,
    SlideBottom,
    Subscriptions,
    SubscriptionsLanding,
    BaseComponents,
    InsurancePopup,
    SmsNotifications,
    PriorityBoardingPopup,
    TransitTooltip,
    ServiceSummary,
    Cart,
    TicketExample,
    DoubleSeatsModal,
    LoyaltyInfo,
    SureModal,
    InfoMessages,
    SecureAccess,
    html,
    ResultsError,
    FlightStatus,
    Communications,
    InsurancePage,
    EditPassenger,
    TicketRequest,
    Consents,
    Transfer,
    RefundSummary,
    AIPurchasePredictionService,
    AgreementCheckboxWrapper,
    AircraftLivery,
    BookingPreview,
    Flights,
    Navigation,
    CardExtra
};
const ThemeContext = React.createContext(theme);
export const ThemeProvider = React.memo(({ value, children }) => {
    const theme = useContext(ThemeContext);
    return React.createElement(ThemeContext.Provider, { value: mergeCSS(theme, value) }, children);
});
export const { Consumer: ThemeConsumer } = ThemeContext;
export { ThemeContext };
export const useTheme = (module) => useContext(ThemeContext)[module];
