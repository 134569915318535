/* tslint:disable max-classes-per-file */
export class CheckinFatalError extends Error {
    constructor(m) {
        super(m);
        this.name = 'CheckinFatalError';
        Object.setPrototypeOf(this, CheckinFatalError.prototype);
    }
}
export class CheckinEmptySeatMapError extends CheckinFatalError {
    constructor(m) {
        super(m);
        this.name = 'CheckinEmptySeatMapError';
        Object.setPrototypeOf(this, CheckinEmptySeatMapError.prototype);
    }
}
export class CheckinUnavailableSeatMapError extends CheckinFatalError {
    constructor(m) {
        super(m);
        this.name = 'CheckinUnavailableSeatMapError';
        Object.setPrototypeOf(this, CheckinUnavailableSeatMapError.prototype);
    }
}
