import { __awaiter } from "tslib";
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ApolloError } from 'apollo-boost';
import { TravellerFieldEnum, useFindOrderMutation } from '@websky/graphql';
import { capitalizeFirstLetterInName, format, getUserValue, redirectToOrder, removeDotsFromDate } from '../../utils';
import { useConfig } from '../../context';
import { useHistory } from 'react-router-dom';
const ATTEMPT_TO_IMPORT_ORDER_ERROR = 'Попытка импорта заказа не удалась';
export function useBookingPreview({ order, onClearOrder, redirectViaHistory }) {
    const { t } = useTranslation('BookingPreview');
    const history = useHistory();
    const { Account: { engineURL } } = useConfig();
    const [error, setError] = useState(null);
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [findOrder, { loading }] = useFindOrderMutation();
    const formatDate = useCallback((date) => removeDotsFromDate(format(date, 'dd MMM, yyyy')), []);
    const { departure, arrival } = useMemo(() => {
        var _a, _b, _c, _d, _e;
        const segmentGroups = (_a = order === null || order === void 0 ? void 0 : order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups;
        const departure = (_c = (_b = segmentGroups === null || segmentGroups === void 0 ? void 0 : segmentGroups[0]) === null || _b === void 0 ? void 0 : _b.segments[0]) === null || _c === void 0 ? void 0 : _c.departure;
        const arrival = (_e = (_d = segmentGroups === null || segmentGroups === void 0 ? void 0 : segmentGroups[segmentGroups.length - 1]) === null || _d === void 0 ? void 0 : _d.segments) === null || _e === void 0 ? void 0 : _e[0].arrival;
        return { departure, arrival };
    }, [order === null || order === void 0 ? void 0 : order.flight]);
    const date = useMemo(() => {
        var _a, _b;
        if (!order) {
            return null;
        }
        if (((_b = (_a = order === null || order === void 0 ? void 0 : order.flight) === null || _a === void 0 ? void 0 : _a.segmentGroups) === null || _b === void 0 ? void 0 : _b.length) > 1) {
            if (departure.date === arrival.date) {
                return `${formatDate(departure.date)}, ${departure.time} - ${arrival.time}`;
            }
            else {
                return `${formatDate(departure.date)} - ${formatDate(arrival.date)}`;
            }
        }
        return formatDate(departure.date);
    }, [order === null || order === void 0 ? void 0 : order.flight]);
    const travellersCount = useMemo(() => { var _a, _b; return (_b = (_a = order === null || order === void 0 ? void 0 : order.travellers) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0; }, [order === null || order === void 0 ? void 0 : order.travellers]);
    const onError = useCallback((e) => {
        var _a, _b, _c;
        let errorMessage = 'Unknown error';
        if (e instanceof ApolloError && ((_b = (_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message)) {
            const gqlError = e.graphQLErrors[0];
            if (((_c = gqlError.debugMessage) !== null && _c !== void 0 ? _c : gqlError.message).includes(ATTEMPT_TO_IMPORT_ORDER_ERROR)) {
                errorMessage = 'The attempt to import the order failed';
            }
            else {
                errorMessage = gqlError.message;
            }
        }
        else if (e instanceof Error && e.message) {
            errorMessage = e.message;
        }
        setError(t(errorMessage));
    }, [setError]);
    const onClearError = useCallback(() => {
        setError(null);
    }, []);
    const onViewOrder = useCallback(() => {
        if (redirectViaHistory) {
            history.push(`/booking/${order.id}`);
            return;
        }
        redirectToOrder(engineURL, order.id);
    }, [engineURL, order, redirectViaHistory, history]);
    const onSaveAndView = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a;
        setIsSaveLoading(true);
        let accessCode = order.accessCode;
        if ((_a = order.travellers) === null || _a === void 0 ? void 0 : _a.length) {
            accessCode = capitalizeFirstLetterInName(getUserValue(order.travellers[0], TravellerFieldEnum.LastName));
        }
        try {
            const { data } = yield findOrder({
                variables: {
                    params: {
                        id: order.locator,
                        secret: accessCode,
                        saveInProfile: true
                    }
                }
            });
            if (!(data === null || data === void 0 ? void 0 : data.FindOrder)) {
                return;
            }
            onViewOrder();
            onClearOrder === null || onClearOrder === void 0 ? void 0 : onClearOrder();
        }
        catch (e) {
            onError(e);
        }
        setIsSaveLoading(false);
    }), [order, findOrder, setIsSaveLoading, onViewOrder, onClearOrder, onError]);
    return {
        // data
        departure,
        arrival,
        date,
        travellersCount,
        error,
        isLoading: loading,
        isSaveLoading,
        // methods
        onViewOrder,
        onSaveAndView,
        onClearError
    };
}
