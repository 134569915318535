import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import { TravellerFieldEnum, useOrderQuery, useSaveOrderServicesMutation, useFfpCompleteOrderMutation, OrderStatus } from '@websky/graphql';
import { getUserValue, MOBILE_MIN_WIDTH } from '../../../../../utils';
import { StepType } from '../../../../types';
import Toolbar from '../../../../../Toolbar';
import { useTheme } from '../../../../../theme';
import { isFormLocked } from '../../../../store/formIsLocked/selectors';
import MobileToolbar from '../../../../../MobileToolbar';
import { getBonusForFlight, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmedOrCancelled, getIsContactsInfoIsEmpty, getIsNew, getIsTravellersDataFilled, getOrder as getRootOrder, getOrderId, getPaymentIsRequired, getPriceInMiles, getSelectedInsuranceCodes, getServicesFallbackURL, getTotalInsurancePrice } from '../../../../store/order/selectors';
import { Money as MoneyComponent } from '../../../../../index';
import { useUpsaleWarning } from '../../Upsale/hooks';
import Warning from '../../Upsale/Warning/Warning';
import { getTotalPriceToPay } from '../../../../store/orderPrice/selectors';
import PassengersToolbarCheckbox from './PassengersToolbarCheckbox';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { fillOrder } from '../../../../store/order/actions';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useUpdateTravellers } from '../../../../../FareLock/hooks';
import { getPassengerState } from '../../../../store/passengers/selectors';
import { OverrideComponent } from '../../../../../renderProps';
import { InsuranceUpsaleModal } from '../../../../../InsurancePage';
import { useInsuranceUpsaleModal } from '../../../../../InsurancePage/components/InsuranceUpsaleModal/InsuranceUpsaleModal.hook';
import { saveOrderServicesParamsFactory } from '../../../../../InsurancePage/store/utils';
import AIPurchasePredictionService from '../../../../../AIPurchasePredictionService/components/AIPurchasePredictionService';
import { setMultipleSelectedServices } from '../../../../store/selectedServices/actions';
import { useAiPurchasePredictionService } from '../../../../../AIPurchasePredictionService/hook';
import { isServicesSaving } from '../../../../store/servicesIsSaving/selectors';
import { WEBSKY_SERVICE_OPEN_EVENT } from '../../../../constants';
import WebskyAnalyticsApollo from '../../../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { PurchaseShowPopupAction, WxEvents } from '../../../../../DataLayerAnalytics/types';
import { getAiPurchasePopupGoal } from '../../../../../DataLayerAnalytics/utils';
import SimpleLoader from '../../../../../SimpleLoader';
const PassengersToolbar = ({ formRef, currentStep, setNextStep, disableBooking, loading, onConfirmConsents, showConsents = true }) => {
    const { t } = useTranslation('Checkout');
    const { CheckoutStyles: checkoutTheme, PassengersToolbar: toolbarTheme } = useTheme('Checkout');
    const dispatch = useDispatch();
    const history = useHistory();
    const isNew = useSelector(getIsNew);
    const isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    const isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    const isBooked = useSelector(getIsBooked);
    const formIsLocked = useSelector(isFormLocked);
    const totalInsurancePrice = useSelector(getTotalInsurancePrice);
    const totalPriceToPay = useSelector(getTotalPriceToPay);
    const servicesRedirectURL = useSelector(getServicesFallbackURL);
    const { toggleWarningIfOrderConfirmed, showWarning, closeWarning } = useUpsaleWarning();
    const isOrderCancelled = useSelector(getIsCancelled);
    const bonusForFlight = useSelector(getBonusForFlight);
    const priceInMilesForFlight = useSelector(getPriceInMiles);
    const isPaymentRequired = useSelector(getPaymentIsRequired);
    const isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    const isContactsInfoEmpty = useSelector(getIsContactsInfoIsEmpty);
    const passengersValues = useSelector(getPassengerState);
    const order = useSelector(getRootOrder);
    const orderId = useSelector(getOrderId);
    const selectedInsuranceCodes = useSelector(getSelectedInsuranceCodes);
    let handleSubmit;
    let updateTravellers;
    const isOrderServicesSaving = useSelector(isServicesSaving);
    try {
        updateTravellers = useUpdateTravellers();
        handleSubmit = updateTravellers.handleSubmit;
    }
    catch (e) { }
    let isToolbarButtonDisabled = false;
    if (typeof updateTravellers !== 'undefined' && !updateTravellers.valid) {
        isToolbarButtonDisabled = true;
    }
    if (isTravellersDataFilled) {
        isToolbarButtonDisabled = false;
    }
    const [servicesLoading, setServicesLoading] = useState(false);
    const { refetch: getOrder } = useOrderQuery({ skip: true });
    const [saveOrderServices, saveOrderServicesResult] = useSaveOrderServicesMutation();
    const [ffpCompleteOrder, ffpCompleteOrderResult] = useFfpCompleteOrderMutation();
    const { saveClientInfo, closeContactsModal, openContactsModal, isContactsModalOpen, loading: isClientInfoSaving } = useCustomerContactsForm();
    const insuranceUpsaleModal = useInsuranceUpsaleModal({ strategy: 'checkout-before-payment' });
    const aiPurchasePredictionService = useAiPurchasePredictionService();
    const goToPayment = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (isToolbarButtonDisabled) {
            formRef.current.dispatchEvent(new Event('submit', { cancelable: true }));
            return;
        }
        if (handleSubmit) {
            yield handleSubmit(passengersValues.map(traveller => {
                var _a;
                return ({
                    id: traveller.id,
                    values: (_a = traveller.values) === null || _a === void 0 ? void 0 : _a.map(value => ({
                        type: value.type,
                        name: value.name,
                        value: value.value
                    }))
                });
            }));
        }
        if (showWarning) {
            return toggleWarningIfOrderConfirmed();
        }
        if (isPaymentRequired && isContactsInfoEmpty) {
            openContactsModal();
            return;
        }
        if (order.ffpInfo.skipPayment) {
            const result = yield ffpCompleteOrder({ variables: { id: orderId } });
            const ffpCompleteOrderResult = (_a = result === null || result === void 0 ? void 0 : result.data) === null || _a === void 0 ? void 0 : _a.FfpCompleteOrder;
            if ((ffpCompleteOrderResult === null || ffpCompleteOrderResult === void 0 ? void 0 : ffpCompleteOrderResult.result) && (ffpCompleteOrderResult === null || ffpCompleteOrderResult === void 0 ? void 0 : ffpCompleteOrderResult.order)) {
                dispatch(fillOrder(ffpCompleteOrderResult.order));
            }
        }
        else {
            setNextStep();
        }
    }), [totalPriceToPay, passengersValues, onConfirmConsents, order.ffpInfo.skipPayment]);
    const onContinuePurchasePredictionService = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (onConfirmConsents) {
            onConfirmConsents();
            return;
        }
        setServicesLoading(true);
        yield aiPurchasePredictionService.onContinue(goToPayment);
        setServicesLoading(false);
    }), [onConfirmConsents, goToPayment, aiPurchasePredictionService.onContinue]);
    const saveClientInfoAndUpdateOrder = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield saveClientInfo({ variables: Object.assign(Object.assign({}, data), { orderId }) });
        const { data: getOrderResult } = yield getOrder({ id: orderId });
        if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.Order) {
            dispatch(fillOrder(getOrderResult.Order));
        }
        closeContactsModal();
        setNextStep();
    });
    const savePriorityInsurance = useCallback((selectedCodes = []) => __awaiter(void 0, void 0, void 0, function* () {
        const selectedCodesSet = new Set([...selectedInsuranceCodes, ...selectedCodes]);
        if (!selectedCodesSet.size) {
            return;
        }
        const travellerIds = order.travellers.map(traveller => traveller.id);
        const saveOrderParams = saveOrderServicesParamsFactory(travellerIds, [...selectedCodesSet]);
        const { data } = yield saveOrderServices({
            variables: {
                params: Object.assign(Object.assign({}, saveOrderParams.params), { id: order.id })
            }
        });
        if (data === null || data === void 0 ? void 0 : data.SaveOrderServices) {
            dispatch(fillOrder(data.SaveOrderServices));
        }
        yield onContinuePurchasePredictionService();
    }), [order, selectedInsuranceCodes, onContinuePurchasePredictionService, dispatch]);
    const saveAdditionalService = (service) => __awaiter(void 0, void 0, void 0, function* () {
        dispatch(setMultipleSelectedServices([
            {
                passengerId: service.passengerId,
                allowedSegments: service.allowedSegments,
                segmentId: service.segmentId,
                serviceId: service.serviceId,
                count: service.count,
                service: {
                    id: service.service.id,
                    type: service.service.type
                }
            }
        ], { custom_ecommerce_name: getAiPurchasePopupGoal(service.service.type, PurchaseShowPopupAction.Add) }));
    });
    useEffect(() => {
        if (!isOrderServicesSaving && aiPurchasePredictionService.isAddedService) {
            goToPayment();
        }
    }, [isOrderServicesSaving, aiPurchasePredictionService.isAddedService]);
    useEffect(() => {
        if (aiPurchasePredictionService.isOpen) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.AiPurchaseModalOpen, {
                servicesIds: [aiPurchasePredictionService.service.id]
            });
        }
    }, [aiPurchasePredictionService.isOpen]);
    const onChangeStep = (service) => {
        const event = new CustomEvent(WEBSKY_SERVICE_OPEN_EVENT, {
            detail: {
                service,
                callback: goToPayment,
                meta: {
                    custom_ecommerce_name: service.type !== 'insurance'
                        ? getAiPurchasePopupGoal(service.type, PurchaseShowPopupAction.Change)
                        : null
                }
            }
        });
        document.dispatchEvent(event);
    };
    const onAiPurchaseDisagree = () => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.AiPurchaseModalClose, {
            servicesIds: [aiPurchasePredictionService.service.id]
        });
        aiPurchasePredictionService.onDisagree(goToPayment);
    };
    const customerValues = React.useMemo(() => {
        const customer = order.customer;
        return {
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [order.customer]);
    const goBack = useCallback(() => {
        history.goBack();
    }, [history]);
    const hasPriceOrMilesToPay = totalPriceToPay.amount > 0 || (order.ffpInfo.skipPayment && order.status !== OrderStatus.Confirmed);
    const showToolbar = !formIsLocked && (currentStep === null || currentStep === void 0 ? void 0 : currentStep.type) !== StepType.Payment && !isOrderCancelled && hasPriceOrMilesToPay;
    const isDesktopToolbarLoading = (updateTravellers === null || updateTravellers === void 0 ? void 0 : updateTravellers.loading) || loading || saveOrderServicesResult.loading || servicesLoading;
    const isMobileToolbarDisabled = disableBooking || loading || saveOrderServicesResult.loading || servicesLoading;
    return (React.createElement(React.Fragment, null,
        ffpCompleteOrderResult.loading && React.createElement(SimpleLoader, null),
        showToolbar && (React.createElement(React.Fragment, null,
            React.createElement(Warning, { open: showWarning, redirectURL: servicesRedirectURL, onClose: closeWarning }),
            React.createElement(InsuranceUpsaleModal, { insurance: insuranceUpsaleModal.priorityInsurance, isOpen: insuranceUpsaleModal.isUpsaleOpen, onAgree: () => insuranceUpsaleModal.onAgree(savePriorityInsurance), onDisagree: () => insuranceUpsaleModal.onDisagree(onContinuePurchasePredictionService) }),
            React.createElement(AIPurchasePredictionService, { order: order, service: aiPurchasePredictionService.service, onAgree: service => aiPurchasePredictionService.onAgree(service, saveAdditionalService), onChange: payload => aiPurchasePredictionService.onChange(payload, onChangeStep), onDisagree: onAiPurchaseDisagree }),
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(Toolbar, { isLoading: isDesktopToolbarLoading, actionsRight: isBooked, hideNextButton: !hasPriceOrMilesToPay, totalPrice: totalPriceToPay, priceInMiles: !isConfirmedOrCancelled && priceInMilesForFlight, onButtonClick: isBookedOrConfirmed
                        ? () => insuranceUpsaleModal.onContinue(onContinuePurchasePredictionService)
                        : null, miles: !isConfirmedOrCancelled && bonusForFlight, buttonDisabled: disableBooking, additional: React.createElement(React.Fragment, null,
                        isBooked && totalInsurancePrice && totalInsurancePrice.amount && (React.createElement("div", { className: checkoutTheme.gdsTotalPrice },
                            t('Insurance'),
                            ": ",
                            React.createElement(MoneyComponent, { money: totalInsurancePrice }))),
                        React.createElement("div", null,
                            showConsents && isNew && React.createElement(PassengersToolbarCheckbox, null),
                            React.createElement(OverrideComponent, { componentProps: {
                                    order
                                }, component: {
                                    renderPassengersToolbarAdditional: () => null
                                } }))) })),
            React.createElement(CustomerContactsModal, { initialValues: customerValues, onContactsSubmit: saveClientInfoAndUpdateOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen, onClose: closeContactsModal }),
            React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement("div", { className: toolbarTheme.toolbarCheckbox__wrapper },
                    showConsents && isNew && React.createElement(PassengersToolbarCheckbox, null),
                    React.createElement(OverrideComponent, { componentProps: {
                            order
                        }, component: {
                            renderPassengersToolbarAdditional: () => null
                        } })),
                React.createElement(MobileToolbar, { onBack: goBack, disabled: isMobileToolbarDisabled, onContinue: isBookedOrConfirmed && hasPriceOrMilesToPay
                        ? () => insuranceUpsaleModal.onContinue(onContinuePurchasePredictionService)
                        : isBookedOrConfirmed
                            ? undefined
                            : null }))))));
};
export default PassengersToolbar;
