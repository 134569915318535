import * as React from 'react';
import { useTheme } from '../../../../../theme';
import { Form, FormSpy } from 'react-final-form';
import Field from './Field/Field';
import { useTranslation } from 'react-i18next';
import { FfpAccountFieldEnum, Gender } from '@websky/graphql';
import { InputType } from '../../../../../Passenger/types';
import cn from 'classnames';
import ModalConfirm from '../../../../../ModalConfirm';
import { ItemIncluded, WarningRounded } from '../../../../../Icons';
import Tooltip from '../../../../../Tooltip';
import { API_DATE_FORMAT, format } from '../../../../../utils';
import { parse } from 'date-fns';
const CreateAccount = props => {
    var _a, _b, _c, _d;
    const theme = useTheme('Loyalty').LoyaltyConnect;
    const { t } = useTranslation('Loyalty');
    const getFieldType = (type) => {
        switch (type) {
            case FfpAccountFieldEnum.BirthDate:
            case FfpAccountFieldEnum.DocExpiryDate:
            case FfpAccountFieldEnum.DocIssued:
                return InputType.Date;
            case FfpAccountFieldEnum.Phone:
                return InputType.Phone;
            case FfpAccountFieldEnum.Country:
            case FfpAccountFieldEnum.DocType:
                return InputType.Select;
            case FfpAccountFieldEnum.Gender:
                return InputType.Switch;
            case FfpAccountFieldEnum.City:
                return InputType.Text;
            default:
                return InputType.Text;
        }
    };
    const getOptions = (validationRules, values, type) => {
        let options;
        for (const rule of validationRules) {
            if (!rule.with || rule.with.every(condition => values[condition.name] === condition.value)) {
                options = rule.options;
                break;
            }
        }
        return options.map(option => {
            return {
                key: option.value,
                value: option.label
            };
        });
    };
    const fieldList = (_b = (_a = props.initialValues) === null || _a === void 0 ? void 0 : _a.FfpDefaultAccount) === null || _b === void 0 ? void 0 : _b.values;
    const blocks = [
        {
            title: 'Your name, Cyrillic',
            groups: [[FfpAccountFieldEnum.FirstName], [FfpAccountFieldEnum.LastName], [FfpAccountFieldEnum.MiddleName]]
        },
        {
            title: 'Your name, Latin',
            subtitle: 'Please enter Full name in Latin transcription',
            groups: [
                [FfpAccountFieldEnum.LatinFirstName],
                [FfpAccountFieldEnum.LatinLastName],
                [FfpAccountFieldEnum.LatinMiddleName]
            ]
        },
        {
            title: 'Document',
            groups: [
                [FfpAccountFieldEnum.Country],
                [FfpAccountFieldEnum.DocType],
                [FfpAccountFieldEnum.DocNumber],
                [FfpAccountFieldEnum.DocExpiryDate],
                [FfpAccountFieldEnum.DocIssued],
                [FfpAccountFieldEnum.DocIssuedBy]
            ]
        },
        {
            title: 'Contacts',
            subtitle: 'How to Connect with You?',
            groups: [[FfpAccountFieldEnum.Email], [FfpAccountFieldEnum.Phone], [FfpAccountFieldEnum.City]]
        }
    ];
    const BirthdayGenderFieldGroup = [FfpAccountFieldEnum.BirthDate, FfpAccountFieldEnum.Gender]
        .map(fieldType => {
        return fieldList === null || fieldList === void 0 ? void 0 : fieldList.find(param => (param === null || param === void 0 ? void 0 : param.type) === fieldType);
    })
        .filter(p => p !== undefined);
    const formData = React.useMemo(() => {
        if (!fieldList) {
            return null;
        }
        const data = blocks === null || blocks === void 0 ? void 0 : blocks.map((block, i) => {
            return {
                title: block.title,
                subtitle: block.subtitle || false,
                groups: block.groups.map(group => {
                    return group
                        .map(fieldType => {
                        return fieldList.find(param => (param === null || param === void 0 ? void 0 : param.type) === fieldType);
                    })
                        .filter(p => p !== undefined);
                })
            };
        });
        data.forEach(block => {
            block.groups = block.groups.filter(group => group.length > 0);
        });
        const filtered = data.filter(block => block.groups.length > 0);
        if (BirthdayGenderFieldGroup.length > 0) {
            filtered[0].groups.push(BirthdayGenderFieldGroup);
        }
        return filtered;
    }, [fieldList]);
    const [formValidationErrors, setValidationErrors] = React.useState({});
    const formBlocksValidationData = React.useMemo(() => {
        if (!formData) {
            return null;
        }
        const list = new Map(Object.entries(formValidationErrors));
        const blocksList = formData.map(block => {
            const isValidBLock = block.groups.find(gr => {
                const groupHasErrors = gr.find(val => {
                    return list.get(val.name);
                });
                return !!groupHasErrors;
            });
            return !!!isValidBLock;
        });
        return blocksList;
    }, [fieldList, formValidationErrors]);
    const onSubmit = (data) => {
        var _a, _b, _c, _d;
        (_a = props.onSubmitForm) === null || _a === void 0 ? void 0 : _a.call(props);
        const params = [];
        for (const param in data) {
            if (data.hasOwnProperty(param)) {
                const defaultValue = (_c = (_b = props.initialValues) === null || _b === void 0 ? void 0 : _b.FfpDefaultAccount) === null || _c === void 0 ? void 0 : _c.values.find(value => value.name === param);
                const value = (_d = data[param]) !== null && _d !== void 0 ? _d : '';
                params.push({
                    name: param,
                    value,
                    type: defaultValue.type
                });
            }
        }
        return props.onCreateAccount(params);
    };
    const initialValues = {};
    (_d = (_c = props.initialValues) === null || _c === void 0 ? void 0 : _c.FfpDefaultAccount) === null || _d === void 0 ? void 0 : _d.values.forEach(value => {
        if (value.type === FfpAccountFieldEnum.Gender && value.value) {
            initialValues[value.name] = value.value === 'M' ? Gender.Male : Gender.Female;
        }
        else {
            initialValues[value.name] = value.value;
        }
        if (getFieldType(value.type) === 'date' && !!value.value) {
            try {
                initialValues[value.name] = format(parse(value.value, API_DATE_FORMAT, new Date()), 'dd.MM.yyyy');
            }
            catch (e) {
                initialValues[value.name] = format(parse(value.value, 'dd.MM.yyyy', new Date()), 'dd.MM.yyyy');
            }
        }
    });
    React.useEffect(() => {
        if (!props.isOpen && props.formRef.current) {
            props.formRef.current.reset();
        }
    }, [props.isOpen]);
    if (!formData) {
        return React.createElement(React.Fragment, null);
    }
    return (React.createElement("div", { className: theme.loyaltyConnect },
        React.createElement(Form, { onSubmit: onSubmit, initialValues: initialValues, validateOnBlur: true }, ({ handleSubmit, values, form }) => {
            props.formRef.current = form;
            return (React.createElement(React.Fragment, null,
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement("div", { className: theme.form__content }, formData.map((block, index) => {
                        var _a;
                        return (React.createElement("div", { className: theme.block, key: index },
                            React.createElement("div", { className: theme.block__headerWrapper },
                                React.createElement("div", { className: theme.block__header },
                                    React.createElement("div", { className: theme.block__header__titleWrapper },
                                        React.createElement("div", { className: theme.block__header__titleNumber }, index + 1),
                                        React.createElement("div", { className: theme.block__header__title }, t(block.title))),
                                    formBlocksValidationData[index] && (React.createElement("div", { className: theme.block__header__valid }, ItemIncluded)),
                                    !formBlocksValidationData[index] && (React.createElement(Tooltip, { title: t('Show errors') },
                                        React.createElement("div", { className: theme.block__header__invalid, onClick: () => {
                                                block.groups.forEach(gr => {
                                                    gr.forEach(it => {
                                                        form.blur(it.name);
                                                    });
                                                });
                                            } }, WarningRounded)))),
                                block.subtitle && (React.createElement("div", { className: theme.block__header__subtitle }, t(block.subtitle.toString())))), (_a = block === null || block === void 0 ? void 0 : block.groups) === null || _a === void 0 ? void 0 :
                            _a.map((group, index) => {
                                return (React.createElement("div", { className: theme.groupCreate, key: index }, group === null || group === void 0 ? void 0 : group.map(field => {
                                    const type = getFieldType(field.type);
                                    const isEmail = field.type === FfpAccountFieldEnum.Email;
                                    const isDisabled = isEmail && !!initialValues['email'];
                                    return (React.createElement("div", { "data-field-name": field.name, className: cn(theme.groupCreate__field, {
                                            [theme.groupCreate__field_switch]: field.type === FfpAccountFieldEnum.Gender
                                        }), key: field.name },
                                        React.createElement(Field, { name: field.name, label: field.name, type: type, value: field.value || '', validationRules: field.validationRules, options: type === InputType.Select &&
                                                getOptions(field.validationRules, values, field.type), translateValue: field.type === FfpAccountFieldEnum.DocType, disabled: isDisabled })));
                                })));
                            })));
                    })),
                    React.createElement(FormSpy, { subscription: {
                            errors: true,
                            hasValidationErrors: true,
                            values: true,
                            modified: true,
                            active: true
                        }, onChange: ({ errors, hasValidationErrors }) => {
                            setTimeout(() => {
                                var _a;
                                setValidationErrors(errors);
                                (_a = props.onErrorsChanges) === null || _a === void 0 ? void 0 : _a.call(props, hasValidationErrors);
                            }, 0);
                        } })),
                props.error && (React.createElement(ModalConfirm, { isOpen: !!props.error, size: "medium", title: t('Cannot create an account'), onClose: props.closeError, children: props.error, confirmBtn: t('Close'), onConfirm: props.closeError }))));
        })));
};
export default CreateAccount;
