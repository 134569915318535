import * as React from 'react';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { initI18n, MOBILE_MIN_WIDTH, scrollTo } from '../../utils';
import { useTheme } from '../../theme';
import AdditionalServiceBanner from '../../AdditionalServiceBanner/AdditionalServiceBanner';
import SimpleLoader from '../../SimpleLoader';
import IncludedBaggage from '../../Baggage/IncludedBaggage/components/IncludedBaggage';
import { getServicesSavingState } from '../../Checkout/store/servicesIsSaving/selectors';
import { factorySelectedUpgrades } from '../../Baggage/IncludedBaggage/utils';
import AdditionalBaggage from '../../Baggage/AdditionalBaggage/components/AdditionalBaggage';
import SpecialEquipment from '../../Baggage/SpecialEquipment';
import OverweightBaggage from '../../Baggage/OverweightBaggage';
import { WxEvents } from '../../DataLayerAnalytics/types';
import { filterIncludedBaggageByRfics } from '../../Baggage/UpgradeBaggageDialog/utils';
import WebskyAnalyticsApollo from '../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { getOrder } from '../../Checkout/store/order/selectors';
import { getSelectedServicesState } from '../../Checkout/store/selectedServices/selectors';
import AvailableServicesCountContextProvider from '../../AdditionalServices/context';
import { OrderAdditionalServiceGdsServiceServiceType } from '@websky/graphql';
import { useMediaQuery } from 'react-responsive';
import { useConfig } from '../../context';
import { MobileBaggageRules } from './MobileBaggageRules/MobileBaggageRules';
import InfoMessages from '../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
initI18n('BaggagePage');
const BaggagePage = props => {
    var _a, _b, _c, _d;
    const theme = useTheme('BaggagePage').BaggagePage;
    const order = useSelector(getOrder);
    const selectedServicesState = useSelector(getSelectedServicesState);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const selectedServices = useMemo(() => {
        return factorySelectedUpgrades(props.selectedBaggage);
    }, [props.selectedBaggage]);
    const sortedAdditionalBaggage = React.useMemo(() => props.additionalBaggage.sort((a, b) => { var _a, _b; return ((_a = a.value) === null || _a === void 0 ? void 0 : _a.amount) - ((_b = b.value) === null || _b === void 0 ? void 0 : _b.amount); }), [props.additionalBaggage]);
    React.useEffect(() => {
        scrollTo(0);
    }, []);
    const servicesSaving = useSelector(getServicesSavingState);
    const baggages = React.useMemo(() => [
        ...props.additionalBaggage,
        ...props.upgradeBaggage,
        ...props.specialBaggage,
        ...props.overweightBaggage
    ], [props.additionalBaggage, props.upgradeBaggage, props.specialBaggage, props.overweightBaggage]);
    const setAdditionalBaggage = (segments) => {
        const services = [];
        segments.forEach(service => {
            const existsService = baggages.find(baggage => service.serviceId === baggage.id);
            if (existsService) {
                const selectedService = {
                    id: existsService.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    price: existsService.price,
                    confirmedCount: 0,
                    name: existsService.name
                };
                services.push({
                    passengerId: service.passengerId,
                    count: service.count,
                    serviceId: service.serviceId,
                    service: selectedService,
                    segmentId: service.segmentIds[0],
                    allowedSegments: [service.segmentIds]
                });
            }
        });
        props.setAdditionalBaggage(services);
    };
    useEffect(() => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.BaggageList);
    }, []);
    const filteredIncludedBaggageByRfics = filterIncludedBaggageByRfics(props.baggage, props.includedBaggage, selectedServices);
    const showMobileBaggageRules = isMobile && ['D2'].includes((_c = (_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global) === null || _b === void 0 ? void 0 : _b.companyInfo) === null || _c === void 0 ? void 0 : _c.iataCode);
    return (React.createElement("div", { className: cn(theme.baggage, props.className) },
        props.isLoading && React.createElement(SimpleLoader, null),
        !props.hideBanner && React.createElement(AdditionalServiceBanner, { service: "baggage" }),
        showMobileBaggageRules && React.createElement(MobileBaggageRules, null),
        React.createElement(AvailableServicesCountContextProvider, { value: { order, selectedServices: selectedServicesState } },
            React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.baggage, className: theme.infoMessage, itemHtmlClassName: theme.infoMessage__htmlMessage }),
            React.createElement("div", { className: theme.items },
                React.createElement("div", { className: theme.newBaggage },
                    (filteredIncludedBaggageByRfics.length > 0 || ((_d = props.upgradeBaggage) === null || _d === void 0 ? void 0 : _d.length) > 0) && (React.createElement(IncludedBaggage, { className: theme.included_baggage, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, includedBaggage: filteredIncludedBaggageByRfics, upgradeServices: props.upgradeBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, isLoading: servicesSaving, dynamicBaggagePricing: props.dynamicBaggagePricing })),
                    sortedAdditionalBaggage.length > 0 && (React.createElement(AdditionalBaggage, { className: theme.extra_baggage, additionalBaggage: sortedAdditionalBaggage, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, selectedServices: selectedServices, isLoading: servicesSaving, dynamicBaggagePricing: props.dynamicBaggagePricing, onChange: console.log, onConfirm: setAdditionalBaggage, disabled: props.disabled })))),
            React.createElement("div", { className: cn(theme.items, theme.additional) },
                props.overweightBaggage.length > 0 && (React.createElement(OverweightBaggage, { className: cn(theme.additionalBaggage, theme.overweight_baggage), passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, services: props.overweightBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: props.disabled, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: servicesSaving })),
                props.specialBaggage.length > 0 && (React.createElement(SpecialEquipment, { className: cn(theme.additionalBaggage, theme.equipment_baggage), passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, services: props.specialBaggage, selectedServices: selectedServices, onUpgrade: setAdditionalBaggage, disabled: props.disabled, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: servicesSaving }))))));
};
export default BaggagePage;
