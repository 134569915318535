import * as React from 'react';
import { useMemo } from 'react';
import { useTheme } from '../../../theme';
import Included from './Included/Included';
import Upgrade from './Upgrade/Upgrade';
import BaggageTotalPriceBlock from '../../BaggageTotalPriceBlock';
import { useToggleable } from '../../../hooks';
import UpgradeModal from './UpgradeModal/UpgradeModal';
import { initI18n } from '../../../utils';
import { getMinPrice, getPriceToPay } from '../../../BaggagePage/utils';
import { joinIncludedBaggageByRfics, joinServicesByRfics } from '../../UpgradeBaggageDialog/utils';
import BaggageContextProvider from '../../context';
import cn from 'classnames';
initI18n('IncludedBaggage');
const IncludedBaggage = props => {
    var _a;
    const { IncludedBaggage: css } = useTheme('IncludedBaggage');
    const { isOpen: isOpenModal, open: openModal, close: closeModal } = useToggleable(false);
    const priceToPay = useMemo(() => getPriceToPay(props.selectedServices, props.upgradeServices), [
        props.upgradeServices,
        props.selectedServices
    ]);
    const baggageByRfics = useMemo(() => joinServicesByRfics(props.upgradeServices), [props.upgradeServices]);
    const minPrice = useMemo(() => getMinPrice(props.upgradeServices), [props.upgradeServices]);
    const groupedByRficsIncludedBaggage = useMemo(() => {
        return joinIncludedBaggageByRfics(props.includedBaggage);
    }, [props.includedBaggage]);
    return (React.createElement("div", { className: cn(css.root, props.className) },
        React.createElement("div", { className: cn(css.options, props.upgradeServices.length === 0 ? css.options_withoutUpgrade : null) },
            props.includedBaggage.length > 0 && (React.createElement(Included, { className: css.included_block, segments: props.segments, includedBaggage: groupedByRficsIncludedBaggage, sliderToShow: ((_a = props.upgradeServices) === null || _a === void 0 ? void 0 : _a.length) > 0 ? 1 : 2 })),
            props.upgradeServices.length > 0 && (React.createElement(BaggageContextProvider, { value: { onBaggageIconClick: openModal } },
                React.createElement(Upgrade, { className: css.upgrade_block, upgradeServices: baggageByRfics, upgradeMinPrice: minPrice, selectedServices: props.selectedServices, segments: props.segments })))),
        props.upgradeServices.length > 0 && (React.createElement("div", { className: css.footer },
            React.createElement(BaggageTotalPriceBlock, { total: priceToPay, min: minPrice, onClickButton: openModal }))),
        props.upgradeServices.length > 0 && (React.createElement(UpgradeModal, { className: props.className, open: isOpenModal, onClose: closeModal, passengers: props.passengers, passengerLabels: props.passengerLabels, segments: props.segments, upgradeBaggage: props.upgradeServices, onConfirm: props.onUpgrade, disabled: props.isLoading || props.disabled, selectedServices: props.selectedServices, dynamicBaggagePricing: props.dynamicBaggagePricing, isLoading: props.isLoading }))));
};
export default IncludedBaggage;
