import { getContext, put, select, takeEvery } from 'redux-saga/effects';
import { ADD_PASSENGER, REMOVE_PASSENGER, setPassengers } from './actions';
import { getPassengersState } from './selectors';
import { PassengerType } from '../../../enums';
import { isResetSelectedOnlyInfants, setPassengerCountLessOrEqualAdt } from './utils';
function* clearConflictedPassengers({ payload: passengerType }) {
    const { SearchForm: { passengersConfig } } = yield getContext('config');
    const state = yield select(getPassengersState);
    const newState = Object.assign({}, state);
    passengersConfig.forEach(config => {
        var _a, _b;
        if (config.code === passengerType.passengerType) {
            (_a = config.noAvailableWith) === null || _a === void 0 ? void 0 : _a.forEach(conflictedCode => {
                newState[conflictedCode] = 0;
            });
        }
        else if ((_b = config.noAvailableWith) === null || _b === void 0 ? void 0 : _b.includes(passengerType.passengerType)) {
            newState[config.code] = 0;
        }
    });
    if (isResetSelectedOnlyInfants(newState, passengersConfig)) {
        Object.keys(newState).forEach(type => {
            newState[type] = 0;
        });
    }
    yield put(setPassengers(newState));
}
function* decrementInfants() {
    const { SearchForm: { passengersConfig } } = yield getContext('config');
    const state = yield select(getPassengersState);
    const adultPassengers = passengersConfig.filter(config => config.code === PassengerType.Adult || config.isAdult);
    const adultCount = adultPassengers.reduce((count, config) => {
        const currentCount = state[config.code];
        return count + currentCount;
    }, 0);
    let newState = Object.assign({}, state);
    newState = setPassengerCountLessOrEqualAdt(newState, adultCount, passengersConfig, PassengerType.Infant);
    newState = setPassengerCountLessOrEqualAdt(newState, adultCount, passengersConfig, PassengerType.InfantWithSeat);
    yield put(setPassengers(newState));
}
function* handleAddPassenger(action) {
    yield clearConflictedPassengers(action);
    yield decrementInfants();
}
export default function* passengersSaga() {
    yield takeEvery(ADD_PASSENGER, handleAddPassenger);
    yield takeEvery(REMOVE_PASSENGER, decrementInfants);
}
