import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { vouchersList } from '../../mocked';
import { Voucher } from '../Voucher/Voucher';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import Tabs from '../../../Tabs/Tabs';
import LoyaltyInfo from '../../../LoyaltyInfo';
import MilesHistory from '../../../MilesHistory';
import { useFfpAccountHistoryQuery, useFfpAccountLogoutMutation } from '@websky/graphql';
import WarningModal from '../../../WarningModal';
import { parseDate } from '../../utils';
import WxPagination from '../../../WxPagination';
import { usePagination } from '../../../WxPagination/utils';
import { OverrideComponent } from '../../../renderProps';
initI18n('Loyalty');
const Loyalty = props => {
    var _a;
    const { t } = useTranslation('Loyalty');
    const css = useTheme('Loyalty').Loyalty;
    const [selectedTab, setSelectedTab] = useState(0);
    const [accountHistoryError, setAccountHistoryError] = useState(null);
    const { data: ffpAccountHistory, refetch } = useFfpAccountHistoryQuery({
        skip: !props.ffpInfo,
        onError: error => setAccountHistoryError(error.message)
    });
    const [ffpAccountLogoutMutation] = useFfpAccountLogoutMutation();
    const { pagesCount, itemsPerPage, paginationAvailable, handleChangePage } = usePagination(ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory, 3);
    const milesHistory = (_a = ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) === null || _a === void 0 ? void 0 : _a.sort((a, b) => {
        const firstDate = parseDate(a.onTimeUTC), secondDate = parseDate(b.onTimeUTC);
        return secondDate.getTime() - firstDate.getTime();
    });
    const onCloseAccountHistoryError = () => {
        setAccountHistoryError(null);
    };
    const onLoyaltyCardSaved = () => {
        return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { data, errors } = yield refetch();
                const result = yield props.onLoyaltyCardSaved();
                if (errors.length) {
                    setAccountHistoryError(errors[0].message);
                }
                else {
                    resolve(!!(data === null || data === void 0 ? void 0 : data.FfpAccountHistory) && result);
                }
            }
            catch (_a) {
                reject(false);
            }
        }));
    };
    const onLogoutFfpAccount = () => {
        return new Promise((resolve, reject) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const { data } = yield ffpAccountLogoutMutation();
                const result = yield props.onLoyaltyCardSaved();
                resolve(!!(data === null || data === void 0 ? void 0 : data.FfpAccountLogout.result) && result);
            }
            catch (_a) {
                reject(false);
            }
        }));
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(LoyaltyInfo, { ffpInfo: props.ffpInfo, userInfo: props.userInfo, loyaltyName: props.loyaltyName, links: props.links, onLoyaltySaved: onLoyaltyCardSaved, onLogoutFfp: onLogoutFfpAccount, onGoToSettings: props.onGoToSettings, isLoading: props.isLoading }),
            (ffpAccountHistory === null || ffpAccountHistory === void 0 ? void 0 : ffpAccountHistory.FfpAccountHistory) && (React.createElement(OverrideComponent, { componentProps: {
                    className: css.milesHistory,
                    records: milesHistory.slice(itemsPerPage.startValue, itemsPerPage.endValue)
                }, component: { AccountMilesHistory: MilesHistory } })),
            !!paginationAvailable && (React.createElement(WxPagination, { className: css.pagination, pages: pagesCount, onHandleChange: handleChangePage })),
            props.vouchers && (React.createElement("div", { className: cn(css.card, css.promo) },
                React.createElement("h1", { className: css.promo__title }, t('Promo-code')),
                React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                    React.createElement(Tabs, { tabs: [
                            { header: t('Active'), value: 0 },
                            { header: t('Expired'), value: 1 }
                        ], onChange: setSelectedTab, selected: selectedTab }),
                    selectedTab === 0 && (React.createElement("div", { className: css.vouchers }, vouchersList
                        .filter(voucher => voucher.expires > new Date())
                        .map(voucher => (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) }))))),
                    selectedTab === 1 && (React.createElement("div", { className: css.vouchers }, vouchersList
                        .filter(voucher => voucher.expires <= new Date())
                        .map(voucher => (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) })))))),
                React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                    React.createElement("div", { className: css.vouchers },
                        vouchersList.map(voucher => (React.createElement(Voucher, { voucher: voucher, key: vouchersList.indexOf(voucher) }))),
                        vouchersList.length % 3 === 0 ? null : React.createElement("div", { style: { width: 233, height: 1 } })))))),
        React.createElement(WarningModal, { isOpen: !!accountHistoryError, onClose: onCloseAccountHistoryError, onButtonClick: onCloseAccountHistoryError, errorMessage: accountHistoryError, title: t('Oops...'), content: t('An error occurred while executing the request. Please try again later or contact a customer support service.'), buttonText: t('Ok') })));
};
export default Loyalty;
