import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../theme';
import { Button } from '../index';
const SuggestionModalContent = ({ onAgree, onDisagree, icon, text, isLoading, agreeButtonText, disagreeButtonText, insideSlideBottom }) => {
    const { SuggestionModalContent: theme } = useTheme('SuggestionModalContent');
    return (React.createElement("div", { className: cn(theme.modal__content, { [theme.modal__content_insideSlideBottom]: insideSlideBottom }) },
        React.createElement("div", null, icon),
        React.createElement("div", { className: theme.modal__text }, text),
        React.createElement(Button, { className: theme.modal__button, isLoading: isLoading, onClick: onAgree }, agreeButtonText),
        disagreeButtonText && onDisagree && (React.createElement(Button, { className: cn(theme.modal__button, theme.modal__button_decline), onClick: onDisagree }, disagreeButtonText))));
};
export default SuggestionModalContent;
