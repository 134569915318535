import { combineReducers } from 'redux';
import { passengersReducer } from './passengers/reducers';
import { segmentsReducer } from './segments/reducers';
import { redeemMilesReducer } from './redeemMiles/reducers';
import { vouchersReducer } from './vouchers/reducers';
import { recentSearchesReducer } from './recentSearches/reducers';
import promoCodeReducer from './promoCode/reducers';
import { passengersConfigReducer } from './passengersConfig/reducers';
import { offerHotelsReducer } from './offerHotels/reducers';
export const rootReducer = combineReducers({
    segments: segmentsReducer,
    passengers: passengersReducer,
    passengersConfig: passengersConfigReducer,
    redeemMiles: redeemMilesReducer,
    voucher: vouchersReducer,
    recentSearches: recentSearchesReducer,
    promoCode: promoCodeReducer,
    offerHotels: offerHotelsReducer
});
