import React from 'react';
import { initI18n } from '../../../../../utils';
import ModalConfirm from '../../../../../ModalConfirm';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../../../theme';
initI18n('Checkout');
const CheckDataModal = ({ isOpen, onClose, onCancel, onConfirm }) => {
    const { t } = useTranslation('Checkout');
    const { CheckDataModal: theme } = useTheme('Checkout');
    return (React.createElement(ModalConfirm, { classes: theme, isOpen: isOpen, onClose: onClose, onConfirm: onConfirm, onCancel: onCancel, title: t('Check the correctness of the entered data'), confirmBtn: t('Continue'), cancelBtn: t('Check') },
        React.createElement("div", { className: theme.contentWrapper }, t('You will not be able to change passenger details after purchasing a ticket.'))));
};
export default CheckDataModal;
