import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Button from '../Button/Button';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
initI18n('PaymentResult');
const PaymentResult = ({ result, onClick, isModal = false, onBackToOrder }) => {
    const css = useTheme('PaymentResult').PaymentResult;
    const { t } = useTranslation('PaymentResult');
    return (React.createElement("div", { className: cn(css.wrapper, {
            [css.wrapper_inModal]: isModal
        }) },
        React.createElement("div", { className: cn(css.header, {
                [css.success]: result === 'success',
                [css.failed]: result === 'fail'
            }) },
            React.createElement("div", { className: cn(css.paymentIcon, {
                    [css.paymentIcon_success]: result === 'success',
                    [css.paymentIcon_failed]: result === 'fail'
                }) }),
            React.createElement("div", { className: cn(css.dividerIcon, {
                    [css.dividerIcon_success]: result === 'success',
                    [css.dividerIcon_failed]: result === 'fail'
                }) })),
        React.createElement("div", { className: css.content },
            React.createElement("div", { className: css.title }, result === 'success' ? t('Payment successfully complete') : t('Payment has failed')),
            React.createElement("div", { className: css.buttons },
                result === 'fail' && onBackToOrder && (React.createElement(Button, { onClick: onBackToOrder, className: cn(css.redirectButton, css.button_failed) }, t('View booking'))),
                React.createElement(Button, { onClick: () => {
                        onClick(true);
                    }, className: css.redirectButton }, result === 'success' ? t('View booking') : t('Try again'))))));
};
export default PaymentResult;
