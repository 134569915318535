import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { Redirect, Route, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StepType } from '../../types';
import { initI18n, MOBILE_MIN_WIDTH } from '../../../utils';
import Stepbar from './Stepbar/Stepbar';
import MobileStepbar from '../../../MobileStepbar';
import PromoLoader from '../../../PromoLoader/components/PromoLoader';
import { convertSearchParameters } from '../../../Results/utils';
import { useTheme } from '../../../theme';
import Seats from './routes/Seats';
import Baggage from './routes/Baggage';
import Meal from './routes/Meal';
import Passengers from './routes/Passengers';
import Order from './routes/Order';
import { getIsAllSegmentsAvailableOnlyOnCheckin, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmedOrCancelled, getIsFareLockSelected, getIsNew, getIsOrderBlockedForPayment, getIsTravellersDataFilled, getOrder, getSearchParameters, getIsNeedToHideFlightPrice } from '../../store/order/selectors';
import { analyzePurchasedProducts as analyzePurchasedProductsAction, fillOrder as fillOrderAction } from '../../store/order/actions';
import PaymentTimeLimitPage from '../../../PaymentTimeLimitPage';
import Payment from './routes/Payment';
import PaymentResult from '../../../PaymentResult';
import Actualization from './Actualization/Actualization';
import { CheckoutSteps } from '../../../MobileStepbar/types';
import { OrderGoal, reachGoal } from '../../../analytics';
import ProtectedRoute from '../../../ProtectedRoute/ProtectedRoute';
import SimpleLoader from '../../../SimpleLoader';
import Modal from '../../../Modal';
import SlideBottom from '../../../SlideBottom';
import { isServicesSaving } from '../../store/servicesIsSaving/selectors';
import { useConfig } from '../../../context';
import Refund from '../../../Refund';
import Exchange from '../../../Modules/Exchange/components/Module';
import { ActualizationStatus, OrderStatus, PaymentStatusEnum, useActualizeOrderMutation, useGetAccountInfoQuery, useOrderQuery, useResetOrderPaymentBlockMutation } from '@websky/graphql';
import { useSubsidies } from './Form/SubsidyRequest/subsidyHook';
import { useSteps, useUpdateTravellerServices } from './hooks';
import { OverrideComponent } from '../../../renderProps';
import { createDataLayer } from '../../../DataLayerAnalytics/orderDataLayerInstance';
import { OrderType } from '../../../DataLayerAnalytics/OrderDataLayer/types';
import { StepsEnum } from '../../../types';
import { CustomContentPageEnum } from '../../../CustomContent/types';
import CustomContent from '../../../CustomContent/CustomContent';
import EditPassenger from '../../../EditPassenger';
import { pnrBlockedRegExp } from '../../../apolloErrorLink';
import { useCartPrices } from '../../../Cart/hooks';
import { Insurance } from './routes/Insurance';
import { useCart } from './Stepbar/hooks';
import { useIataCode } from '../../../hooks';
initI18n('Checkout');
const checkTimeLimit = (timelimitInSecond) => timelimitInSecond !== null && timelimitInSecond <= 0;
export var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
const Checkout = ({ match, history, location, startNewSearch }) => {
    var _a;
    createDataLayer(OrderType.Checkout);
    const theme = useTheme('Checkout').CheckoutStyles;
    const { t, i18n } = useTranslation();
    const order = useSelector(getOrder);
    const isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    const isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    const searchParameters = useSelector(getSearchParameters);
    const isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    const isNew = useSelector(getIsNew);
    const { steps, nextStep, setStep, currentStep, prevStep } = useSteps();
    const isOrderCancelled = useSelector(getIsCancelled);
    const isOrderServicesSaving = useSelector(isServicesSaving);
    const isFareLockSelected = useSelector(getIsFareLockSelected);
    const isBlockedForPayment = useSelector(getIsOrderBlockedForPayment);
    const isAllSegmentsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    const isNeedToHideFlightPrice = useSelector(getIsNeedToHideFlightPrice);
    const { onDeleteItem, loading: cartLoading } = useCart();
    const priceToPay = useCartPrices();
    const dispatch = useDispatch();
    const fillOrder = (orderParam) => dispatch(fillOrderAction(orderParam));
    const analyzePurchasedProducts = () => dispatch(analyzePurchasedProductsAction());
    const orderId = order.id;
    const [paymentResults, setPaymentResults] = useState(null);
    const [paymentResultsModalIsVisible, setPaymentResultsModalIsVisible] = useState(false);
    const [isLoading, setLoading] = useState(!order.actualizationResult);
    const [orderIsRefetching, setOrderIsRefetching] = useState(false);
    const [timelimitHasPast, setTimelimitHasPast] = useState(isBookedOrConfirmed && checkTimeLimit(order.timelimitInSeconds));
    const [showActualization, setShowActualization] = useState(false);
    const [bookingErr, setBookingErr] = useState(null);
    const [changePassengerId, setChangePassengerId] = useState(null);
    const [actualization] = useActualizeOrderMutation();
    const { refetch: refetchOrder, loading: refetchOrderLoading } = useOrderQuery({
        skip: true,
        fetchPolicy: 'no-cache',
        variables: {
            id: orderId
        }
    });
    const { data: userInfo, refetch: refetchUser, loading: userLoading } = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache'
    });
    const [resetOrderPaymentBlock, { loading: resetOrderLoading }] = useResetOrderPaymentBlockMutation();
    const [isResetOrderPaymentBlockLoading, setResetOrderPaymentBlockLoading] = useState(resetOrderLoading);
    const config = useConfig();
    const { global: { showIsActualizationResultOk, companyInfo: { iataCode } }, Engine: { ignoreBlockedPaymentToReturnOrder } } = config;
    useEffect(() => {
        setResetOrderPaymentBlockLoading(resetOrderLoading);
    }, [resetOrderLoading]);
    useUpdateTravellerServices();
    const closePaymentResultsModal = () => {
        setPaymentResultsModalIsVisible(false);
        setStep(StepsEnum.Order);
        setTimeout(() => setPaymentResults(null), 300);
    };
    const startActualize = () => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setLoading(true);
        const data = yield actualization({
            variables: {
                id: orderId
            }
        });
        if ((_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.ActualizeOrder) {
            fillOrder(data.data.ActualizeOrder);
        }
        setLoading(false);
    });
    const refetchOrderAfterPayment = () => __awaiter(void 0, void 0, void 0, function* () {
        const initialInterval = 500;
        const step = 1.5;
        const tryMaxCount = 10;
        setOrderIsRefetching(true);
        let tryCount = 0;
        const polling = (interval) => __awaiter(void 0, void 0, void 0, function* () {
            var _c;
            try {
                tryCount++;
                const data = yield refetchOrder();
                const order = (_c = data === null || data === void 0 ? void 0 : data.data) === null || _c === void 0 ? void 0 : _c.Order;
                const isFullyPayedOrder = (order === null || order === void 0 ? void 0 : order.status) === OrderStatus.Confirmed && (order === null || order === void 0 ? void 0 : order.paymentStatus) === PaymentStatusEnum.Full;
                const isPayedPriceFreeze = order.isPriceFreezeProductSelected &&
                    !isTravellersDataFilled &&
                    (order === null || order === void 0 ? void 0 : order.paymentStatus) === PaymentStatusEnum.Part;
                const orderIsCancelled = (order === null || order === void 0 ? void 0 : order.status) === OrderStatus.Cancelled;
                if (isFullyPayedOrder || isPayedPriceFreeze || orderIsCancelled) {
                    fillOrder(order);
                    setPaymentResultsModalIsVisible(true);
                    setOrderIsRefetching(false);
                }
                else {
                    if (tryCount <= tryMaxCount) {
                        setTimeout(() => polling(interval * step), interval * step);
                    }
                    else {
                        setOrderIsRefetching(false);
                    }
                }
            }
            catch (e) {
                setOrderIsRefetching(false);
                console.warn(e);
            }
        });
        setTimeout(() => polling(initialInterval), initialInterval);
    });
    const baseUrl = match.url;
    const refundURL = `${baseUrl}/refund`;
    const exchangeURL = `${baseUrl}/exchange`;
    const changePassengerDocsURL = `${baseUrl}/change-passenger-docs`;
    const fallbackUrl = `${baseUrl}/order`;
    const isPayment = location.pathname === `${baseUrl}/payment`;
    const isExchange = location.pathname.includes(exchangeURL);
    const isRefund = location.pathname.includes(refundURL);
    const isChange = location.pathname.includes(changePassengerDocsURL);
    const isDv = useIataCode() === 'DV';
    const retrySearch = startNewSearch.bind(null, convertSearchParameters(searchParameters));
    const backToResults = () => {
        if (order.flight.searchId) {
            history.push(`/results/${order.flight.searchId}`);
        }
        else {
            retrySearch();
        }
    };
    const goToRefund = () => history.push(refundURL);
    const goToExchange = () => history.push(exchangeURL);
    const goToChangePassenger = (passengerId) => {
        setChangePassengerId(passengerId);
        history.push(changePassengerDocsURL);
    };
    const goToPayment = useCallback(() => {
        setPaymentResultsModalIsVisible(false);
        history.push(`${baseUrl}/payment`);
    }, [paymentResults]);
    useEffect(() => {
        if (currentStep) {
            const contentPageByStep = {
                [StepsEnum.Seats]: CustomContentPageEnum.Seats,
                [StepsEnum.Meal]: CustomContentPageEnum.Meal,
                [StepsEnum.Baggage]: CustomContentPageEnum.Baggage,
                [StepsEnum.Order]: CustomContentPageEnum.Order,
                [StepsEnum.Payment]: CustomContentPageEnum.Payment
            };
            const contentPage = contentPageByStep[currentStep.type];
            if (contentPage) {
                CustomContent.sendCustomContentEvent({
                    config,
                    locale: i18n.language,
                    page: contentPage,
                    data: order
                });
            }
        }
    }, [currentStep, order]);
    useEffect(() => {
        let timer;
        if (isBookedOrConfirmed && order.timelimitInSeconds !== null) {
            setTimelimitHasPast(checkTimeLimit(order.timelimitInSeconds));
            // https://stackoverflow.com/questions/12633405/what-is-the-maximum-delay-for-setinterval
            const maxSignedInt = Math.pow(2, 31) - 1;
            const interval = maxSignedInt < order.timelimitInSeconds * 1000 ? maxSignedInt : order.timelimitInSeconds * 1000;
            timer = window.setInterval(() => {
                setTimelimitHasPast(true);
            }, interval);
        }
        return () => clearInterval(timer);
    }, [order]);
    useEffect(() => {
        var _a;
        if (!currentStep && order.actualizationResult && !isNew) {
            history.replace(fallbackUrl);
        }
        if (!isBookedOrConfirmed &&
            ((_a = order.actualizationResult) === null || _a === void 0 ? void 0 : _a.status) === ActualizationStatus.NotAvailable &&
            order.status === OrderStatus.New) {
            setShowActualization(true);
        }
        if (!order.actualizationResult) {
            startActualize();
        }
    }, []);
    useEffect(() => {
        if (paymentResults === PaymentResultStatus.Success) {
            analyzePurchasedProducts();
            reachGoal(OrderGoal.Paid);
            refetchOrderAfterPayment();
        }
        else if (paymentResults === PaymentResultStatus.Fail) {
            reachGoal(OrderGoal.PaymentFailed);
            setPaymentResultsModalIsVisible(true);
            if (isBlockedForPayment) {
                resetPaymentBlock();
            }
        }
    }, [paymentResults]);
    const stepbarConfig = {
        [CheckoutSteps.Flights]: {
            label: t('Stepbar:Flights'),
            type: CheckoutSteps.Flights,
            onClick: backToResults,
            isDisabled: !!((_a = steps[0]) === null || _a === void 0 ? void 0 : _a.disabled)
        }
    };
    steps.forEach(step => {
        stepbarConfig[step.type] = {
            label: t(`Stepbar:${step.type}`),
            type: CheckoutSteps[step.type],
            onClick: () => setStep(step.type),
            isDisabled: step.disabled
        };
    });
    const goToPrevStep = () => __awaiter(void 0, void 0, void 0, function* () {
        currentStep.type === steps[0].type ? backToResults() : prevStep();
    });
    const hasPaymentStep = steps.some(step => step.type === StepType.Payment);
    const getCurrentStep = () => steps.findIndex(step => location.pathname.search(step.url) >= 0) + 1;
    const goToOrder = (reload) => {
        setStep(StepType.Order);
        if (reload) {
            window.location.reload();
        }
    };
    const setBookingErrorHandler = (error) => {
        if (!pnrBlockedRegExp.test(error === null || error === void 0 ? void 0 : error.message)) {
            setBookingErr(error);
        }
    };
    const onPaymentSuccess = useCallback((reload) => {
        reachGoal(OrderGoal.Paid);
        analyzePurchasedProducts();
        goToOrder(reload);
    }, []);
    const handlePaymentMethodsLoaded = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield refetchOrder({ id: orderId });
        if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
            fillOrder(data.Order);
        }
    }), [isBlockedForPayment]);
    const handleRefetchCheckoutOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const { data, errors } = yield refetchOrder({ id: orderId });
        if (!(errors === null || errors === void 0 ? void 0 : errors.length) && (data === null || data === void 0 ? void 0 : data.Order)) {
            fillOrder(data.Order);
        }
        return data.Order;
    }), [refetchOrder, orderId]);
    const resetPaymentBlock = () => __awaiter(void 0, void 0, void 0, function* () {
        setResetOrderPaymentBlockLoading(true);
        yield resetOrderPaymentBlock({ variables: { id: orderId } });
        setResetOrderPaymentBlockLoading(false);
    });
    const handleReturnToOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (isBlockedForPayment) {
            yield resetPaymentBlock();
        }
        const { data } = yield refetchOrder({ id: orderId });
        if (data === null || data === void 0 ? void 0 : data.Order) {
            fillOrder(data.Order);
            history.push(`${baseUrl}/order`);
        }
        setPaymentResultsModalIsVisible(false);
    }), [isBlockedForPayment]);
    const onRefetchUser = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const { data } = yield refetchUser();
            if (data === null || data === void 0 ? void 0 : data.CurrentUser) {
                yield handleRefetchCheckoutOrder();
                return data;
            }
            return null;
        }
        catch (e) {
            console.log(e.message);
            return null;
        }
    }), [refetchUser, handleRefetchCheckoutOrder]);
    const orderSubsidyState = useSubsidies();
    const isCanceledOrConfirmed = iataCode === 'WZ' ? false : isConfirmedOrCancelled;
    const onOrderBooked = (order) => {
        reachGoal(OrderGoal.Booked);
        fillOrder(order);
        nextStep();
    };
    const isBookedUserLoading = order.status === OrderStatus.Booked || order.status === OrderStatus.Confirmed ? userLoading : false;
    const onCloseChangePassenger = () => {
        history.replace(fallbackUrl);
        setChangePassengerId(null);
    };
    return (React.createElement("div", { className: cn(theme.container, {
            [theme.container_locked]: !isLoading && timelimitHasPast,
            [theme.container_seats]: !timelimitHasPast && currentStep && currentStep.type === StepType.Seats
        }) },
        !isBookedOrConfirmed && (React.createElement(Actualization, { startBooking: () => null, orderId: orderId, open: showActualization, showOkMessage: showIsActualizationResultOk, onClose: () => setShowActualization(false), onSetOrder: fillOrder, actualizationInfo: order.actualizationResult, backToResults: backToResults })),
        React.createElement(ProtectedRoute, { isAccessible: isConfirmedOrCancelled, redirectTo: fallbackUrl, path: `${baseUrl}/refund`, render: () => (React.createElement(React.Fragment, null,
                refetchOrderLoading && React.createElement(PromoLoader, null),
                !refetchOrderLoading && (React.createElement(Refund, { order: order, onReloadCheckoutOrder: handleRefetchCheckoutOrder })))) }),
        React.createElement(ProtectedRoute, { isAccessible: !!changePassengerId || !!order.exareInfo.lastChangeTravellerDataOrder, redirectTo: fallbackUrl, path: changePassengerDocsURL, render: () => (React.createElement(EditPassenger, { passengerId: changePassengerId, refetchOrder: handleRefetchCheckoutOrder, returnToOrder: onCloseChangePassenger })) }),
        isLoading && React.createElement(PromoLoader, null),
        (orderIsRefetching || isOrderServicesSaving || isResetOrderPaymentBlockLoading || cartLoading) && (React.createElement(SimpleLoader, null)),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(SlideBottom, { isOpen: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, className: theme.payment__slideBottom },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment, onBackToOrder: handleReturnToOrder })))),
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Modal, { open: paymentResultsModalIsVisible, onClose: closePaymentResultsModal, classes: {
                    paper: theme.payment__result
                }, closeClassName: theme.payment__close },
                paymentResults === 'success' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: closePaymentResultsModal })),
                paymentResults === 'fail' && (React.createElement(PaymentResult, { isModal: true, result: paymentResults, onClick: goToPayment, onBackToOrder: handleReturnToOrder })))),
        !isLoading && timelimitHasPast && (React.createElement("div", null,
            React.createElement(PaymentTimeLimitPage, { title: t('PaymentTimeLimit:Sorry,'), text: t('PaymentTimeLimit:time to complete booking has expired'), buttonText: t('PaymentTimeLimit:Repeat search'), onClick: backToResults }))),
        !isLoading && !timelimitHasPast && (React.createElement(React.Fragment, null,
            React.createElement(Route, { exact: true, path: baseUrl, render: () => order.status === OrderStatus.New ? (React.createElement(Redirect, { to: `${baseUrl}/${steps[0].url}` })) : (React.createElement(Redirect, { to: fallbackUrl })) }),
            !isBookedUserLoading && !isPayment && !isExchange && !isRefund && !isChange && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement(OverrideComponent, { componentProps: {
                        order,
                        activeStep: getCurrentStep(),
                        steps: stepbarConfig,
                        currentStep: getCurrentStep(),
                        stepbarConfig: stepbarConfig,
                        hideFlightPrice: isNeedToHideFlightPrice,
                        onServiceDelete: onDeleteItem
                    }, component: {
                        CheckoutDesktopStepbar: Stepbar
                    } }))),
            !isCanceledOrConfirmed && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
                React.createElement(MobileStepbar, { order: order, currentStep: getCurrentStep(), stepbarConfig: stepbarConfig, hideFlightPrice: isNeedToHideFlightPrice, onServiceDelete: onDeleteItem }))),
            React.createElement(ProtectedRoute, { path: `${baseUrl}/seats`, isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: () => {
                    const protectedComponent = (React.createElement(OverrideComponent, { componentProps: {
                            suggestSeatSelection: !isAllSegmentsAvailableOnCheckin,
                            goToNextStep: nextStep,
                            goToPrevStep
                        }, component: { CheckoutSeats: Seats } }));
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(Route, { path: `${baseUrl}/exchange`, render: () => {
                    return React.createElement(Exchange, { orderId: orderId, onRefetchCheckoutOrder: handleRefetchCheckoutOrder });
                } }),
            React.createElement(ProtectedRoute, { path: `${baseUrl}/baggage`, isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: () => {
                    const protectedComponent = React.createElement(Baggage, { goToPrevStep: goToPrevStep, goToNextStep: nextStep });
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        else {
                            return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                        }
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(ProtectedRoute, { path: `${baseUrl}/meal`, isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: () => {
                    const protectedComponent = React.createElement(Meal, { goToPrevStep: goToPrevStep, goToNextStep: nextStep });
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(ProtectedRoute, { path: `${baseUrl}/insurance`, isAccessible: !isOrderCancelled, redirectTo: fallbackUrl, render: () => {
                    const protectedComponent = (React.createElement(Insurance, { goToNextStep: nextStep, goToPrevStep: goToPrevStep, steps: steps }));
                    if (order.isBlockedForPayment) {
                        if (isDv) {
                            return protectedComponent;
                        }
                        return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                    }
                    else {
                        return protectedComponent;
                    }
                } }),
            React.createElement(Route, { path: `${baseUrl}/passengers`, render: () => {
                    // для мягкого перехода на новый набор шагов, после изменения в пхп - убрать
                    if (isBlockedForPayment && hasPaymentStep && !isFareLockSelected) {
                        return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                    }
                    return (React.createElement(Passengers, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, showRefundButton: !!order.exareInfo.lastRefund || order.exareInfo.refundAvailability, showExchangeButton: !!order.exareInfo.lastExchange || order.exareInfo.exchangeAvailability, onSetOrder: fillOrder, onOrderBooked: onOrderBooked, setBookingError: setBookingErrorHandler, bookingError: bookingErr, backToResults: backToResults, currentStep: currentStep, goToPrevStep: goToPrevStep, userInfo: userInfo === null || userInfo === void 0 ? void 0 : userInfo.CurrentUser, refetchUser: onRefetchUser, orderSubsidyState: orderSubsidyState, setStep: setStep, steps: steps, setNextStep: nextStep }));
                } }),
            React.createElement(Route, { path: `${baseUrl}/order`, render: () => {
                    if (userLoading) {
                        return React.createElement(PromoLoader, null);
                    }
                    if (!isTravellersDataFilled && isNew) {
                        return React.createElement(Redirect, { to: `${baseUrl}/passengers` });
                    }
                    if (isBlockedForPayment && hasPaymentStep && !isFareLockSelected) {
                        return React.createElement(Redirect, { to: `${baseUrl}/payment` });
                    }
                    return (React.createElement(Order, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, setBookingError: setBookingErrorHandler, bookingError: bookingErr, userInfo: userInfo === null || userInfo === void 0 ? void 0 : userInfo.CurrentUser, refetchUser: onRefetchUser, currentStep: currentStep, paymentSuccess: paymentResults === PaymentResultStatus.Success, setStep: setStep, setNextStep: nextStep }));
                } }),
            React.createElement(ProtectedRoute, { exact: true, path: `${baseUrl}/payment`, isAccessible: hasPaymentStep, redirectTo: fallbackUrl, render: params => (React.createElement(Payment, { onBackToOrder: (isBlockedForPayment || ignoreBlockedPaymentToReturnOrder) && handleReturnToOrder, priceToPay: priceToPay, orderId: orderId, order: order, timelimit: order.timelimit ? new Date(order.timelimit) : null, redirectPathname: params.location.pathname, onPaymentMethodsLoaded: handlePaymentMethodsLoaded, onSuccess: onPaymentSuccess, goToPrevStep: handleReturnToOrder, orderPaymentResult: paymentResults, orderIsRefetching: orderIsRefetching })) }),
            React.createElement(Route, { path: `${baseUrl}/payment/successfull`, render: () => {
                    setPaymentResults(PaymentResultStatus.Success);
                    return React.createElement(Redirect, { to: fallbackUrl });
                } }),
            React.createElement(Route, { path: `${baseUrl}/payment/failed`, render: () => {
                    setPaymentResults(PaymentResultStatus.Fail);
                    return React.createElement(React.Fragment, null);
                } })))));
};
export default withRouter(Checkout);
