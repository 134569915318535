import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Link from '../../../Link';
import Button from '../../../Button';
import { useTheme } from '../../../theme';
const LoginBlock = ({ onClick, onSkipClick }) => {
    const { LoginBlock: css } = useTheme('Contacts');
    const { t } = useTranslation('Contacts');
    const onSkipButtonClick = (event) => {
        event.preventDefault();
        onSkipClick();
    };
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.text }, t('Want to speed up passenger data entry? Sign in and continue.')),
        React.createElement("div", { className: css.signIn },
            React.createElement(Link, { className: css.signIn__skip, action: onSkipButtonClick }, t('Continue without signing in')),
            React.createElement(Button, { onClick: onClick }, t('Sign in')))));
};
export default LoginBlock;
