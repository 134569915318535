import * as React from 'react';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import PromoCode from '../../../../../PromoCode';
import { clearPromoCode, setPromoCode } from '../../../../store/promoCode/actions';
import { PromoCodeStatus } from '../../../../../PromoCode/types';
import { usePromoCode } from '../../../../../PromoCode/hooks';
import { getPromoCode, hasPromoCode } from '../../../../store/promoCode/selectors';
import { getSearchParameters } from '../../../../store/selectors';
import { useTheme } from '../../../../../theme';
const SearchFormPromoCode = props => {
    var _a, _b;
    const dispatch = useDispatch();
    const { PromoCode: theme } = useTheme('SearchForm');
    const isPromoCodeApplied = useSelector(hasPromoCode);
    const promoCode = useSelector(getPromoCode);
    const { isLoading, setLoading, setNotLoading, openForm, closeForm, status, setStatus, isFormOpen } = usePromoCode(isPromoCodeApplied ? PromoCodeStatus.Applied : PromoCodeStatus.Empty);
    useEffect(() => {
        if (props.promoCode) {
            dispatch(setPromoCode(props.promoCode));
        }
    }, [props.promoCode]);
    let handleAddPromoCode = useCallback(({ promoCode }) => {
        setLoading();
        dispatch(setPromoCode(promoCode));
        setTimeout(() => {
            setNotLoading();
            setStatus(PromoCodeStatus.Provided);
        }, 500);
        setTimeout(closeForm, 1000);
    }, [status, isFormOpen]);
    if (props.onAddPromoCode) {
        const searchParams = useSelector(getSearchParameters);
        handleAddPromoCode = useCallback(({ promoCode }) => {
            dispatch(setPromoCode(promoCode));
            props.onAddPromoCode(Object.assign(Object.assign({}, searchParams), { promotionCode: promoCode }));
        }, [status, isFormOpen, searchParams]);
    }
    let handleClearPromoCode = useCallback(() => {
        setStatus(PromoCodeStatus.Empty);
        dispatch(clearPromoCode());
    }, [isPromoCodeApplied]);
    if (props.onClearPromoCode) {
        const searchParams = useSelector(getSearchParameters);
        handleClearPromoCode = useCallback(() => {
            dispatch(clearPromoCode());
            props.onClearPromoCode(searchParams);
        }, [isPromoCodeApplied]);
    }
    return (React.createElement(PromoCode, { className: cn(props.className, theme.promoCode), promoCode: promoCode, onAddPromoCode: handleAddPromoCode, isLoading: isLoading, isFormOpen: (_a = props.isFormOpen) !== null && _a !== void 0 ? _a : isFormOpen, onCloseForm: props.onClose ? props.onClose : closeForm, onOpenForm: props.onOpen ? props.onOpen : openForm, onClear: handleClearPromoCode, status: (_b = props.status) !== null && _b !== void 0 ? _b : status }));
};
export default SearchFormPromoCode;
