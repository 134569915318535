import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import BaggagePage from '../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { getPassengerLabels, getSelectedBaggage } from '../../../store/passengers/selectors';
import { getAdditionalBaggage, getBaggage, getBonusForFlight, getBrandIncludedBaggage, getIsConfirmed, getIsOddOrderId, getOrder, getOverweightBaggage, getPriceInMiles, getSegmentsForBaggage, getSpecialEquipment, getUpgradeBaggage, hasSeatsServices } from '../../../store/order/selectors';
import { setSplitTest, SplitTest } from '../../../../analytics';
import { MOBILE_MIN_WIDTH } from '../../../../utils';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getBaggagePriceToPay, getTotalPriceToPay } from '../../../store/orderPrice/selectors';
import { addServiceAction, removeServiceAction, setMultipleSelectedServices, setServiceAction } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { getBaggageReduxCallbacks } from '../../../utils';
import { useConfig } from '../../../../context';
import { usePreselectedBaggageSelect } from '../../../hooks';
import { OrderAdditionalServiceGdsServiceServiceType, OrderStatus } from '@websky/graphql';
import UpsaleServicesModal from '../../../../UpsaleServicesModal/UpsaleServicesModal';
import { isServicesSaving } from '../../../store/servicesIsSaving/selectors';
import InfoMessagesContextProvider from '../../../../InfoMessages/context';
const Baggage = ({ goToPrevStep, goToNextStep: setNextStep }) => {
    const { t } = useTranslation('Checkout');
    const css = useTheme('Checkout').CheckoutStyles;
    const isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 });
    const order = useSelector(getOrder);
    const segments = useSelector(getSegmentsForBaggage);
    const isOddOrderId = useSelector(getIsOddOrderId);
    const selectedBaggage = useSelector(getSelectedBaggage);
    const baggage = useSelector(getBaggage);
    const includedBaggage = useSelector(getBrandIncludedBaggage);
    const upgradeBaggage = useSelector(getUpgradeBaggage);
    const additionalBaggage = useSelector(getAdditionalBaggage);
    const specialBaggage = useSelector(getSpecialEquipment);
    const overweightBaggage = useSelector(getOverweightBaggage);
    const bonusForFlight = useSelector(getBonusForFlight);
    const priceInMiles = useSelector(getPriceInMiles);
    const isLoading = useSelector(isServicesSaving);
    const isConfirmed = useSelector(getIsConfirmed);
    const passengers = useSelector(getPassengerStateCompatible);
    const totalPrice = useSelector(getTotalPriceToPay);
    const hasSeatsStep = useSelector(hasSeatsServices);
    const passengerLabels = useSelector(getPassengerLabels);
    const baggageTotalSum = useSelector(getBaggagePriceToPay);
    const dispatch = useDispatch();
    const addService = (payload) => dispatch(addServiceAction(payload));
    const removeService = (payload) => dispatch(removeServiceAction(payload));
    const setService = (payload) => dispatch(setServiceAction(payload));
    const disable = order.status === OrderStatus.Cancelled || !order.canAddServices;
    const { global: { maxBaggage, maxBaggagePerPassenger, dynamicBaggagePricing, companyInfo: { iataCode } } } = useConfig();
    usePreselectedBaggageSelect(['N4', '5G', 'JI', 'WZ'].includes(iataCode));
    const simpleBaggageSelector = !maxBaggage && !maxBaggagePerPassenger && isOddOrderId && (segments.length === 1 || isMobile);
    setSplitTest(SplitTest.OneButtonBaggageSelector, simpleBaggageSelector);
    const baggageCallbacks = useMemo(() => getBaggageReduxCallbacks(passengers, baggage, segments, addService, removeService, setService), [passengers, baggage, segments, addService, removeService, setService]);
    const setAdditionalBaggage = useCallback((services) => {
        dispatch(setMultipleSelectedServices(services));
    }, []);
    return (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice,
            onButtonClick: setNextStep,
            onBackButtonClick: hasSeatsStep ? goToPrevStep : null,
            additional: baggageTotalSum.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Baggage'),
                ": ",
                React.createElement(Money, { money: baggageTotalSum }))) : null,
            miles: bonusForFlight,
            priceInMiles
        }, mobileToolbarProps: {
            onContinue: setNextStep,
            onBack: goToPrevStep
        } },
        React.createElement(InfoMessagesContextProvider, { value: { messages: order.messages } },
            React.createElement(BaggagePage, Object.assign({ passengers: passengers, passengerLabels: passengerLabels, segments: segments, baggage: baggage, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, simpleBaggageSelector: simpleBaggageSelector, setAdditionalBaggage: setAdditionalBaggage, readonly: isConfirmed, dynamicBaggagePricing: dynamicBaggagePricing, isLoading: isLoading }, baggageCallbacks, { disabled: disable }))),
        React.createElement(UpsaleServicesModal, { travellers: order.travellers, additionalServices: order.additionalServices, serviceType: OrderAdditionalServiceGdsServiceServiceType.Baggage, onRefuseServices: payload => {
                if (payload.length) {
                    payload.forEach(removeService);
                }
            }, descriptionRender: (upsaleService, description) => t(description !== null && description !== void 0 ? description : 'We recommend using extra baggage', { name: upsaleService === null || upsaleService === void 0 ? void 0 : upsaleService.name }) })));
};
export default Baggage;
