import { __awaiter } from "tslib";
import * as React from 'react';
import { useState } from 'react';
import { MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import LoyaltyConnect, { ConnectType } from '../../../Loyalty/components/LoyaltyStub/LoyaltyConnect/LoyaltyConnect';
import { useTheme } from '../../../theme';
import { useLoyaltyInfo } from '../../hooks';
import PriceInMiles from '../../../PriceInMiles/PriceInMiles';
import Menu from '../Menu/Menu';
import Button from '../../../BaseComponents/Button';
import SureModal from '../../../SureModal';
import MilesRecovery from '../../../MilesRecovery/components/MilesRecovery';
import { OverrideComponent } from '../../../renderProps';
import FfpInfoStatuses from '../FfpInfoStatuses/FfpInfoStatuses';
import { useToggleable } from '../../../hooks';
import AddToAppleWallet from './AddToAppleWallet/AddToAppleWallet';
const FfpInfo = ({ className, userInfo, ffpInfo, links, showLinksCount = 4, onLoyaltySaved, onLogoutFfp, onGoToSettings, isLoading, hideProgress }) => {
    const { FfpInfo: css } = useTheme('LoyaltyInfo');
    const { t } = useTranslation('Loyalty');
    const [connectType, setConnectType] = useState(null);
    const { isOpen, setOpen } = useToggleable(false);
    const onCloseConnect = () => {
        setConnectType(null);
        setOpen(false);
    };
    React.useEffect(() => {
        if (connectType) {
            setOpen(true);
        }
        else {
            setConnectType(null);
        }
    }, [connectType]);
    const { nextLevel, milesPercentage, faresPercentage } = useLoyaltyInfo(ffpInfo);
    const setConnectTypeHandler = (type) => () => setConnectType(type);
    const [mainLinks, otherLinks] = [links.slice(0, showLinksCount), links.slice(showLinksCount)];
    const renderLinks = () => (React.createElement("div", { className: css.links }, mainLinks
        .filter(({ url }) => !!url)
        .map((link, index) => (React.createElement("a", { href: link === null || link === void 0 ? void 0 : link.url, target: "_blank", className: css.links_item, key: index }, t(link.title))))));
    React.useEffect(() => {
        if (isLoading) {
            onCloseConnect();
        }
    }, [isLoading]);
    const onLoyaltyCardSaved = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onLoyaltySaved();
        onCloseConnect();
    });
    const onLogoutFfpHandler = () => __awaiter(void 0, void 0, void 0, function* () {
        yield onLogoutFfp();
    });
    const [isDataLoading, setDataLoading] = useState(false);
    const onDataLoading = (state) => {
        setDataLoading(state);
    };
    if (!ffpInfo) {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.container, className) },
                React.createElement("div", { className: css.text }, t('Start saving now')),
                renderLinks(),
                !isLoading && (React.createElement("div", { className: css.footer },
                    React.createElement(Button, { className: cn(css.button, css.button_gray), variant: "smallSecondary", onClick: setConnectTypeHandler(ConnectType.Login), isLoading: isLoading }, t('I have a card')),
                    React.createElement(Button, { className: css.button, variant: "smallNeitral", onClick: setConnectTypeHandler(ConnectType.Create), isLoading: isDataLoading }, t('Registration')))),
                isLoading && (React.createElement("div", { className: css.loader },
                    React.createElement(Button, { className: css.loader__indicator, isLoading: true }),
                    t('Please wait, we are updating information about your loyalty card. It make take a  few minutes...')))),
            React.createElement(LoyaltyConnect, { userInfo: userInfo, isOpen: isOpen, onDataLoading: onDataLoading, type: connectType || undefined, onLoyaltyCardSaved: onLoyaltyCardSaved, onClose: onCloseConnect, onGoToSettings: onGoToSettings })));
    }
    return (React.createElement("div", { className: cn(css.container, className) },
        React.createElement("div", { className: css.header },
            React.createElement(PriceInMiles, { moneyClassName: css.miles, currencyClassName: css.miles_currency, amount: ffpInfo.numberOfMiles }),
            React.createElement(Menu, { className: css.menu }, onClickMenu => [
                ...otherLinks.map((link, index) => {
                    const openUrl = () => window.open(link.url);
                    return (React.createElement(MenuItem, { key: index, onClick: onClickMenu(openUrl) }, link.title));
                }),
                React.createElement(OverrideComponent, { componentProps: {
                        renderTrigger: onClick => React.createElement(MenuItem, { onClick: onClick }, t('Miles recovery'))
                    }, component: { MilesRecovery } }),
                React.createElement(SureModal, { title: t('You sure?'), description: t('Delete card from your account?'), onSuccess: onLogoutFfpHandler, renderTrigger: onClick => (React.createElement(MenuItem, { onClick: onClick }, t('Exit and add another card'))) })
            ])),
        nextLevel && !hideProgress && (React.createElement(OverrideComponent, { componentProps: {
                milesPercentage,
                faresPercentage,
                ffpInfo,
                nextLevel
            }, component: {
                FfpInfoStatuses
            } })),
        !nextLevel && !ffpInfo.milesForNextLevel && (React.createElement(OverrideComponent, { componentProps: { ffpInfo }, component: {
                FfpInfoStatusesMaxLevel: () => null
            } })),
        renderLinks(),
        React.createElement(AddToAppleWallet, null)));
};
export default FfpInfo;
