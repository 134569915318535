import { __rest } from "tslib";
import * as React from 'react';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { Datepicker } from '../../Datepicker/components/Datepicker';
import { Provider as PriceMatrixProvider } from '../../PriceMatrix/theme';
import { priceGraphTheme, priceMatrixTheme } from '../theme';
import { RouteType } from '../../PriceGraph/types';
import { DialogAdapter } from './DialogAdapter/DialogAdapter';
import { format, initI18n, mergeCSS, MOBILE_MIN_WIDTH, removeDotsFromDate, TABLET_MIN_WIDTH } from '../../utils';
import { BaseButton } from '../../index';
import { ThemeProvider, useTheme } from '../../theme';
import DummyPriceGraph from '../../Results/components/Results/Calendar/DummyPriceGraph/DummyPriceGraph';
import { Money } from '../../Money';
import LinearProgress from '@material-ui/core/LinearProgress';
import EmptyDatesOverlay from './EmptyDatesOverlay/EmptyDatesOverlay';
import { useRenderers } from '../../renderProps';
import { DatepickerTab } from '../../SearchForm/components/SearchForm/Segments/Segment/Datepicker/Datepicker';
import { isMultiCity } from '../../SearchForm/store/segments/selectors';
import { Currency } from '../../enums';
initI18n('SearchFormDatepicker');
var TabTypes;
(function (TabTypes) {
    TabTypes[TabTypes["PlainCalendar"] = 0] = "PlainCalendar";
    TabTypes[TabTypes["PriceGraph"] = 1] = "PriceGraph";
    TabTypes[TabTypes["RoundtripMatrix"] = 2] = "RoundtripMatrix";
})(TabTypes || (TabTypes = {}));
const calendarIcon = (React.createElement("svg", { width: "18", height: "20", viewBox: "0 0 18 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M16 2H15V0H13V2H5V0H3V2H2C0.89 2 0.01 2.9 0.01 4L0 18C0 19.1 0.89 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM4 9V11H6V9H4ZM10 9H8V11H10V9ZM14 9V11H12V9H14ZM2 18H16V7H2V18Z", fill: "#A5A5A5" })));
const graphIcon = (React.createElement("svg", { width: "16", height: "16", viewBox: "0 0 16 16", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M6 16H10V0H6V16ZM0 16H4V8H0V16ZM12 16V5H16V16H12Z", fill: "#A5A5A5" })));
const PriceGraph = React.lazy(() => import('../../PriceGraph/components/PriceGraph'));
const PriceMatrix = React.lazy(() => import('../../PriceMatrix/components/PriceMatrix'));
const SearchFormDatepicker = React.memo(forwardRef((props, ref) => {
    var _a;
    const { t } = useTranslation('SearchFormDatepicker');
    const [tab, setTab] = useState(TabTypes.PlainCalendar), [isOpen, toggleOpen] = useState(false), outDate = props.selectedDates && props.selectedDates.length ? new Date(props.selectedDates[0]) : null, returnDate = props.selectedDates && props.selectedDates.length > 1 ? new Date(props.selectedDates[1]) : null, isTabDisplay = props.showPriceMatrix || props.showPriceGraph, isMobile = useMediaQuery({ maxWidth: +MOBILE_MIN_WIDTH + 1 }), isNarrowDesktop = useMediaQuery({ maxWidth: 1160 });
    const isMultyCity = useSelector(isMultiCity);
    const { renderAdaptiveSearchFormDatepickerControls, renderMoneyAmount: overridedRenderMoneyAmount } = useRenderers();
    const refEl = useRef(null);
    const contentRef = React.createRef();
    const onClose = () => {
        toggleOpen(false);
        props.clearOpenDate && props.clearOpenDate();
        if (props.onClose) {
            props.onClose();
        }
    };
    const onDone = () => {
        toggleOpen(false);
        if (props.onDone) {
            props.onDone();
        }
    };
    const open = () => {
        var _a;
        toggleOpen(true);
        (_a = props.onOpen) === null || _a === void 0 ? void 0 : _a.call(props);
    };
    const onSelect = (date) => {
        if (props.onSelect) {
            props.onSelect(date, props.routeType === 'return');
        }
        if (props.closeAfterSelectReturnDate && props.closeAfterSelectReturnDate(date)) {
            toggleOpen(false);
        }
    };
    const { Datepicker: searchFormDatepickerTheme } = useTheme('SearchForm');
    const _b = useTheme('SearchFormDatepicker'), { SearchFormDatepickerStyles: datepickerTheme } = _b, defaultDatepickerTheme = __rest(_b, ["SearchFormDatepickerStyles"]);
    let showOverlay = false;
    if (props.routeType === DatepickerTab.Outbound && props.pricesTo === null) {
        showOverlay = true;
    }
    else if (props.routeType === DatepickerTab.Return && (props.pricesTo === null || props.pricesBack === null)) {
        showOverlay = true;
    }
    if (!isMobile) {
        showOverlay = false;
    }
    const pricesLoaded = () => {
        if (props.routeType === 'outbound' && Object.keys(props.pricesTo).length) {
            return true;
        }
        else if (props.routeType === 'return' &&
            Object.keys(props.pricesTo).length &&
            Object.keys(props.pricesBack).length) {
            return true;
        }
        return false;
    };
    const datepickerStyles = useTheme('Datepicker');
    const legendStyles = useTheme('PriceGraph').LegendsStyles;
    const datePickerWithPricesTheme = {
        Datepicker: Object.assign(Object.assign({}, defaultDatepickerTheme), { WeeklyHeaderStyles: Object.assign(Object.assign({}, defaultDatepickerTheme.WeeklyHeaderStyles), { dayOfWeekName: defaultDatepickerTheme.WeeklyHeaderStyles.dayOfWeekName_withPrices }), MonthStyles: Object.assign(Object.assign({}, defaultDatepickerTheme.MonthStyles), { week: defaultDatepickerTheme.MonthStyles.week_withPrice }), DayStyles: Object.assign(Object.assign({}, defaultDatepickerTheme.DayStyles), { day: defaultDatepickerTheme.DayStyles.day_withPrice, day_endHover: defaultDatepickerTheme.DayStyles.day_endHover_withPrice, day_startHover: defaultDatepickerTheme.DayStyles.day_startHover_withPrice }) })
    };
    const customStyles = {
        Datepicker: Object.assign(Object.assign({}, defaultDatepickerTheme), { DayStyles: Object.assign(Object.assign({}, defaultDatepickerTheme.DayStyles), { day: props.datepickerAlternativeHighlighting
                    ? datepickerTheme.day
                    : defaultDatepickerTheme.DayStyles.day }), MonthsWrapperStyles: Object.assign(Object.assign({}, defaultDatepickerTheme.MonthsWrapperStyles), { wrapper: cn(defaultDatepickerTheme.MonthsWrapperStyles.wrapper, datepickerTheme.wrapper), nav_prev: cn(defaultDatepickerTheme.MonthsWrapperStyles.nav_prev, datepickerTheme.nav_prev), nav_next: cn(defaultDatepickerTheme.MonthsWrapperStyles.nav_next, datepickerTheme.nav_next) }) })
    };
    const defaultStyles = {
        Datepicker: Object.assign({}, datepickerStyles)
    };
    // todo: react.memo does not make deep compare, if we want to memoise mergedTheme, we can pass JSON.stringify(defaultStyles), JSON.strignify(customStyles) do deps array
    const mergedTheme = React.useMemo(() => mergeCSS(defaultStyles, props.useThemeWithPrices ? datePickerWithPricesTheme : customStyles), [defaultStyles, customStyles]);
    const renderMoneyAmount = (amount, currency) => {
        return amount.toString().length > 6
            ? t('{{amount}}{{k}}.', { amount: Math.floor(amount / 1000), k: currency === Currency.RUB ? 'т' : 'k' })
            : amount;
    };
    const dayWithPrice = (date) => {
        var _a, _b, _c;
        const compareString = date.toISOString();
        let price = null;
        const pricesMap = props.routeType === 'outbound' ? props.pricesTo : props.pricesBack;
        const hasFlight = (_a = pricesMap === null || pricesMap === void 0 ? void 0 : pricesMap[compareString]) === null || _a === void 0 ? void 0 : _a.hasFlight;
        if (pricesMap && pricesMap[compareString] && ((_c = (_b = pricesMap[compareString]) === null || _b === void 0 ? void 0 : _b.price) === null || _c === void 0 ? void 0 : _c.amount)) {
            price = pricesMap[compareString].price;
        }
        return (React.createElement("div", { className: cn({ [defaultDatepickerTheme.DayStyles.day_hasPrice]: price || hasFlight }) },
            date.getDate(),
            price && (React.createElement(Money, { moneyClassName: cn(defaultDatepickerTheme.DayStyles.day__price, searchFormDatepickerTheme.day__price), money: price, amountRenderer: overridedRenderMoneyAmount || renderMoneyAmount }))));
    };
    const handleOverlayButtonClick = React.useCallback(() => {
        setTab(TabTypes.PlainCalendar);
    }, []);
    const renderTabletFooter = useCallback(({ notActive, onClear, isDoneable, onDone }) => {
        return (React.createElement(MediaQuery, { minWidth: TABLET_MIN_WIDTH },
            React.createElement("div", { className: datepickerTheme.footer__buttons },
                React.createElement(BaseButton, { variant: "smallSecondary", className: datepickerTheme.footer__clear, disabled: !notActive, onClick: onClear }, t('Clear')),
                React.createElement(BaseButton, { variant: "smallThird", className: datepickerTheme.footer__done, disabled: !isDoneable, onClick: isDoneable ? onDone : null }, outDate && !returnDate ? t('One way') : t('Done')))));
    }, [tab, props.isDoneable, props.isClearable, onDone, props.onClear]);
    const renderMobileFooter = useCallback(({ notActive, isDoneable, onClear, onDone }) => {
        return (React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH - 1 }, outDate && (React.createElement("div", { className: datepickerTheme.footer__buttons },
            React.createElement(BaseButton, { variant: "smallSecondary", className: datepickerTheme.footer__clear, disabled: !notActive, onClick: onClear }, t('Clear')),
            React.createElement(BaseButton, { variant: "smallThird", className: datepickerTheme.footer__done, disabled: !isDoneable, onClick: isDoneable ? onDone : null }, tab === TabTypes.PriceGraph && outDate && !returnDate ? t('One way') : t('Done'))))));
    }, [tab, props.isDoneable, props.isClearable, onDone, props.onClear]);
    const buttonRendererProps = {
        notActive: props.isClearable,
        onClear: props.onClear,
        onClose,
        isDoneable: props.isDoneable,
        onDone,
        tab,
        routeType: props.routeType,
        isDateBackSelected: props.isDateBackSelected,
        isDateToSelected: props.isDateToSelected,
        isMultyCity
    };
    const desktopMonthCount = isNarrowDesktop ? 2 : (_a = props.monthCount) !== null && _a !== void 0 ? _a : 3;
    return (React.createElement(ThemeProvider, { value: mergedTheme },
        React.createElement("div", { ref: ref, className: datepickerTheme.inputWrapper },
            React.createElement("input", { placeholder: props.inputPlaceholder, className: classnames(props.inputClassName, { [props.inputFocusedClassName]: isOpen }), onClick: open, autoComplete: "one-time-code", ref: refEl, value: props.inputValue, readOnly: true }),
            props.valueRenderer && props.valueRenderer(isOpen, open, onClose)),
        React.createElement(DialogAdapter, { onClose: onClose, isOpen: isOpen, outsideClickIgnoreClass: props.outsideClickIgnoreClass, popupClassName: datepickerTheme.dialog },
            React.createElement("div", { className: classnames(datepickerTheme.popup, {
                    [datepickerTheme.popup_withoutTabs]: !isTabDisplay
                }), "data-search-form-datepicker": true },
                props.showProgress && (React.createElement(LinearProgress, { variant: "indeterminate", classes: {
                        root: datepickerTheme.progress__root,
                        barColorPrimary: datepickerTheme.progress__bar__color,
                        colorPrimary: datepickerTheme.progress__color
                    } })),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                    React.createElement("div", { className: datepickerTheme.dialog__bar },
                        React.createElement("div", { className: datepickerTheme.dialog__dates, onClick: onClose },
                            React.createElement("span", { className: classnames({
                                    [datepickerTheme.dialog__activeDate]: props.routeType === 'outbound'
                                }), onClick: e => {
                                    e.stopPropagation();
                                    props.onClickOutboundDate(e);
                                } },
                                React.createElement("span", null, outDate
                                    ? removeDotsFromDate(format(outDate, 'd MMM, EEEEEE'))
                                    : t('Outbound'))),
                            outDate && (React.createElement("span", { className: classnames(datepickerTheme.dialog__dateBack, {
                                    [datepickerTheme.dialog__activeDate]: props.routeType === 'return'
                                }), onClick: e => {
                                    e.stopPropagation();
                                    props.onClickReturnDate(e);
                                } },
                                React.createElement("span", null, returnDate
                                    ? removeDotsFromDate(format(returnDate, 'd MMM, EEEEEE'))
                                    : t('Return'))))),
                        React.createElement("div", { onClick: onClose, className: datepickerTheme.dialog__close }))),
                React.createElement("div", { className: classnames(datepickerTheme.content), ref: contentRef },
                    tab === TabTypes.PlainCalendar && (React.createElement(React.Fragment, null,
                        React.createElement(MediaQuery, { minWidth: +TABLET_MIN_WIDTH + 1 },
                            React.createElement(Datepicker, { onSelect: onSelect, onHover: props.onHover, hoverDates: props.hoverDates, openDate: props.openDate, highlightedDates: props.highlightedDates, selectedDates: props.selectedDates, monthCount: desktopMonthCount, minDate: props.minDate, maxDate: props.maxDate, dayRenderer: props.useThemeWithPrices && dayWithPrice })),
                        React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH },
                            React.createElement(Datepicker, { getParentRef: () => contentRef, onSelect: onSelect, onHover: props.onHover, hoverDates: props.hoverDates, openDate: props.openDate, highlightedDates: props.highlightedDates, selectedDates: props.selectedDates, monthCount: 12, minDate: props.minDate, maxDate: props.maxDate, dayRenderer: props.useThemeWithPrices && dayWithPrice })))),
                    tab === TabTypes.PriceGraph &&
                        (showOverlay ? (React.createElement(EmptyDatesOverlay, { onClick: handleOverlayButtonClick })) : (React.createElement(ThemeProvider, { value: {
                                PriceGraph: Object.assign(Object.assign({}, priceGraphTheme), { LegendsStyles: legendStyles })
                            } },
                            React.createElement(React.Suspense, { fallback: React.createElement("p", null, "...") }, props.showPriceGraph && pricesLoaded() ? (React.createElement(PriceGraph, { outDate: outDate, backDate: returnDate, openDate: props.selectedDates && props.selectedDates.length
                                    ? props.selectedDates[0]
                                    : props.openDate, onPricesEnd: props.onPriceGraphPricesEnd, onSelect: onSelect, prices: props.routeType === 'return' ? props.pricesBack : props.pricesTo, routeType: props.routeType === 'return'
                                    ? RouteType.Return
                                    : RouteType.Outbound })) : (React.createElement(DummyPriceGraph, null)))))),
                    tab === TabTypes.RoundtripMatrix && (React.createElement(PriceMatrixProvider, { value: priceMatrixTheme },
                        React.createElement(React.Suspense, { fallback: React.createElement("p", null, "...") },
                            React.createElement(PriceMatrix, { prices: props.matrixPrices, size: 7, onDateSelect: props.onRTSelect })))),
                    props.useThemeWithPrices && (React.createElement("div", { className: datepickerTheme.datepicker__disclaimer }, t('Approximate prices for 1 person for 1 direction')))),
                React.createElement("div", { className: datepickerTheme.footer },
                    isTabDisplay && (React.createElement(Tabs, { value: tab, onChange: (ecent, newValue) => setTab(newValue), classes: {
                            root: datepickerTheme.tabs,
                            indicator: datepickerTheme.tab__indicator
                        } },
                        React.createElement(Tab, { disableRipple: true, classes: {
                                root: datepickerTheme.tab,
                                wrapper: datepickerTheme.tab__wrapper,
                                selected: datepickerTheme.tab_selected
                            }, label: t('Calendar'), icon: React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH }, calendarIcon) }),
                        props.showPriceGraph && (React.createElement(Tab, { disableRipple: true, classes: {
                                root: datepickerTheme.tab,
                                wrapper: datepickerTheme.tab__wrapper,
                                selected: datepickerTheme.tab_selected
                            }, label: t('Price graph'), icon: React.createElement(MediaQuery, { maxWidth: TABLET_MIN_WIDTH }, graphIcon) })),
                        props.showPriceMatrix && (React.createElement(Tab, { disableRipple: true, classes: {
                                root: classnames(datepickerTheme.tab, datepickerTheme.tab_matrix),
                                selected: datepickerTheme.tab_selected
                            }, label: t('Price matrix') })))),
                    renderAdaptiveSearchFormDatepickerControls
                        ? renderAdaptiveSearchFormDatepickerControls(buttonRendererProps)
                        : renderTabletFooter(buttonRendererProps)),
                renderAdaptiveSearchFormDatepickerControls
                    ? renderAdaptiveSearchFormDatepickerControls(buttonRendererProps)
                    : renderMobileFooter(buttonRendererProps)))));
}));
export default SearchFormDatepicker;
