import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import Categories from '../Categories/Categories';
import Warning from '../Warning/Warning';
import { useTheme } from '../../../theme';
import { ALL_DISHES_CATEGORY_ID, SELECTED_DISHES_CATEGORY_ID } from '../../constants';
import { getCategories, removeDuplicatesByName } from '../../utils';
import { MealViewMode } from '../../../Meal/types';
import { useConfig } from '../../../context';
import { WxEvents } from '../../../DataLayerAnalytics/types';
import WebskyAnalyticsApollo from '../../../DataLayerAnalytics/WebskyAnalyticsApollo';
import { isDemo } from '../../../utils';
import { useTranslation } from 'react-i18next';
import InfoMessages from '../../../InfoMessages';
import { InfoMessagePositionsEnum } from '@websky/graphql';
import { OverrideComponent } from '../../../renderProps';
const ServiceWrapper = ({ activeSegmentId, meals, selectedMeals = [], initialSelectedMeals = [], mealRenderer, recommendedMeals }) => {
    const { t } = useTranslation('MealService');
    const { ServiceWrapper: css } = useTheme('MealService');
    const [hasSelectedMeals, setHasSelectedMeals] = useState(false);
    const { Engine: { mealWarning, mealDefaultFilterButtonPlacement } } = useConfig();
    const categories = useMemo(() => getCategories(meals, activeSegmentId), [meals, activeSegmentId]);
    const [category, setCategory] = useState(mealDefaultFilterButtonPlacement === 'end' ? 0 : ALL_DISHES_CATEGORY_ID);
    const mealIsSelected = (meal) => {
        return initialSelectedMeals.find(selectedMeal => {
            return meal.id === selectedMeal.mealId && selectedMeal.segmentId === activeSegmentId;
        });
    };
    const mealIsConfirmed = (meal) => {
        return selectedMeals.find(selectedMeal => {
            return (meal.id === selectedMeal.mealId &&
                selectedMeal.segmentId === activeSegmentId &&
                selectedMeal.confirmedCount);
        });
    };
    const filteredMeals = useMemo(() => {
        const filtered = meals.filter(meal => {
            return (meal.segmentsId.includes(activeSegmentId) &&
                (category === ALL_DISHES_CATEGORY_ID ||
                    meal.category === categories[category] ||
                    (hasSelectedMeals && category === SELECTED_DISHES_CATEGORY_ID && mealIsSelected(meal))));
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [meals, activeSegmentId, category, selectedMeals]);
    const confirmedMeals = useMemo(() => {
        const filtered = meals.filter(meal => {
            return meal.segmentsId.includes(activeSegmentId) && mealIsConfirmed(meal);
        });
        return isDemo ? removeDuplicatesByName(filtered) : filtered;
    }, [meals, activeSegmentId, category, selectedMeals]);
    const numOfSelectedMeals = useMemo(() => {
        let result = 0;
        selectedMeals.forEach(meal => {
            if (meal.segmentId === activeSegmentId) {
                result += meal.count - meal.confirmedCount;
            }
        });
        return result;
    }, [selectedMeals, activeSegmentId]);
    useEffect(() => {
        setHasSelectedMeals(initialSelectedMeals.some(selectedMeal => selectedMeal.segmentId === activeSegmentId));
        // if user remove meal on selected dishes tab, return to first category
        if (category === SELECTED_DISHES_CATEGORY_ID && !numOfSelectedMeals) {
            setCategory(ALL_DISHES_CATEGORY_ID);
        }
    }, [selectedMeals, activeSegmentId, category, hasSelectedMeals]);
    useEffect(() => {
        WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.MealsList, { segmentId: activeSegmentId });
    }, [activeSegmentId]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Categories, { categories: categories, onSelect: setCategory, selectedCategory: category, numOfSelectedMeals: numOfSelectedMeals, defaultGroupPlacement: mealDefaultFilterButtonPlacement }),
        React.createElement(InfoMessages, { position: InfoMessagePositionsEnum.meal, className: css.infoMessage, itemHtmlClassName: css.infoMessage__htmlMessage }),
        mealWarning && (React.createElement(OverrideComponent, { componentProps: {
                warning: mealWarning
            }, component: { MealServiceWarning: Warning } })),
        React.createElement("div", { className: cn(css.meals, {
                [css.meals_singleMeal]: filteredMeals.length === 1
            }) }, filteredMeals.map(meal => mealRenderer(meal, activeSegmentId, filteredMeals.length === 1))),
        (confirmedMeals === null || confirmedMeals === void 0 ? void 0 : confirmedMeals.length) > 0 && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: cn(css.meals_purchased) }, t('Purchased')),
            React.createElement("div", { className: cn(css.meals, {
                    [css.meals_singleMeal]: confirmedMeals.length === 1
                }) }, confirmedMeals.map(meal => mealRenderer(meal, activeSegmentId, confirmedMeals.length === 1, MealViewMode.confirmed)))))));
};
export default ServiceWrapper;
