import * as React from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import BaggageCounter from '../../../../BaggageCounter';
import { serviceForMultipleSegments, usePassengerState } from '../../../hooks';
import Tooltip from '../../../../../Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedServicesByPassengersMap, getMaxBaggagePerPassengerCount, getService, getPassenger } from '../../../store/selector';
import { findMaxCountServicesInFlight, getBaggageCountInSegment, getMaxBaggageCount, getMinBaggageCount, updateSelectedItems } from '../../../utils';
import { useConfig } from '../../../../../context';
import { useAvailableCountForTravellerOnSegment } from '../../../../../AdditionalServices/hooks';
const PassengerBaggageCounter = ({ className, passengerId, baggages, onChange, disabled, segmentIds, segmentId }) => {
    var _a, _b, _c;
    const theme = useTheme('UpgradeBaggageDialog').BaggagePassenger;
    const { t } = useTranslation('IncludedBaggage');
    const { items, selectedItems, selectedCount } = usePassengerState(passengerId, baggages, segmentIds, segmentId);
    const services = useSelector(getService);
    const selectedServicesByPassengers = useSelector(getSelectedServicesByPassengersMap);
    const defaultMaxBaggagePerPassenger = useSelector(getMaxBaggagePerPassengerCount);
    const passenger = useSelector((state) => getPassenger(state, passengerId));
    const baggageLimitConfig = (_b = (_a = useConfig().global) === null || _a === void 0 ? void 0 : _a.baggage) === null || _b === void 0 ? void 0 : _b.baggageLimit;
    const selectedBaggageCountInSegmentForPassenger = React.useMemo(() => segmentId
        ? getBaggageCountInSegment(selectedServicesByPassengers.get(passengerId) || [], segmentId)
        : findMaxCountServicesInFlight(selectedServicesByPassengers.get(passengerId) || []), [selectedServicesByPassengers, segmentId]);
    const selectedPassengerBaggageByTypeCount = React.useMemo(() => {
        const selectedPassengerBag = selectedServicesByPassengers.get(passengerId);
        if (selectedPassengerBag) {
            let bagCount;
            if (!segmentId) {
                bagCount = findMaxCountServicesInFlight(selectedPassengerBag);
            }
            else {
                bagCount = selectedPassengerBag
                    .filter(i => i.segmentIds.includes(segmentId))
                    .reduce((count, bag) => (services.some(s => s.id === bag.serviceId) ? count + bag.count : count), 0);
            }
            return bagCount;
        }
        return 0;
    }, [passengerId, segmentId, selectedServicesByPassengers, services]);
    const availableForTravellerOnSegment = useAvailableCountForTravellerOnSegment((_c = baggages[0]) === null || _c === void 0 ? void 0 : _c.id, passengerId, segmentIds[0]);
    const maxCount = React.useMemo(() => {
        var _a;
        if (availableForTravellerOnSegment !== undefined) {
            return availableForTravellerOnSegment;
        }
        if ((_a = baggages[0]) === null || _a === void 0 ? void 0 : _a.maxServiceCountPerPassenger) {
            return baggages[0].maxServiceCountPerPassenger;
        }
        return getMaxBaggageCount(defaultMaxBaggagePerPassenger, baggageLimitConfig, baggages[0].type, selectedCount, selectedPassengerBaggageByTypeCount, selectedBaggageCountInSegmentForPassenger);
    }, [
        selectedBaggageCountInSegmentForPassenger,
        defaultMaxBaggagePerPassenger,
        selectedCount,
        selectedPassengerBaggageByTypeCount,
        baggages,
        availableForTravellerOnSegment
    ]);
    const minCount = React.useMemo(() => getMinBaggageCount(passenger, baggages, segmentId), [passenger]);
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    const onChangeHandler = (count) => {
        const newItems = updateSelectedItems(selectedCount, count, items, selectedItems);
        if (newItems) {
            onChange(newItems);
        }
        if (multipleSegments) {
            setTooltipOpen(true);
            setTimeout(() => setTooltipOpen && setTooltipOpen(false), 700);
        }
    };
    const multipleSegments = React.useMemo(() => serviceForMultipleSegments(baggages[0]), [baggages]);
    return (React.createElement("div", { className: cn(className, theme.counter_wrapper) },
        React.createElement(Tooltip, { title: t('Added for all flights'), disableHoverListener: !multipleSegments, disableTouchListener: !multipleSegments, open: tooltipOpen },
            React.createElement("div", null,
                React.createElement(BaggageCounter, { max: maxCount, min: minCount, value: selectedCount, onChange: onChangeHandler, disabled: disabled })))));
};
export default PassengerBaggageCounter;
