import i18n from 'i18next';
export const getLabel = (miles) => {
    const lastDigit = miles % 10, lastDigits = miles % 100;
    if (lastDigit === 1 && miles !== 11) {
        return i18n.t('PriceInMiles:miles_1');
    }
    if (lastDigit >= 2 && lastDigit <= 4 && (lastDigits < 10 || lastDigits >= 20)) {
        return i18n.t('PriceInMiles:miles_3');
    }
    return i18n.t('PriceInMiles:miles_2');
};
