import { __awaiter, __rest } from "tslib";
import * as React from 'react';
import { FORM_ERROR } from 'final-form';
import { useTranslation } from 'react-i18next';
import Login from './Login/Login';
import CreateAccount from './CreateAccount/CreateAccount';
import { useResponseErrorParser } from './hooks';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import { OverrideComponent } from '../../../../renderProps';
import { LoginType, useCreateFfpAccountMutation, useFfpDefaultAccountQuery, useGetAccountInfoQuery } from '@websky/graphql';
import { useTheme } from '../../../../theme';
import { ApolloError } from 'apollo-boost';
import ModalConfirm from '../../../../ModalConfirm';
import Link from '../../../../Link';
import { Hello } from '../../../../Icons';
export var ConnectType;
(function (ConnectType) {
    ConnectType["Login"] = "Login";
    ConnectType["Reset"] = "Reset";
    ConnectType["Create"] = "Create";
})(ConnectType || (ConnectType = {}));
const LoyaltyConnect = (_a) => {
    var _b, _c;
    var { type: connectType = ConnectType.Login, isOpen, onClose, onDataLoading } = _a, props = __rest(_a, ["type", "isOpen", "onClose", "onDataLoading"]);
    const { t } = useTranslation('Loyalty');
    const { LoyaltyConnect: css } = useTheme('Loyalty');
    const [type, typeChange] = React.useState(connectType);
    const newAccFormRef = React.useRef(null);
    React.useEffect(() => {
        typeChange(isOpen ? connectType : null);
    }, [connectType, isOpen]);
    const { parseResponse } = useResponseErrorParser();
    const [error, setError] = React.useState(null);
    const [createAccountSuccess, setCreateAccountSuccess] = React.useState(false);
    const [createAccountLoading, setCreateAccountLoading] = React.useState(false);
    const { data, loading: isDefaultDataLoading } = useFfpDefaultAccountQuery();
    const { data: accountInfo, loading: accountInfoLoading } = useGetAccountInfoQuery({
        fetchPolicy: 'no-cache',
        skip: type !== ConnectType.Create
    });
    React.useEffect(() => {
        onDataLoading === null || onDataLoading === void 0 ? void 0 : onDataLoading(accountInfoLoading);
    }, [accountInfoLoading]);
    const [createAccountMutation] = useCreateFfpAccountMutation();
    const onError = (message) => {
        const parseMessage = parseResponse(message);
        setError(parseMessage);
        return { [FORM_ERROR]: parseMessage };
    };
    const createAccountRequest = (newFfpAccountData) => __awaiter(void 0, void 0, void 0, function* () {
        var _d, _e, _f;
        setCreateAccountLoading(true);
        try {
            const { data } = yield createAccountMutation({
                variables: {
                    params: newFfpAccountData
                }
            });
            if (!((_d = data === null || data === void 0 ? void 0 : data.CreateFfpAccount) === null || _d === void 0 ? void 0 : _d.result)) {
                // TODO: HACK fix it
                if (data.CreateFfpAccount.message === 'Сообщение программы: invalid FFP LK system') {
                    setCreateAccountSuccess(data.CreateFfpAccount.result);
                    setTimeout(() => typeChange(ConnectType.Login));
                    return undefined;
                }
                return onError(data.CreateFfpAccount.message);
            }
            else {
                setTimeout(() => typeChange(ConnectType.Login));
                return undefined;
            }
        }
        catch (err) {
            let error = 'Unknown error';
            if (err instanceof Error && err.message) {
                error = err.message;
            }
            else if (err instanceof ApolloError && ((_f = (_e = err.graphQLErrors) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.message)) {
                if (err.graphQLErrors.some(gqlError => gqlError.message.includes('(0) Неверные исходные данные:') &&
                    gqlError.message.includes('уже используется!'))) {
                    error = t('The specified email is already in use, please log in to your personal account using the data you provided during registration or restore your card number/password');
                }
                else {
                    error = err.graphQLErrors[0].message;
                }
            }
            setCreateAccountSuccess(false);
            return onError(error);
        }
        finally {
            setCreateAccountLoading(false);
        }
    });
    const toCreateAccount = () => {
        if (props.showRegisterAction) {
            typeChange(ConnectType.Create);
        }
    };
    const toResetPassword = () => {
        typeChange(ConnectType.Reset);
    };
    const closeErrorHandler = () => setError(null);
    const hasConfirmedEmail = (_c = (_b = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.CurrentUser) === null || _b === void 0 ? void 0 : _b.authMethods) === null || _c === void 0 ? void 0 : _c.some(method => method.loginType === LoginType.Email && method.confirmed);
    const [isCreateAccountButtonDisabled, setIsCreateAccountButtonDisabled] = React.useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(ModalConfirm, { classes: { root: css.warning__modal }, title: t('Oops...'), size: "medium", isOpen: isOpen &&
                !accountInfoLoading &&
                !isDefaultDataLoading &&
                !hasConfirmedEmail &&
                type === ConnectType.Create, onClose: onClose, onCancel: onClose, confirmBtn: t('Fill email'), onConfirm: props.onGoToSettings }, t('To continue registering in the loyalty system, fill out and confirm your email in your personal account.')),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Create && hasConfirmedEmail, isLoading: createAccountLoading, title: React.createElement("div", { className: css.createTitle },
                React.createElement("div", null, Hello),
                React.createElement("div", null, t('Create account'))), confirmBtn: t('Create'), disableConfirmBtn: isCreateAccountButtonDisabled, buttonsExtra: React.createElement(React.Fragment, null,
                React.createElement(Link, { action: () => typeChange(ConnectType.Login) }, t('I have a card'))), onConfirm: () => {
                var _a;
                (_a = newAccFormRef.current) === null || _a === void 0 ? void 0 : _a.submit();
            }, scroll: "body" },
            React.createElement(OverrideComponent, { component: {
                    CreateFfpAccountForm: CreateAccount
                }, componentProps: {
                    initialValues: data,
                    onCreateAccount: createAccountRequest,
                    success: createAccountSuccess,
                    toLogin: () => typeChange(ConnectType.Login),
                    error,
                    closeError: closeErrorHandler,
                    formRef: newAccFormRef,
                    isOpen: isOpen && type === ConnectType.Create && hasConfirmedEmail,
                    onErrorsChanges: hasErrors => {
                        setIsCreateAccountButtonDisabled(hasErrors);
                    }
                } })),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Login, hideFooter: true, hideTitle: true },
            React.createElement(Login, { onLoyaltyCardSaved: props.onLoyaltyCardSaved, toCreateAccount: toCreateAccount, toResetPassword: toResetPassword, showRegisterAction: props.showRegisterAction, queryType: props.authType })),
        React.createElement(ModalConfirm, { size: "medium", onClose: onClose, isOpen: isOpen && type === ConnectType.Reset, hideFooter: true, hideTitle: true },
            React.createElement(ForgotPassword, { toLogin: () => typeChange(ConnectType.Login), email: props.userInfo.email }))));
};
export default LoyaltyConnect;
