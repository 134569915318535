import { __awaiter } from "tslib";
import * as React from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Button, WarningRounded } from '../../../../index';
import { LoginType } from '@websky/graphql';
import { ModalTypesEnum } from '../AuthMethod/AuthMethod';
import { Statuses } from '../../../types';
import { Field, Form } from 'react-final-form';
import { useCustomerContactsValidation } from '../../../../CustomerContactsForm/hooks';
import { initI18n, useIsMobile } from '../../../../utils';
import Modal from '../../../../Modal';
import PhoneInput from '../../../../PhoneInput';
import Input from '../../../../Input';
import Timer from '../../../../Timer';
initI18n('Account');
const AuthDialog = props => {
    const css = useTheme('Account').ConfirmDialog, { t } = useTranslation('Account');
    const [activeDescription, setActiveDescription] = React.useState('');
    const { error, verificationError } = props.errors;
    const { testEmail, testPhone, testFFP } = useCustomerContactsValidation();
    const isMobile = useIsMobile();
    const onAvailableSubmit = props.status >= Statuses.Verification ? props.onSubmit : props.onContinue;
    const loadingStatus = props.status === Statuses.VerificationLoading || props.status === Statuses.Loading;
    const [loading, setLoading] = React.useState(false);
    const isLoading = loadingStatus || loading;
    const onDelete = React.useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        yield props.onDelete();
        setLoading(false);
    }), [props.onDelete]);
    const onValidate = React.useMemo(() => {
        return ((props.fieldType === LoginType.Phone && testPhone) ||
            (props.fieldType === LoginType.Email && testEmail) ||
            (props.fieldType === LoginType.FFP && testFFP));
    }, [props.fieldType]);
    const textFieldPropsFactory = (renderProps) => {
        return {
            label: props.fieldType !== LoginType.FFP ? t(props.fieldType) : t('Loyalty card number'),
            error: (renderProps.meta.touched && renderProps.meta.error) || error,
            helperText: (renderProps.meta.touched && renderProps.meta.error) || t(error === null || error === void 0 ? void 0 : error.message),
            onChange: (event) => {
                renderProps.input.onChange(event);
                props.setError(null);
                props.onChange(event.target.value);
            },
            onFocus: (event) => {
                renderProps.input.onFocus(event);
                if (props.type === ModalTypesEnum.Change && renderProps.input.value === props.fieldValue) {
                    event.target.select();
                }
            },
            onBlur: (event) => {
                var _a;
                if (((_a = event.relatedTarget) === null || _a === void 0 ? void 0 : _a.tagName) !== 'BUTTON') {
                    renderProps.input.onBlur(event);
                    if (!!renderProps.input.value.length) {
                        onValidate(renderProps.input.value);
                    }
                }
            }
        };
    };
    React.useEffect(() => {
        if (props.type === ModalTypesEnum.Remove) {
            switch (props.fieldType) {
                case LoginType.Email:
                    setActiveDescription(t(`Email will be removed from your login methods`, { email: props.fieldValue }));
                    break;
                case LoginType.Phone:
                    setActiveDescription(t(`Phone will be removed from your login methods`, { phone: props.fieldValue }));
                    break;
                case LoginType.FFP:
                    setActiveDescription(t(`FFP will be removed from your login methods`, { ffp: props.fieldValue }));
                    break;
                default:
                    setActiveDescription(t('method will be removed from your login methods', { method: props.fieldType }));
            }
        }
        else {
            setActiveDescription(t(`A confirmation code will be sent to your ${props.fieldType === LoginType.Phone ? 'phone' : 'address'}`));
        }
    }, [props.fieldType, props.type]);
    return (React.createElement(Modal, { maxWidth: "xs", bottomDrawer: isMobile, classes: { paper: css.paper }, open: props.isOpen, onClose: props.onClose, closeClassName: css.closeIcon },
        props.type === ModalTypesEnum.Remove && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title },
                React.createElement("span", { className: css.title__icon }, WarningRounded),
                t('Login method removal')),
            React.createElement("div", { className: css.description }, activeDescription),
            React.createElement("div", { className: css.buttons },
                React.createElement(Button, { className: css.button_normal, variant: "flat", onClick: onDelete, isLoading: isLoading }, t('Remove')),
                !isLoading && (React.createElement(Button, { className: css.button_accent, variant: "flat", onClick: props.onClose }, t('Cancel')))))),
        (props.type === ModalTypesEnum.Connect || props.type === ModalTypesEnum.Change) && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: css.title }, props.type === ModalTypesEnum.Connect
                ? props.status >= Statuses.Verification &&
                    props.isAutoConnect &&
                    props.fieldType === LoginType.Email
                    ? t('Add an email for quick access to your personal account')
                    : t('Add a new login method')
                : t('Change login method')),
            props.type === ModalTypesEnum.Connect &&
                props.status >= Statuses.Verification &&
                props.isAutoConnect &&
                props.fieldType === LoginType.Email && (React.createElement("div", { className: css.info }, t('You can log in to your personal account using your email'))),
            React.createElement(Form, { onSubmit: onAvailableSubmit, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                    props.status >= Statuses.Verification ? (React.createElement(Field, { name: "confirmationCode", type: "text", render: ({ input }) => (React.createElement("div", { className: css.input_wrapper },
                            React.createElement(Input, Object.assign({}, input, { TextFieldProps: {
                                    label: props.fieldType !== LoginType.FFP
                                        ? t('Confirmation code')
                                        : t('Password'),
                                    helperText: verificationError &&
                                        t(verificationError.message.replace('Network error: ', '')),
                                    error: props.status === Statuses.VerificationError ||
                                        !!verificationError,
                                    onChange: (event) => {
                                        input.onChange(event);
                                        props.setVerificationError(null);
                                        props.setStatus(Statuses.Verification);
                                    }
                                } })),
                            props.fieldType !== LoginType.FFP && (React.createElement(Timer, { classes: {
                                    timer: css.resend_timer,
                                    link: css.resend_timer__link
                                }, resendCode: props.resendCode })))) })) : (React.createElement(Field, { name: "authField", initialValue: props.fieldValue, validate: onValidate, render: ({ input, meta }) => (React.createElement("div", { className: css.input_wrapper },
                            props.fieldType === LoginType.Phone && (React.createElement(PhoneInput, Object.assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, textFieldsProps: Object.assign(Object.assign({}, textFieldPropsFactory({ input, meta })), { onChange: (event) => {
                                        input.onChange(event);
                                        props.setError(null);
                                        if (typeof event === 'string') {
                                            props.onChange(event);
                                        }
                                    } }) }))),
                            props.fieldType === LoginType.Email && (React.createElement(Input, Object.assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, TextFieldProps: Object.assign({}, textFieldPropsFactory({ input, meta })) }))),
                            props.fieldType === LoginType.FFP && (React.createElement(Input, Object.assign({}, input, { autoFocus: props.type === ModalTypesEnum.Change, TextFieldProps: Object.assign({}, textFieldPropsFactory({ input, meta })) }))),
                            props.fieldType !== LoginType.FFP && (React.createElement("div", { className: css.description }, activeDescription)))) })),
                    React.createElement("div", { className: css.buttons },
                        React.createElement(Button, { className: css.button_normal, variant: "flat", onClick: props.onClose }, t('Cancel')),
                        React.createElement(Button, { className: cn(css.button_accent, {
                                [css.button_disabled]: !props.isSubmitAvailable,
                                [css.button_loader]: isLoading
                            }), variant: "flat", type: "submit", disabled: !props.isSubmitAvailable, isLoading: isLoading }, props.status >= Statuses.Verification ? t('Add') : t('Continue'))))) })))));
};
export default AuthDialog;
