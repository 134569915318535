import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MediaQuery from 'react-responsive';
import { ApolloError } from 'apollo-boost';
import { CheckinOrderStatus, TravellerFieldEnum, useCheckinCompleteMutation, useGetCheckinOrderQuery } from '@websky/graphql';
import Passengers from './Passengers/Passengers';
import Stepbar from '../../../../../Stepbar';
import { getCheckinHasCompleted, getCheckinIsBlockedForPayment, getCheckinOrder, getIsCheckinCompletedOrPreseating, getIsContactsInfoEmpty, getIsSeatMapUnavailable, hasBaggageServices, hasMealServices, hasSeatMap, hasUpgradeServices, isExpired } from '../../../store/order/selectors';
import { useCheckinAvailabilityInfo, useSteps } from '../../../utils';
import { CheckinStep } from '../../../types';
import Meal from './routes/Meal';
import Baggage from './routes/Baggage';
import Payment from './routes/Payment';
import Extras from './routes/Extras';
import PaymentResult from '../../../../../PaymentResult';
import PaymentTimeLimitPage from '../../../../../PaymentTimeLimitPage';
import { setCheckinOrder } from '../../../store/order/actions';
import { useTheme } from '../../../../../theme';
import { CheckinGoal, reachGoal } from '../../../../../analytics';
import ProtectedRoute from '../../../../../ProtectedRoute/ProtectedRoute';
import { getUserValue, seatNumberRegex, TABLET_MIDDLE_WIDTH } from '../../../../../utils';
import CustomerContactsModal from '../../../../../CustomerContactsForm/Modal/CustomerContactsModal';
import { useCustomerContactsForm } from '../../../../../CustomerContactsForm/hooks';
import { getIsServicesSaving } from '../../../store/servicesLoading/selectors';
import SimpleLoader from '../../../../../SimpleLoader';
import PreselectedSeats from './PreselectedSeats/PreselectedSeats';
import WarningModal from '../../../../../WarningModal';
import { useUnlockCheckinOrder } from './hooks';
import { useToggleable } from '../../../../../hooks';
import MobileStepbar from '../../../../../MobileStepbar';
import { useCheckinOrderServices } from '../../../hooks';
import { useStepsToStepbarStepsAdapter } from './utils';
import { getFunnelParameters } from '../../../../../DataLayerAnalytics/utils';
import { Upgrade } from './routes/Upgrade';
import { CheckinUnavailableSeatMapError } from '../CheckinErrorBoundary/CheckinErrors';
var PaymentResultStatus;
(function (PaymentResultStatus) {
    PaymentResultStatus["Success"] = "success";
    PaymentResultStatus["Fail"] = "fail";
})(PaymentResultStatus || (PaymentResultStatus = {}));
const Order = () => {
    const { t } = useTranslation('Checkin');
    const { Order: css } = useTheme('Checkin');
    const isCheckinCompleted = useSelector(getCheckinHasCompleted);
    const isBlockedForPayment = useSelector(getCheckinIsBlockedForPayment);
    const hasBaggage = useSelector(hasBaggageServices);
    const hasMeals = useSelector(hasMealServices);
    const hasSeats = useSelector(hasSeatMap);
    const hasUpgrade = useSelector(hasUpgradeServices);
    const isCheckinExpired = useSelector(isExpired);
    const order = useSelector(getCheckinOrder);
    const isSeatMapUnavailable = useSelector(getIsSeatMapUnavailable);
    const isContactsInfoEmpty = useSelector(getIsContactsInfoEmpty);
    const isServicesSaving = useSelector(getIsServicesSaving);
    const isCheckinCompletedOrPreseating = useSelector(getIsCheckinCompletedOrPreseating);
    const history = useHistory();
    const [paymentResult, setPaymentResult] = React.useState(null);
    const [orderRefetching, setOrderRefetching] = React.useState(false);
    const [requestError, setRequestError] = useState();
    const { isOpen: isLoading, setOpen: setIsLoading } = useToggleable(false);
    const { saveClientInfo, closeContactsModal, openContactsModal, isContactsModalOpen, loading: isClientInfoSaving } = useCustomerContactsForm();
    const { goToNextStep, setStep, getNextStep, availableSteps, previousStep, getNextStepEnum } = useSteps();
    const { location: { pathname } } = history;
    const handleReturn = useCallback(() => {
        history.push('/');
    }, []);
    const { showExpireModal } = useCheckinAvailabilityInfo(600000);
    const { refetch: getOrder } = useGetCheckinOrderQuery({ skip: true, fetchPolicy: 'no-cache' });
    const [completeCheckin] = useCheckinCompleteMutation();
    const isSeatsStep = pathname.includes(CheckinStep.Seats);
    const isPaymentStep = pathname.includes(CheckinStep.Payment);
    const saveClientInfoAndLoadOrder = (data) => __awaiter(void 0, void 0, void 0, function* () {
        yield saveClientInfo({ variables: Object.assign(Object.assign({}, data), { orderId: order.id }) });
        const { data: getOrderResult } = yield getOrder({ id: order.id });
        if (getOrderResult === null || getOrderResult === void 0 ? void 0 : getOrderResult.CheckinOrder) {
            setOrder(getOrderResult.CheckinOrder);
        }
        closeContactsModal();
        if (isPaymentStep) {
            previousStep(CheckinStep.Payment);
        }
        else {
            const currentStep = isSeatsStep ? CheckinStep.Seats : CheckinStep.Extras;
            goToNextStep(currentStep);
        }
    });
    const dispatch = useDispatch();
    const setOrder = useCallback((order) => {
        dispatch(setCheckinOrder(order));
    }, [dispatch]);
    const onCloseWarningModal = () => {
        setRequestError(null);
    };
    const onRequestError = (errors) => {
        var _a, _b, _c, _d;
        const error = ((_b = (_a = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.debugMessage) || ((_d = (_c = errors === null || errors === void 0 ? void 0 : errors.graphQLErrors) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.message) || 'Checkin error';
        const invalidSeatError = 'Invalid seat number';
        if (error.includes(invalidSeatError)) {
            setRequestError(t(invalidSeatError, { seatNumber: error.match(seatNumberRegex)[0] }));
        }
        else {
            setRequestError(error);
        }
    };
    const steps = useStepsToStepbarStepsAdapter(availableSteps);
    const getStepByPathname = () => Object.keys(steps).findIndex(stepKey => {
        return stepKey && pathname.includes(stepKey);
    });
    const [activeStep, setActiveStep] = useState(getStepByPathname());
    useEffect(() => {
        const filteredSteps = {};
        if (!isCheckinCompleted && isBlockedForPayment && !isPaymentStep) {
            setStep(CheckinStep.Payment);
        }
        Object.keys(steps).forEach(stepKey => {
            if (steps.hasOwnProperty(stepKey) && !steps[stepKey].isHidden) {
                filteredSteps[stepKey] = steps[stepKey];
            }
        });
        if (!isCheckinCompleted && /^\/(\d+)\/?$/.test(pathname)) {
            setActiveStep(0); // passengers
        }
        else if (isCheckinCompleted) {
            setActiveStep(Object.keys(filteredSteps).length - 1); // boarding pass
        }
        else {
            setActiveStep(getStepByPathname());
        }
    }, [pathname, steps]);
    const onRefetchOrder = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        try {
            const { data } = yield getOrder({ id: order.id });
            if (data.CheckinOrder) {
                if (data.CheckinOrder.status !== CheckinOrderStatus.Started) {
                    throw new Error('Wrong order status!');
                }
                setOrder(data.CheckinOrder);
            }
            return data.CheckinOrder;
        }
        catch (e) {
            if (e instanceof ApolloError) {
                if ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                    onRequestError(e);
                }
            }
            else {
                throw e;
            }
            return null;
        }
    }), [order.id, getOrder]);
    const { onDeleteItem, isLoading: cartLoading } = useCheckinOrderServices(onRefetchOrder, onRequestError, setStep);
    const onProceed = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const currentStep = isSeatsStep ? CheckinStep.Seats : CheckinStep.Extras;
        const nextStep = getNextStepEnum(currentStep);
        const checkinOrder = yield onRefetchOrder();
        const paymentIsRequired = checkinOrder === null || checkinOrder === void 0 ? void 0 : checkinOrder.paymentIsRequired;
        const isContactsInfoEmpty = checkinOrder === null || checkinOrder === void 0 ? void 0 : checkinOrder.customer.contactInfoIsEmpty;
        if (nextStep === CheckinStep.Payment) {
            if (paymentIsRequired) {
                if (isContactsInfoEmpty) {
                    openContactsModal();
                }
                else {
                    setStep(CheckinStep.Payment);
                }
            }
            else {
                try {
                    const { data, errors } = yield completeCheckin({
                        variables: {
                            id: order.id
                        }
                    });
                    if (errors) {
                        console.error(errors);
                        onRequestError(errors);
                    }
                    else if (data === null || data === void 0 ? void 0 : data.CheckinComplete) {
                        setOrder(data.CheckinComplete);
                        setStep(CheckinStep.Passengers);
                    }
                }
                catch (e) {
                    onRequestError(e);
                    console.error(e);
                }
            }
        }
        else {
            goToNextStep(currentStep);
        }
    }), [order.id, pathname, isContactsInfoEmpty]);
    const onCheckinStart = useCallback(() => {
        goToNextStep(CheckinStep.Passengers);
    }, []);
    const customerValues = React.useMemo(() => {
        const customer = order.customer;
        return {
            email: getUserValue(customer, TravellerFieldEnum.Email),
            phone: getUserValue(customer, TravellerFieldEnum.Phone)
        };
    }, [order.customer]);
    const refetchOrderAfterPayment = () => {
        const initialInterval = 500;
        const step = 1.5;
        setOrderRefetching(true);
        const polling = (interval) => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            try {
                const newOrder = yield getOrder({
                    id: order.id
                });
                if ([CheckinOrderStatus.Confirmed, CheckinOrderStatus.Expired].includes((_b = (_a = newOrder.data) === null || _a === void 0 ? void 0 : _a.CheckinOrder) === null || _b === void 0 ? void 0 : _b.status)) {
                    setOrder(newOrder.data.CheckinOrder);
                    setOrderRefetching(false);
                }
                else {
                    setTimeout(() => polling(interval * step), interval);
                }
            }
            catch (e) {
                setOrderRefetching(false);
                console.warn(e);
            }
        });
        setTimeout(() => polling(initialInterval), initialInterval);
    };
    useEffect(() => {
        if (isSeatsStep && isSeatMapUnavailable) {
            throw new CheckinUnavailableSeatMapError(t('Service is temporarily unavailable. There are no seats available. Please try again later, or check in at the airport.'));
        }
    }, [isSeatsStep, isSeatMapUnavailable]);
    useEffect(() => {
        if (paymentResult === PaymentResultStatus.Success) {
            reachGoal(CheckinGoal.Paid);
            reachGoal(CheckinGoal.CompleteWithPayment, { funnel_checkin: getFunnelParameters(order) });
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
            else {
                refetchOrderAfterPayment();
            }
        }
        else if (paymentResult === PaymentResultStatus.Fail) {
            reachGoal(CheckinGoal.PaymentCancelled);
            if (isCheckinCompleted) {
                goToNextStep(CheckinStep.Passengers, true);
            }
        }
    }, [paymentResult]);
    const unlockOrder = useUnlockCheckinOrder(setIsLoading);
    return (React.createElement("div", { className: css.container },
        (isServicesSaving || orderRefetching || isLoading || cartLoading) && React.createElement(SimpleLoader, null),
        !showExpireModal && !isCheckinExpired && (React.createElement(React.Fragment, null,
            !isCheckinCompleted && (React.createElement(React.Fragment, null,
                React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH },
                    React.createElement(Stepbar, { steps: steps, activeStep: activeStep, className: css.stepBar })),
                React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
                    React.createElement(MobileStepbar, { order: order, currentStep: activeStep, stepbarConfig: steps, onServiceDelete: onDeleteItem })))),
            React.createElement(CustomerContactsModal, { initialValues: customerValues, onContactsSubmit: saveClientInfoAndLoadOrder, isLoading: isClientInfoSaving, open: isContactsModalOpen }),
            React.createElement(Switch, null,
                React.createElement(Route, { path: "/:id(\\d+)", exact: true, render: () => (React.createElement(Passengers, { onCheckinStart: onCheckinStart, onRefetchOrder: onRefetchOrder })) }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/upgrade", isAccessible: !isCheckinCompletedOrPreseating && hasUpgrade, redirectTo: getNextStep(CheckinStep.Upgrade), render: () => React.createElement(Upgrade, null) }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/baggage", isAccessible: !isCheckinCompletedOrPreseating && hasBaggage, redirectTo: isCheckinCompletedOrPreseating
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasBaggage
                            ? getNextStep(CheckinStep.Baggage)
                            : '', render: () => React.createElement(Baggage, null) }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/meal", isAccessible: !isCheckinCompletedOrPreseating && hasMeals, redirectTo: isCheckinCompletedOrPreseating
                        ? getNextStep(CheckinStep.Passengers)
                        : !hasMeals
                            ? getNextStep(CheckinStep.Meal)
                            : '', render: () => React.createElement(Meal, { isLoading: isServicesSaving }) }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/seats", isAccessible: !isCheckinCompletedOrPreseating && hasSeats, redirectTo: getNextStep(CheckinStep.Passengers), render: () => React.createElement(PreselectedSeats, { onProceed: onProceed }) }),
                React.createElement(ProtectedRoute, { path: "/:id(\\d+)/extras", isAccessible: !isCheckinCompletedOrPreseating, redirectTo: getNextStep(CheckinStep.Passengers), render: () => React.createElement(Extras, { onProceed: onProceed, onSetOrder: setOrder }) }),
                React.createElement(Route, { exact: true, path: "/:id(\\d+)/payment", render: () => {
                        if (isCheckinCompleted) {
                            setStep(CheckinStep.Passengers);
                            return null;
                        }
                        if (isContactsInfoEmpty) {
                            openContactsModal();
                            return null;
                        }
                        setPaymentResult(null);
                        return React.createElement(Payment, null);
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/successfull", render: () => {
                        setPaymentResult(PaymentResultStatus.Success);
                        return (React.createElement("div", { className: css.paymentResult },
                            React.createElement(PaymentResult, { result: "success", onClick: () => {
                                    setStep(CheckinStep.Passengers);
                                } })));
                    } }),
                React.createElement(Route, { path: "/:id(\\d+)/payment/failed", render: () => {
                        setPaymentResult(PaymentResultStatus.Fail);
                        return (React.createElement("div", { className: css.paymentResult },
                            React.createElement(PaymentResult, { result: "fail", onBackToOrder: unlockOrder, onClick: () => setStep(CheckinStep.Payment) })));
                    } })))),
        (showExpireModal || isCheckinExpired) && (React.createElement("div", { className: css.timelimit__wrp },
            React.createElement(PaymentTimeLimitPage, { title: t('Sorry,'), text: t('online check-in is closed for this flight'), buttonText: t('OK'), onClick: handleReturn }))),
        React.createElement(WarningModal, { title: t('Oops, something went wrong'), content: t('An error occurred during the check-in process. Please try again later or contact a customer support service.'), isOpen: !!requestError, errorMessage: requestError, buttonText: t('Close'), onButtonClick: onCloseWarningModal })));
};
export default Order;
