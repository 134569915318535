import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Money } from '../../Money';
import { initI18n, MOBILE_MIN_WIDTH } from '../../utils';
import { useTheme } from '../../theme';
import Button from '../../Button/Button';
import Header from './Header/Header';
import Link from '../../Link';
import { useConfig } from '../../context';
import { useMediaQuery } from 'react-responsive';
initI18n('AdditionalServiceCard');
const AdditionalServiceCard = memo(({ service, onClick, buttonText, isLoading, horizontal = false }) => {
    var _a;
    const theme = useTheme('AdditionalServiceCard').AdditionalServiceStyles;
    const { t } = useTranslation('AdditionalServiceCard');
    const { global: { companyInfo } } = useConfig();
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const ownContent = useMemo(() => (React.createElement(React.Fragment, null,
        horizontal && !isMobile ? (React.createElement("div", { className: theme.title }, t(service.header))) : (React.createElement("div", { className: theme.subheader }, t(service.subheader))),
        service.content,
        service.header === 'Insurance' && (!horizontal || isMobile) && (React.createElement(Link, { action: `${companyInfo.insurance}`, target: "_blank" }, t('Terms of service'))))), [horizontal, isMobile, service.content, service.header]);
    return (React.createElement("div", { className: cn(theme.additional, {
            [theme.additional_horizontal]: horizontal && !isMobile
        }) },
        React.createElement(Header, { header: service.header, renderHeader: service.renderHeader, logo: service.logo, headClassName: service.headClassName, icon: service.icon, horizontal: horizontal && !isMobile }),
        React.createElement("div", { className: theme.content }, service.renderContent ? service.renderContent() : ownContent),
        React.createElement("div", { className: theme.footer },
            service.renderPrice ? (React.createElement("span", { className: theme.price }, service.renderPrice({ price: service.price, active: service.active }))) : (((_a = service.price) === null || _a === void 0 ? void 0 : _a.amount) > 0 && (React.createElement(Money, { moneyClassName: cn(theme.price, {
                    [theme.price_notSelected]: !service.active
                }), money: service.price }))),
            React.createElement(Button, { variant: "common", className: cn(theme.buttonBase, {
                    [theme.remove]: service.active
                }), isLoading: isLoading, onClick: onClick }, buttonText ? t(buttonText) : service.active ? t('Remove') : t('Add')))));
});
export default AdditionalServiceCard;
