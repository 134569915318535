import { z } from 'zod';
import { BooleanOptional, NumberOptional, StringOptional } from './schemaTypes';
export const PassengerConfigSegmentSchema = z.object({
    arrival: z.union([z.literal('*'), z.string()]),
    departure: z.union([z.literal('*'), z.string()])
});
export const PassengerTypeConfigSchema = z.object({
    code: z.string(),
    parentCode: StringOptional,
    isAdult: BooleanOptional,
    noAvailableWith: z.array(z.string()).optional(),
    defaultCount: NumberOptional,
    onlyRoutes: z.array(z.array(PassengerConfigSegmentSchema)).optional(),
    disabledRoutes: z.array(z.array(PassengerConfigSegmentSchema)).optional(),
    disabled: BooleanOptional,
    maxCountWithoutAdult: NumberOptional,
    blockPayByMiles: BooleanOptional,
    maxCount: NumberOptional,
    isIndependent: BooleanOptional,
    requiredWithOneOf: z.array(z.string()).optional(),
    modifyCount: NumberOptional
});
const SelectableCurrencySchema = z.object({
    code: z.string(),
    symbol: z.string()
});
export const SearchFormSchema = z.object({
    redwingsForeignCardPaymentCheckbox: BooleanOptional,
    enableComplexRoute: BooleanOptional,
    mobileFormType: z.enum(['common', 'alternative']).optional(),
    engineURL: StringOptional,
    payByMiles: BooleanOptional,
    requestCoordintates: z
        .object({
        desktop: BooleanOptional,
        mobile: BooleanOptional
    })
        .optional(),
    maxPassengersCount: NumberOptional,
    passengersConfig: z.array(PassengerTypeConfigSchema).optional(),
    offerHotels: BooleanOptional,
    offerHotelsLink: StringOptional,
    offerHotelsMainLink: StringOptional,
    offerHotelsSupplier: z.enum(['OSTROVOK', 'ONETWOTRIP']).optional(),
    offerHotelsAdditionalId: StringOptional,
    offerHotelsUtm: StringOptional,
    resultsURL: StringOptional,
    showPriceGraph: BooleanOptional,
    showPricesInDatepicker: BooleanOptional,
    proMode: BooleanOptional,
    selectableCurrencies: z.array(SelectableCurrencySchema).optional()
});
