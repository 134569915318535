import * as React from 'react';
import { memo } from 'react';
import MediaQuery from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../utils';
import Toolbar from '../../Toolbar';
import MobileToolbar from '../../MobileToolbar';
const ServicesToolbar = memo(({ toolbarProps, mobileToolbarProps, children }) => {
    return (React.createElement(React.Fragment, null,
        children,
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(Toolbar, Object.assign({}, toolbarProps))),
        mobileToolbarProps && (React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement(MobileToolbar, Object.assign({}, mobileToolbarProps))))));
});
export default ServicesToolbar;
