import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo } from 'react';
import cn from 'classnames';
import FareGroup from '../FareGroup';
import { useTheme } from '../../../theme';
import { icons, payment } from '../../icons';
import { useOptionIconModifier } from './hooks';
import { LuggageExcessIcon } from './icons';
import Tooltip from '../../../Tooltip';
import { FareFeaturePaymentType } from '@websky/graphql';
import { getUniqueValidOptions } from '../../../Results/utils';
import { OverrideComponent, useRenderers } from '../../../renderProps';
import Button from '../../../Button';
import { ArrowForward, Exchangeable, Refundable } from '../../../Icons';
import { useConfig } from '../../../context';
import { FareFamilyOptionsType } from '@websky/graphql';
const FareGroupGrid = props => {
    var _a, _b;
    const { FareGroupGrid: css } = useTheme('FareGroup');
    const { fare, upgradeOption, orderId } = props;
    const { t } = useTranslation('FareGroup');
    const iataCode = useConfig().global.companyInfo.iataCode;
    const isF7 = iataCode === 'F7';
    const isY7 = iataCode === 'Y7';
    const options = getUniqueValidOptions(fare.options, false, true, (_a = props.showUnavailableOptions) !== null && _a !== void 0 ? _a : false).filter(option => option.isKeyOption);
    const notKeyOptions = getUniqueValidOptions(fare.options, false, false, (_b = props.showUnavailableOptions) !== null && _b !== void 0 ? _b : false).filter(option => !option.isKeyOption);
    const smallAfterFirst = isF7 || isY7 || notKeyOptions.length > 4;
    const smallAfterFirst4 = options.length > 6 && options.length <= 7;
    const smallOptions = options.length > 7;
    const { renderFareGroupOptionValue, renderFareGroupOptionIcon } = useRenderers();
    const renderOptionAvailabilityIcon = useCallback((availability) => {
        if (renderFareGroupOptionValue) {
            return renderFareGroupOptionValue(availability);
        }
        return payment[availability];
    }, [payment, renderFareGroupOptionValue]);
    const renderOptionIcon = useCallback((type, isSpecialLuggageOverweightOption) => {
        if (renderFareGroupOptionIcon) {
            return renderFareGroupOptionIcon(type, isSpecialLuggageOverweightOption);
        }
        return isSpecialLuggageOverweightOption ? LuggageExcessIcon : icons[type];
    }, [icons, renderFareGroupOptionIcon]);
    const renderOption = useCallback((option, index) => {
        var _a, _b, _c;
        const { isSpecialLuggageOverweightOption } = useOptionIconModifier(option);
        const currentIndex = index + 1;
        let isSmall = false;
        const [isTooltipOpened, setTooltipOpened] = React.useState(false);
        const setTooltipState = (state) => {
            var _a;
            setTooltipOpened(state);
            (_a = props.setTooltipsOpened) === null || _a === void 0 ? void 0 : _a.call(props, state);
        };
        if (smallAfterFirst4 && currentIndex > 4) {
            isSmall = true;
        }
        if (smallAfterFirst) {
            isSmall = true;
        }
        if (smallOptions && currentIndex > 2) {
            isSmall = true;
        }
        return (React.createElement(Tooltip, { title: option.description ? option.description : option.title, key: option.type, enterTouchDelay: 0, open: isTooltipOpened && props.isTooltipsOpened, onClose: () => setTooltipOpened(false), onOpen: () => setTooltipState(true) },
            React.createElement("div", { className: cn(css.option, {
                    [css.paid]: option.availability === FareFeaturePaymentType.Pay,
                    [css.not_available]: option.availability === FareFeaturePaymentType.NotAvailable,
                    [css.small]: isSmall
                }) }, isY7 ? (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.icon }, renderOptionIcon(option.type, isSpecialLuggageOverweightOption)),
                React.createElement("span", { className: css.availabilityIcon }, (_a = (isSmall && option.value)) !== null && _a !== void 0 ? _a : renderOptionAvailabilityIcon(option.availability)),
                !isSmall && (React.createElement(React.Fragment, null, (_b = option.value) !== null && _b !== void 0 ? _b : option.title,
                    React.createElement("div", { className: css.size }, option.size))))) : (React.createElement(React.Fragment, null,
                React.createElement("div", { className: css.icon }, renderOptionIcon(option.type, isSpecialLuggageOverweightOption)),
                React.createElement("div", { className: css.availabilityIcon }, renderOptionAvailabilityIcon(option.availability)),
                React.createElement("div", { className: css.title }, (_c = option.value) !== null && _c !== void 0 ? _c : option.title),
                React.createElement("div", { className: css.size }, option.size))))));
    }, [fare, props.isTooltipsOpened]);
    const renderHeader = useCallback(() => (React.createElement(React.Fragment, null,
        React.createElement("span", { className: css.header }, fare.title),
        React.createElement("div", { className: css.container }, options.map(renderOption)))), [fare, options]);
    const optionIcon = {
        [FareFamilyOptionsType.Exchangeable]: Exchangeable,
        [FareFamilyOptionsType.Refundable]: Refundable
    };
    const renderUpgradeOption = useCallback(() => (React.createElement("div", { className: css.upgradeOffer, onClick: props.onUpgrade },
        React.createElement("div", { className: css.upgradeOffer__content },
            React.createElement("span", { className: css.upgradeOffer__text }, upgradeOption.upgradeOffer),
            React.createElement("span", { className: css.upgradeOffer__choose },
                t('Choose'),
                " ",
                upgradeOption.canUpgradeTo.fareFamily.title),
            React.createElement("div", { className: css.upgradeOffer__options },
                React.createElement("div", { className: css.upgradeOffer__optionsPlus }),
                upgradeOption.optionsAvailableOnNextFare.map((option, index) => (React.createElement("div", { key: index }, optionIcon[option]))))),
        React.createElement(Button, { className: css.upgradeOffer__button }, ArrowForward))), [upgradeOption, props.onUpgrade]);
    const renderRulesButton = useCallback(({ flightId }) => (React.createElement(OverrideComponent, { componentProps: {
            flightId,
            orderId
        }, component: {
            FareGroupGridRulesButton: () => null
        } })), []);
    const renderers = useMemo(() => ({
        renderHeader,
        renderRulesButton,
        renderUpgradeOption: upgradeOption ? renderUpgradeOption : () => null
    }), [upgradeOption, props.onUpgrade, fare, props.isTooltipsOpened]);
    return (React.createElement(OverrideComponent, { componentProps: Object.assign(Object.assign({}, props), { className: cn(props.className, { [css.recommended]: fare.isRecommended && !props.isDisabled }), renderProps: renderers }), component: {
            FareGroup
        } }));
};
export default FareGroupGrid;
