import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { InfoDetails } from '../../../../../Icons';
import { useTheme } from '../../../../../theme';
import { useToggleable } from '../../../../../hooks';
import Button from '../../../../../Button';
import Conditions from './Conditions/Conditions';
import ModalX from '../../../../../BaseComponents/ModalX';
const ConditionsModal = ({ renderTrigger }) => {
    var _a;
    const { t } = useTranslation('CheckinConditions');
    const { ConditionsModal: theme } = useTheme('Checkin');
    const { isOpen, open, close } = useToggleable(false);
    return (React.createElement(React.Fragment, null, (_a = renderTrigger === null || renderTrigger === void 0 ? void 0 : renderTrigger({ onClick: open })) !== null && _a !== void 0 ? _a : (React.createElement(Button, { variant: "text", className: theme.button, onClick: open },
        React.createElement("div", { className: theme.icon }, InfoDetails),
        t('Check-in conditions'))),
        React.createElement(ModalX, { classes: {
                scrollBody: theme.scrollBody,
                paper: theme.paper
            }, isOpen: isOpen, onClose: close },
            React.createElement(Conditions, { className: theme.conditions }))));
};
export default ConditionsModal;
