import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApolloError } from 'apollo-boost';
import { useCheckOrderAvailabilityMutation, OrderStatus, OrderAdditionalServiceGdsServiceServiceType, useSaveOrderServicesMutation, useCheckinInfoQuery, useGetServicesRefundInfoQuery } from '@websky/graphql';
import { getOrder, getOrderId, getRecheckAvailabilityTimout, getRecommendedBaggage } from './store/order/selectors';
import { fillOrder } from './store/order/actions';
import { BAGGAGE_RECOMMENDATION_SHOWN, get, set } from '../cache';
import { setMultipleSelectedServices, setServiceAction } from './store/selectedServices/actions';
import { useIataCode } from '../hooks';
import { calculateCheckinAvailability } from './utils';
import { getPassengersSelectedProductIds } from '../RefundSummary/utils';
export const usePreselectedBaggageSelect = (selectBaggageWithoutModal) => {
    const dispatch = useDispatch();
    const orderId = useSelector(getOrderId);
    const recommendedBaggage = useSelector(getRecommendedBaggage);
    const localStorageKey = `${BAGGAGE_RECOMMENDATION_SHOWN}_${orderId}`;
    const recommendationWasShown = get(localStorageKey);
    const handleSelectAllServices = () => {
        const servicesToSelect = [];
        recommendedBaggage.forEach(service => {
            servicesToSelect.push({
                service: {
                    id: service.baggage.id,
                    type: OrderAdditionalServiceGdsServiceServiceType.Baggage,
                    confirmedCount: service.confirmedCount,
                    price: service.baggage.price
                },
                segmentIds: service.segmentIds,
                serviceId: service.baggage.id,
                count: service.count,
                passengerId: service.travellerId,
                segmentId: service.segmentIds[0]
            });
        });
        if (servicesToSelect.length) {
            dispatch(setMultipleSelectedServices(servicesToSelect));
        }
    };
    useEffect(() => {
        if (!recommendationWasShown) {
            set(localStorageKey, true);
            if (selectBaggageWithoutModal) {
                handleSelectAllServices();
            }
        }
    }, [recommendationWasShown]);
};
export const useAvailabilityRecheck = (setActualizationOpen) => {
    const timeoutToReCheckAvailability = useSelector(getRecheckAvailabilityTimout) * 1000;
    const orderId = useSelector(getOrderId);
    const orderStatus = useSelector(getOrder).status;
    const [timeout, setNewTimeout] = useState(null);
    const dispatch = useDispatch();
    const needToCheckAvailability = orderStatus === OrderStatus.New || orderStatus === OrderStatus.Booked;
    const [recheckAvailability, { data }] = useCheckOrderAvailabilityMutation({ fetchPolicy: 'no-cache' });
    const recheckIfTimoutExceed = () => {
        if (needToCheckAvailability) {
            recheckAvailability({ variables: { id: orderId } });
        }
    };
    useEffect(() => {
        var _a, _b;
        if (!(data === null || data === void 0 ? void 0 : data.CheckOrderAvailability)) {
            return () => setActualizationOpen(false);
        }
        if (((_a = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _a === void 0 ? void 0 : _a.status) === OrderStatus.Confirmed) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return () => setActualizationOpen(false);
        }
        if (((_b = data === null || data === void 0 ? void 0 : data.CheckOrderAvailability) === null || _b === void 0 ? void 0 : _b.status) === OrderStatus.Cancelled) {
            clearTimeout(timeout);
            setNewTimeout(null);
            return () => setActualizationOpen(false);
        }
        dispatch(fillOrder(data.CheckOrderAvailability));
        clearTimeout(timeout);
        setNewTimeout(null);
        if (timeoutToReCheckAvailability === 0) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
        setActualizationOpen(true);
        return () => {
            setActualizationOpen(false);
        };
    }, [timeoutToReCheckAvailability]);
    useEffect(() => {
        if (!timeout) {
            setNewTimeout(setTimeout(recheckIfTimoutExceed, timeoutToReCheckAvailability));
        }
    }, [timeout, timeoutToReCheckAvailability]);
};
export const useIsCheckinAvailable = (order) => {
    var _a;
    const isConfirmed = order.status === OrderStatus.Confirmed;
    const iataCode = useIataCode();
    const manualCheckIataCodes = ['DV'];
    const { data: getCheckinInfoResponse } = useCheckinInfoQuery({
        skip: !isConfirmed || manualCheckIataCodes.includes(iataCode),
        variables: {
            params: {
                aviaOrderId: order.id
            }
        }
    });
    if (manualCheckIataCodes.includes(iataCode) && isConfirmed) {
        return calculateCheckinAvailability(order);
    }
    return (_a = getCheckinInfoResponse === null || getCheckinInfoResponse === void 0 ? void 0 : getCheckinInfoResponse.CheckinInfo) === null || _a === void 0 ? void 0 : _a.isAvailable;
};
export const useServicesRefundInfo = (orderId, travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange, skip) => {
    const productIds = React.useMemo(() => {
        return getPassengersSelectedProductIds(travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange);
    }, [travellers, selectedPassengerIds, selectedSegmentIds, isAllFlightToExchange]);
    return useGetServicesRefundInfoQuery({
        variables: { params: { orderId, productIds } },
        skip: !productIds.length || skip
    });
};
export function useOrderServices() {
    // Hooks
    const dispatch = useDispatch();
    const [saveOrderServices, saveOrderServicesResult] = useSaveOrderServicesMutation();
    const [requestError, setRequestError] = useState();
    // Effects
    useEffect(() => {
        var _a;
        if ((_a = saveOrderServicesResult === null || saveOrderServicesResult === void 0 ? void 0 : saveOrderServicesResult.data) === null || _a === void 0 ? void 0 : _a.SaveOrderServices) {
            dispatch(fillOrder(saveOrderServicesResult.data.SaveOrderServices));
        }
    }, [saveOrderServicesResult === null || saveOrderServicesResult === void 0 ? void 0 : saveOrderServicesResult.data]);
    // Methods
    const onRequestError = useCallback((e) => {
        var _a;
        if (e instanceof ApolloError) {
            if ((_a = e.graphQLErrors) === null || _a === void 0 ? void 0 : _a.length) {
                setRequestError(e.graphQLErrors[0].message);
            }
        }
        else if (e instanceof Error) {
            setRequestError(e.message);
        }
        else {
            setRequestError('Unknown error');
        }
    }, [setRequestError]);
    const onClearError = useCallback(() => setRequestError(undefined), [setRequestError]);
    const onSaveServicesRequest = useCallback((params) => __awaiter(this, void 0, void 0, function* () {
        try {
            const { data } = yield saveOrderServices({ variables: { params } });
            return data === null || data === void 0 ? void 0 : data.SaveOrderServices;
        }
        catch (e) {
            onRequestError(e);
            return null;
        }
    }), [onRequestError]);
    const onAddServices = useCallback((services) => dispatch(setMultipleSelectedServices(services)), [
        dispatch
    ]);
    const onRemoveServices = useCallback((services) => {
        services.forEach(serviceToDelete => dispatch(setServiceAction(serviceToDelete)));
    }, [dispatch]);
    return {
        // data
        requestError,
        saveOrderServicesResult,
        isLoading: saveOrderServicesResult.loading,
        // methods
        saveOrderServices,
        onRequestError,
        onClearError,
        onSaveServicesRequest,
        onAddServices,
        onRemoveServices
    };
}
