import { call, put, select, takeLatest } from 'redux-saga/effects';
import { getSelectedServices } from './selectors';
import { ADD_SERVICE, REMOVE_SERVICE, SAVE_SERVICES, SET_SERVICE, DELETE_ALL_TYPES_ON_SEGMENT, SET_MULTIPLE_SELECTED_SERVICES } from './actions';
import { createClient } from '../../../graphql-client';
import { getIsBookedOrConfirmed, getOrderId, getOrder } from '../order/selectors';
import { fillOrder } from '../order/actions';
import { setOrderPrice } from '../orderPrice/actions';
import { setServicesIsSaving, setServicesSaved } from '../servicesIsSaving/actions';
import { OrderDocument, SaveOrderServicesDocument, OrderPriceQueryDocument } from '@websky/graphql';
function priceBreakdownRequest(services, orderId) {
    const client = createClient();
    return client.mutate({
        mutation: OrderPriceQueryDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        }
    });
}
function saveSelectedServicesRequest(services, orderId, context) {
    const client = createClient();
    return client.mutate({
        mutation: SaveOrderServicesDocument,
        variables: {
            params: {
                id: orderId,
                services: services
            }
        },
        context
    });
}
function actualOrderRequest(orderId) {
    const client = createClient();
    return new Promise(resolve => {
        client
            .mutate({
            mutation: OrderDocument,
            variables: {
                id: orderId
            }
        })
            .then(response => {
            var _a;
            if ((_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.Order) {
                resolve(response.data.Order);
            }
            else {
                resolve(null);
            }
        })
            .catch(() => {
            resolve(null);
        });
    });
}
function* getActualOrder(orderId) {
    const actualOrder = yield call(actualOrderRequest, orderId);
    if (actualOrder) {
        return actualOrder;
    }
    const previousOrderState = yield select(getOrder);
    return previousOrderState;
}
function* saveServiceWorker(action) {
    const selectedServices = yield select(getSelectedServices), orderId = yield select(getOrderId), isBookedOrConfirmedOrder = yield select(getIsBookedOrConfirmed);
    if (!isBookedOrConfirmedOrder) {
        yield put(setServicesIsSaving());
        try {
            const orderPrice = yield call(priceBreakdownRequest, selectedServices, orderId);
            if (orderPrice.data.OrderPrice && !orderPrice.errors) {
                yield put(setOrderPrice(orderPrice.data.OrderPrice));
            }
            yield put(setServicesSaved());
        }
        catch (e) {
            console.log(e);
            yield put(setServicesSaved());
        }
    }
    yield put(setServicesIsSaving());
    try {
        const request = yield call(saveSelectedServicesRequest, selectedServices, orderId, action.meta);
        if (request.data.SaveOrderServices && !request.errors) {
            yield put(fillOrder(request.data.SaveOrderServices));
        }
        else {
            const actualOrder = yield call(getActualOrder, orderId);
            yield put(fillOrder(actualOrder));
        }
        yield put(setServicesSaved());
    }
    catch (e) {
        const actualOrder = yield call(getActualOrder, orderId);
        yield put(fillOrder(actualOrder));
        yield put(setServicesSaved());
    }
}
export function* saveServicesSaga() {
    yield takeLatest([
        ADD_SERVICE,
        REMOVE_SERVICE,
        SAVE_SERVICES,
        SET_SERVICE,
        DELETE_ALL_TYPES_ON_SEGMENT,
        SET_MULTIPLE_SELECTED_SERVICES
    ], saveServiceWorker);
}
