import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SeatMap from '../../../../SeatMap/components/Component';
import { Money } from '../../../../Money';
import { useTheme } from '../../../../theme';
import { connect, useSelector } from 'react-redux';
import { getPassengerLabels, getSelectedExtraSeats } from '../../../store/passengers/selectors';
import { getTotalPrice } from '../../../store/priceBreakdown/selectors';
import { getOrderId, getSeatMap, getSegmentsForServices, getShowSeatRecommendations, getTravellers, getBonusForFlight, getIsAllSegmentsAvailableOnlyOnCheckin, getPriceInMiles, getIsNew, getMessages } from '../../../store/order/selectors';
import { fillOrder } from '../../../store/order/actions';
import ServicesToolbar from '../../../../ServicesToolbar';
import { getSeatsPriceToPay } from '../../../store/orderPrice/selectors';
import { setMultipleSelectedServices } from '../../../store/selectedServices/actions';
import { getPassengerStateCompatible } from '../../../store/selectedServices/selectors';
import { getSeatReduxCallbacks } from '../../../utils';
import { get, SEATS_RECOMMENDATION_SHOWN } from '../../../../cache';
import { useConfig } from '../../../../context';
import BookingSeatsWrapper from '../BookingSeatsWrapper/BookingSeatsWrapper';
import { useSeatMapToolbarProps } from '../../../../SeatMap/components/hooks';
import InfoMessagesContextProvider from '../../../../InfoMessages/context';
const Seats = ({ totalPrice, passengersState, goToNextStep: setNextStep, goToPrevStep, fillOrder, suggestSeatSelection, seatsTotalPrice, setMultipleSelectedServices }) => {
    const { t } = useTranslation('Checkout');
    const css = useTheme('Checkout').CheckoutStyles;
    const segments = useSelector(getSegmentsForServices);
    const selectedExtraSeatsServices = useSelector(getSelectedExtraSeats);
    const showRecommendations = useSelector(getShowSeatRecommendations);
    const orderId = useSelector(getOrderId);
    const isNewOrder = useSelector(getIsNew);
    const seatMap = useSelector(getSeatMap);
    const travellers = useSelector(getTravellers);
    const bonusForFlight = useSelector(getBonusForFlight);
    const priceInMiles = useSelector(getPriceInMiles);
    const isAllSeatsAvailableOnCheckin = useSelector(getIsAllSegmentsAvailableOnlyOnCheckin);
    const orderMessages = useSelector(getMessages);
    const { segmentIndex, passengerIndex, setSegment, setPassenger, onToolbarButtonClick } = useSeatMapToolbarProps(seatMap);
    const { global: { seatMap: { showRecommendedSeats } } } = useConfig();
    const seatMapCallbacks = useMemo(() => getSeatReduxCallbacks(passengersState, setMultipleSelectedServices), [
        passengersState,
        setMultipleSelectedServices
    ]);
    const seatsRecommendationShown = showRecommendedSeats
        ? get(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_${segmentIndex}`)
        : get(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_ALL`);
    const confirmRecommendedSeatsWithoutDialog = !seatsRecommendationShown && !isAllSeatsAvailableOnCheckin && !showRecommendedSeats;
    const handleToolbarButtonClick = (suggestCallback) => {
        onToolbarButtonClick(() => {
            if (suggestSeatSelection) {
                suggestCallback();
                return;
            }
            setNextStep();
        });
    };
    return (React.createElement(BookingSeatsWrapper, { passengers: passengersState, goToNextStep: setNextStep }, ({ goToNext }) => (React.createElement(ServicesToolbar, { toolbarProps: {
            totalPrice,
            onBackButtonClick: goToPrevStep,
            onButtonClick: () => handleToolbarButtonClick(goToNext),
            additional: seatsTotalPrice.amount ? (React.createElement("div", { className: css.gdsTotalPrice },
                t('Seats'),
                ": ",
                React.createElement(Money, { money: seatsTotalPrice }))) : null,
            miles: bonusForFlight,
            priceInMiles
        }, mobileToolbarProps: {
            onBack: goToPrevStep,
            onContinue: () => handleToolbarButtonClick(goToNext)
        } },
        React.createElement(InfoMessagesContextProvider, { value: { messages: orderMessages } },
            React.createElement(SeatMap, Object.assign({ confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog, orderId: orderId, seatMap: seatMap, passengers: passengersState, travellers: travellers, segments: segments, fillOrder: fillOrder, segmentIndex: segmentIndex, passengerIndex: passengerIndex, showRecommendations: !isAllSeatsAvailableOnCheckin && showRecommendations, onProceed: suggestSeatSelection ? goToNext : setNextStep, setSegment: setSegment, setPassenger: setPassenger, selectedExtraSeats: selectedExtraSeatsServices, isNewOrder: isNewOrder }, seatMapCallbacks)))))));
};
const mapStateToProps = () => {
    return (state, ownProps) => (Object.assign(Object.assign({}, ownProps), { passengersState: getPassengerStateCompatible(state), totalPrice: getTotalPrice(state), passengerLabels: getPassengerLabels(state), seatsTotalSumFromOrder: getSeatsPriceToPay(state), seatsTotalPrice: getSeatsPriceToPay(state) }));
};
const mapDispatchToProps = {
    fillOrder,
    setMultipleSelectedServices
};
export default connect(mapStateToProps, mapDispatchToProps)(Seats);
