import * as React from 'react';
import { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import cn from 'classnames';
import { FormControlLabel, Radio } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { InsurancePrice } from '../InsurancePrice/InsurancePrice';
import { initI18n } from '../../../utils';
import Tooltip from '../../../Tooltip';
initI18n('InsurancePage');
export const SelectForm = ({ className, selected, price, onSelect, onRemove, hideForAllPassengersTextBecauseSinglePassenger, isTouched, isError }) => {
    const { t } = useTranslation('InsurancePage');
    const { SelectForm: theme } = useTheme('InsurancePage');
    const [value, setValue] = useState(isTouched ? `${selected}` : selected ? 'true' : null);
    const containerRef = React.useRef(null);
    React.useEffect(() => {
        if (isError && containerRef.current) {
            containerRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest'
            });
        }
    }, [isError]);
    const radioItems = [
        {
            value: 'true',
            classes: {
                label: theme.labelContainer
            },
            label: (React.createElement("div", { className: cn(theme.label, { [theme.labelMute]: value === 'false' }) },
                React.createElement("span", null, t('I want protect my trip.')),
                React.createElement(InsurancePrice, { className: theme.price, contentClassName: theme.priceContent, selected: selected, price: price, variant: 'mostPopular', hideForAllPassengersTextBecauseSinglePassenger: hideForAllPassengersTextBecauseSinglePassenger })))
        },
        {
            value: 'false',
            classes: {
                label: theme.labelContainer
            },
            label: (React.createElement("div", { className: cn(theme.label, { [theme.labelMute]: value === 'true' }) }, t('I do not want to insure my life or luggage and assume all risks')))
        }
    ];
    return (React.createElement(Tooltip, { classes: {
            tooltip: theme.tooltip,
            arrow: theme.tooltip_arrow,
            tooltipArrow: theme.tooltip_arrow
        }, open: isError, title: t('Please select the insurance'), placement: "top-start" },
        React.createElement(FormControl, { className: cn(className, theme.form, {
                [theme.singleTravellerInOrder]: hideForAllPassengersTextBecauseSinglePassenger,
                [theme.form_error]: isError
            }), error: isError, ref: containerRef },
            React.createElement(RadioGroup, { name: "select-insurance-form", onChange: e => {
                    if (e.target.value === 'true') {
                        onSelect();
                    }
                    else {
                        onRemove();
                    }
                    setValue(e.target.value);
                }, defaultValue: 'true' }, radioItems.map(item => (React.createElement(FormControlLabel, { key: item.value, value: item.value, className: theme.root, control: React.createElement(Radio, { className: theme.radio, classes: {
                        checked: theme.checked
                    }, value: item.value, checked: value === item.value }), classes: item.classes, label: item.label })))))));
};
