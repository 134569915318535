import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import Button from '../../../Button';
import Money from '../../../Money';
const StepbarServiceSummary = ({ className, children, type, group, isPassed, isActive, isSelected, onClick }) => {
    var _a;
    const { t } = useTranslation('MobileStepbar');
    const { StepbarServiceSummary: css } = useTheme('MobileStepbar');
    const isPassedOrActive = isPassed || isActive;
    const canChange = onClick && isPassedOrActive;
    return (React.createElement("div", { className: cn(className, css.container, {
            [css.disabled]: !isPassedOrActive
        }), "data-step": type },
        React.createElement("div", { className: css.header },
            React.createElement("span", { className: css.header__number }),
            React.createElement("span", { className: css.header__icon }, group.icon),
            React.createElement("span", { className: css.header__title }, group.header),
            isPassedOrActive && (React.createElement(React.Fragment, null, isSelected
                ? ((_a = group.totalPrice) === null || _a === void 0 ? void 0 : _a.amount) > 0 && (React.createElement("span", { className: css.header__price },
                    React.createElement(Money, { money: group.totalPrice })))
                : canChange && (React.createElement(Button, { variant: "text", className: cn(css.button, css.button_add), onClick: onClick }, t('Add')))))),
        isSelected && (React.createElement("div", { className: css.content },
            children,
            canChange && (React.createElement("div", { className: css.footer },
                React.createElement(Button, { variant: "text", className: css.button, onClick: onClick }, t('Change'))))))));
};
export default StepbarServiceSummary;
