import * as React from 'react';
import { useTheme } from '../theme';
import Button from '../Button/Button';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../utils';
import { useConfig } from '../context';
initI18n('Page404');
const Page404 = ({ onClick }) => {
    const css = useTheme('Page404').Page404;
    const { t } = useTranslation('Page404');
    const { global: { page404Url } } = useConfig();
    if (page404Url) {
        window.location.href = page404Url;
        return null;
    }
    return (React.createElement("div", { className: css.wrapper },
        React.createElement("div", { className: css.content },
            React.createElement("h3", { className: css.error__description }, t('Destination unknown')),
            React.createElement("h1", { className: css.error__code }, t('PAGE NOT FOUND')),
            React.createElement(Button, { variant: 'flat', className: css.button, onClick: onClick }, t('Take me home')))));
};
export default Page404;
