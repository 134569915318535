import { __awaiter } from "tslib";
import * as React from 'react';
import cn from 'classnames';
import { connect } from 'react-redux';
import { Form } from 'react-final-form';
import Bottom from './Bottom/Bottom';
import Controls from './Controls/Controls';
import Segments from './Segments/Segments';
import { generateOfferHotelLink, setFieldDataMutator } from '../../utils';
import { redirectToFastSearch, submitForm } from '../../store/actions';
import { initI18n } from '../../../utils';
import { useTheme } from '../../../theme';
import { useConfig } from '../../../context';
import { useRenderers } from '../../../renderProps';
import { isOfferHotels } from '../../store/offerHotels/selectors';
import { getSegments, isMultiCity } from '../../store/segments/selectors';
import { getPassengersForRequest, getTotalPassengersCount } from '../../store/passengers/selectors';
import SimpleLoader from '../../../SimpleLoader';
import { useCurrentUser } from '../../../CurrentUser/CurrentUser.hooks';
const LastSearch = React.lazy(() => import('./LastSearch/LastSearch'));
initI18n('SearchForm');
const SearchForm = React.memo(({ className, submitForm, redirectToFastSearch, onSubmit, renderPromoCode, promoCode, segments, offerHotels, passengersCount, passengers, isMultiCity }) => {
    var _a, _b;
    useCurrentUser();
    const { global: { companyInfo: { iataCode } }, SearchForm: { engineURL, proMode, offerHotelsLink, offerHotelsSupplier, offerHotelsAdditionalId, offerHotelsUtm } } = useConfig();
    const { SearchForm: css } = useTheme('SearchForm');
    const [loading, setLoading] = React.useState(false);
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (offerHotels) {
            setLoading(true);
            const generatedHotelLink = generateOfferHotelLink(offerHotelsLink, segments, passengersCount, passengers, iataCode, offerHotelsSupplier, offerHotelsAdditionalId, offerHotelsUtm);
            redirectToFastSearch({
                engineUrl: engineURL,
                hotelsUrl: generatedHotelLink,
                openOfferHotelsStrategy: 'currentTab'
            });
            return;
        }
        if (onSubmit) {
            submitForm(onSubmit);
        }
        else if (engineURL) {
            redirectToFastSearch({
                engineUrl: engineURL
            });
        }
    });
    const BeforeAdornment = (_b = (_a = useRenderers().SearchFormRenderers) === null || _a === void 0 ? void 0 : _a.BeforeAdornment) !== null && _b !== void 0 ? _b : (() => null);
    return (React.createElement("div", { className: cn(css.searchForm, className, {
            [css.searchForm_advanced]: proMode,
            [css.searchForm_multiCity]: isMultiCity
        }) },
        React.createElement("div", { className: css.wrapper },
            BeforeAdornment && (React.createElement("div", { className: css.beforeAdornment__wrapper },
                React.createElement(BeforeAdornment, null))),
            React.createElement(Form, { onSubmit: handleSubmit, mutators: { setFieldData: setFieldDataMutator }, subscription: {} }, ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit, "data-search-form": true },
                React.createElement("div", { className: css.row },
                    React.createElement(Segments, null),
                    React.createElement(Controls, null)),
                React.createElement(Bottom, { renderPromoCode: renderPromoCode, promoCode: promoCode }))))),
        proMode && (React.createElement(React.Suspense, { fallback: "Loading..." },
            React.createElement(LastSearch, null))),
        loading && React.createElement(SimpleLoader, null)));
});
const mapStateToProps = (state) => ({
    offerHotels: isOfferHotels(state),
    segments: getSegments(state),
    passengersCount: getTotalPassengersCount(state),
    passengers: getPassengersForRequest(state),
    isMultiCity: isMultiCity(state)
});
const mapDispatchToProps = {
    submitForm,
    redirectToFastSearch
};
export default connect(mapStateToProps, mapDispatchToProps)(SearchForm);
