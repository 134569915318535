import { __awaiter } from "tslib";
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { delay } from 'redux-saga';
import recentSearchesSagas from './recentSearches/sagas';
import { REDIRECT_TO_FAST_SEARCH, SUBMIT_FORM } from './actions';
import { getSearchParameters } from './selectors';
import { createFastSearchURL } from '../utils';
import { SET_SEGMENTS, setLocation } from './segments/actions';
import { LocationsDocument, RouteType } from '@websky/graphql';
import { LocationType } from '../types';
import { createClient } from '../../graphql-client';
import { SearchGoal, reachGoal } from '../../analytics';
import { isOfferHotels } from './offerHotels/selectors';
import passengersSaga from './passengers/sagas';
const getLocation = (query) => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const client = createClient();
    const request = yield client.query({
        query: LocationsDocument,
        variables: {
            parameters: {
                query
            }
        }
    });
    return (_a = request === null || request === void 0 ? void 0 : request.data) === null || _a === void 0 ? void 0 : _a.LocationSuggestions;
});
function* sendAnalyticsEvent() {
    const searchParameters = yield select(getSearchParameters);
    const isOfferHotelsActive = yield select(isOfferHotels);
    const from = searchParameters.segments[0];
    const to = searchParameters.segments[searchParameters.segments.length - 1];
    const isRT = from.departure.iata === to.arrival.iata;
    const goal = isOfferHotelsActive ? SearchGoal.SuccessfulSearchWithHotels : SearchGoal.SuccessfulSearchWithoutHotels;
    const funnelParameters = {
        airport_from: from.departure.iata,
        airport_to: to.arrival.iata,
        date_start: from.date,
        date_end: to.date,
        passenger_type: searchParameters.passengers.map(passenger => `${passenger.count}${passenger.passengerType}`),
        route_type: isRT ? RouteType.RT : RouteType.OW,
        avia_operator: window.location.hostname,
        transport_leg: null,
        payment_type: null,
        auth_type: null,
        register_type: null,
        search_id: null
    };
    reachGoal(goal, { funnel_purchase: funnelParameters });
}
function* submitFormSaga() {
    yield takeLatest(SUBMIT_FORM, function* ({ payload: onSubmit }) {
        const searchParameters = yield select(getSearchParameters);
        yield call(sendAnalyticsEvent);
        onSubmit(searchParameters);
    });
}
function* redirectToFastSearchSaga() {
    yield takeLatest(REDIRECT_TO_FAST_SEARCH, function* ({ payload }) {
        const searchParameters = yield select(getSearchParameters);
        yield call(sendAnalyticsEvent);
        yield delay(500); // timeout for analytics request
        const fastSearchURL = `${payload.engineUrl}#/search/${createFastSearchURL(searchParameters)}`;
        if (payload.hotelsUrl) {
            window.open(payload.openOfferHotelsStrategy === 'newTab' ? yield payload.hotelsUrl : fastSearchURL, '_blank');
            window.location.href =
                payload.openOfferHotelsStrategy === 'currentTab' ? yield payload.hotelsUrl : fastSearchURL;
        }
        else {
            window.location.href = fastSearchURL;
        }
    });
}
function* handleSetSegments() {
    yield takeLatest(SET_SEGMENTS, function* ({ payload: segments }) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!!((_b = (_a = segments.simpleRouteSegment) === null || _a === void 0 ? void 0 : _a.arrival) === null || _b === void 0 ? void 0 : _b.iata)) {
            const suggest = yield call(getLocation, segments.simpleRouteSegment.arrival.iata);
            if (((_d = (_c = suggest === null || suggest === void 0 ? void 0 : suggest[0]) === null || _c === void 0 ? void 0 : _c.cities) === null || _d === void 0 ? void 0 : _d[0].iata) === segments.simpleRouteSegment.arrival.iata) {
                yield put(setLocation(suggest[0].cities[0], LocationType.Arrival));
            }
        }
        if (!!((_f = (_e = segments.simpleRouteSegment) === null || _e === void 0 ? void 0 : _e.departure) === null || _f === void 0 ? void 0 : _f.iata)) {
            const suggest = yield call(getLocation, segments.simpleRouteSegment.departure.iata);
            if (((_h = (_g = suggest === null || suggest === void 0 ? void 0 : suggest[0]) === null || _g === void 0 ? void 0 : _g.cities) === null || _h === void 0 ? void 0 : _h[0].iata) === segments.simpleRouteSegment.departure.iata) {
                yield put(setLocation(suggest[0].cities[0], LocationType.Departure));
            }
        }
    });
}
export default function* rootSaga() {
    yield all([
        recentSearchesSagas(),
        submitFormSaga(),
        handleSetSegments(),
        redirectToFastSearchSaga(),
        passengersSaga()
    ]);
}
