import * as React from 'react';
import { initI18n } from '../../utils';
import MealService from '../../MealService';
import { MealViewMode } from '../../Meal/types';
import MealRecommendation from '../../ServiceRecommendation/components/Meal/Component';
import { MealWrapper } from './MealWrapper';
import { useToggleable } from '../../hooks';
initI18n('MealPage');
const MealPage = ({ meals, segments, selectedMeals, recommendedMeals, passengers, onMealAdd, onMealRemove, onMealAddForPassengers, onMealClear, onMealConfirm, onMobileProceed, onMobileBack, onRecommendationConfirm, onRecommendationDisagree, readonly = false, showRecommendations = false, isLoading }) => {
    const { toggle: setInitialSelectedMeals } = useToggleable(false);
    const initialSelectedMeals = React.useMemo(() => {
        return [...selectedMeals];
    }, [setInitialSelectedMeals]);
    return (React.createElement(React.Fragment, null,
        showRecommendations && (React.createElement(MealRecommendation, { segments: segments, passengers: passengers, recommendedMeals: recommendedMeals, onConfirm: () => {
                onRecommendationConfirm(recommendedMeals);
                setInitialSelectedMeals();
            }, onDisagree: onRecommendationDisagree })),
        React.createElement(MealService, { meals: meals, recommendedMeals: recommendedMeals, segments: segments, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, onMobileProceed: onMobileProceed, onMobileBack: onMobileBack, mealRenderer: (meal, segmentId, isSingleMeal, mode = MealViewMode.select) => (React.createElement(MealWrapper, { fullSize: isSingleMeal, key: meal.id, meal: meal, segmentId: segmentId, selectedMeals: selectedMeals, initialSelectedMeals: initialSelectedMeals, passengers: passengers, onMealAdd: onMealAdd, onMealRemove: onMealRemove, onMealConfirm: () => {
                    onMealConfirm(meal, segmentId);
                    setInitialSelectedMeals();
                }, onMealAddForPassengers: onMealAddForPassengers, onMealClear: onMealClear, onMealOpen: setInitialSelectedMeals, readonly: readonly, isRecommend: recommendedMeals === null || recommendedMeals === void 0 ? void 0 : recommendedMeals.some(recommend => recommend.meal.id === meal.id), isLoading: isLoading, mode: mode })) })));
};
export default MealPage;
