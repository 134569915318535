import * as React from 'react';
import { Passenger } from './Passenger/Passenger';
import { PassengerType } from '../../enums';
import { useTheme } from '../../theme';
import { initI18n } from '../../utils';
import cn from 'classnames';
initI18n('Passengers');
export const Passengers = React.memo(({ passengers, addPassenger, removePassenger }) => {
    const { PassengersStyles: css } = useTheme('Passengers');
    return (React.createElement("ul", { className: css.list }, passengers.map(passenger => {
        return (React.createElement("li", { "data-pass-type": passenger.type, key: passenger.type, className: cn(css.item, {
                [css.item_FNN]: passenger.type === PassengerType.Teenager
            }) },
            React.createElement(Passenger, { passenger: passenger, addPassenger: addPassenger, removePassenger: removePassenger, decreaseIsEnabled: passenger.canBeDecreased, increaseIsEnabled: passenger.canBeIncreased })));
    })));
});
export default Passengers;
