import * as React from 'react';
import * as ReactDOM from 'react-dom';
import cn from 'classnames';
import ButtonBase from '@material-ui/core/ButtonBase';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import SeatTooltip from './SeatTooltip/SeatTooltip';
import { useTheme } from '../../../../theme';
import { forwardRef, useContext, useState } from 'react';
import { SeatsRecommendContext } from '../../../../Checkout/components/Checkout/SeatsRecommendation/SeatsRecommendation';
import { TravellerFieldEnum, SeatComfort } from '@websky/graphql';
import { isFreeSeat as getIsFreeSeat } from '../utils';
import { getSeatPrice } from '../../../utils';
import SeatServiceSlide from './SeatServiceSlide/SeatServiceSlide';
import { getUserValue, TABLET_MIDDLE_WIDTH } from '../../../../utils';
import { useToggleable } from '../../../../hooks';
const Seat = forwardRef(({ seat, onSelect, isAvailable, isSmall, occupiedBy, passenger, isConfirmed, segmentId, passengerId, onClear, backgroundColor, hideSeatName, className, combinedSeat, isCombinedWith, onMouseEnter, onMouseLeave, dataColorNum }, ref) => {
    var _a, _b, _c, _d;
    const theme = useTheme('SeatMap').SeatStyles;
    const { isOpen: showSeatInfo, open: openSeatInfo, close: closeSeatInfo } = useToggleable(false);
    const isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const [isTooltipOpened, setTooltipOpened] = useState(false);
    const seatRecommendContext = useContext(SeatsRecommendContext);
    let seats = [seat];
    if (combinedSeat) {
        seats = [...seats, combinedSeat];
    }
    const isFreeSeat = getIsFreeSeat(seat);
    const isAvailableSeat = (isAvailable || occupiedBy) && !isConfirmed;
    const handleSelect = () => {
        if (isMobile) {
            onMouseEnter();
            openSeatInfo();
        }
        else if (isAvailableSeat) {
            onSelect(seat, passengerId);
            setTooltipOpened(false);
        }
    };
    const handleMobileSelect = (seat) => {
        closeSeatInfo();
        isAvailable && onSelect(seat, passengerId);
        onMouseLeave();
    };
    const onCloseSeatInfo = () => {
        closeSeatInfo();
        onMouseLeave();
    };
    const preselectSeatForCurrentPassenger = seatRecommendContext.isOpen
        ? (_b = (_a = seatRecommendContext.rowsMap[segmentId]) === null || _a === void 0 ? void 0 : _a.seats[passengerId]) === null || _b === void 0 ? void 0 : _b.seat.number
        : false;
    const isPreselect = seatRecommendContext.isOpen
        ? (_c = seatRecommendContext.rowsMap[segmentId]) === null || _c === void 0 ? void 0 : _c.seatsMap.hasOwnProperty(seat.number)
        : false;
    let seatLabel = `<span>${seat.row}</span><span>${seat.letter}</span>`;
    let shortPassengerName = false;
    if (occupiedBy) {
        const firstName = getUserValue(occupiedBy, TravellerFieldEnum.FirstName), lastName = getUserValue(occupiedBy, TravellerFieldEnum.LastName);
        if (firstName && lastName) {
            seatLabel = `${lastName.charAt(0)}${firstName.charAt(0)}`;
            shortPassengerName = true;
        }
        else {
            seatLabel = (parseInt(occupiedBy.id) + 1).toString();
        }
    }
    const seatPrice = getSeatPrice(seat);
    const button = (React.createElement(ButtonBase, { ref: ref, "data-rfisc": seat.rfisc, "data-seat-number": seat.number, "data-color-num": dataColorNum, className: cn(theme.seat, className, {
            [theme.seat_small]: isSmall,
            [theme.seat_free]: isFreeSeat,
            [theme.seat_standart]: seat.service && seat.service.comfort === SeatComfort.Standart,
            [theme.seat_business]: seat.service && seat.service.comfort === SeatComfort.Comfort,
            [theme.seat_preferred]: seat.service && seat.service.comfort === SeatComfort.Preferred,
            [theme.seat_unexistent]: !seat.isExistent,
            [theme.seat_unavailable]: !isAvailableSeat,
            [theme.seat_occupied]: occupiedBy,
            [theme.seat_confirmed]: isConfirmed,
            [theme.seat_recommendCurrent]: isPreselect && preselectSeatForCurrentPassenger === seat.number,
            [theme.seat_recommend]: isPreselect,
            [theme.seat_customColor]: !!backgroundColor,
            [theme.seat_combinedLeft]: isCombinedWith === 'left',
            [theme.seat_combinedRight]: isCombinedWith === 'right'
            // [theme.male]: seat.isSeat && seat.occupiedBy === Gender.Male,
            // [theme.female]: props.seat.isSeat && props.seat.occupiedBy === Gender.Female
        }), disabled: !isAvailableSeat, onClick: handleSelect, onMouseEnter: isMobile ? null : onMouseEnter, onMouseLeave: isMobile ? null : onMouseLeave, style: backgroundColor ? { background: backgroundColor } : null }, seat.isExistent && (isAvailable || occupiedBy) && (React.createElement("div", { className: cn(theme.number, { [theme.number_name]: shortPassengerName }), dangerouslySetInnerHTML: {
            __html: seatLabel
        } }))));
    return (React.createElement(React.Fragment, null,
        React.createElement(MediaQuery, { minWidth: TABLET_MIDDLE_WIDTH + 1 },
            React.createElement(SeatTooltip, { isFreeSeat: isFreeSeat, seat: seat, service: (_d = seat.services) === null || _d === void 0 ? void 0 : _d[0], isRecommend: isPreselect, occupiedBy: occupiedBy, isAvailable: isAvailable, seatPrice: seatPrice, hideSeatName: hideSeatName, isTooltipOpened: isTooltipOpened, onToggle: setTooltipOpened }, button)),
        React.createElement(MediaQuery, { maxWidth: TABLET_MIDDLE_WIDTH },
            button,
            ReactDOM.createPortal(React.createElement(SeatServiceSlide, { dataColorNum: dataColorNum, isFreeSeat: isFreeSeat, onClose: onCloseSeatInfo, onSelect: handleMobileSelect, seats: seats, passenger: occupiedBy !== null && occupiedBy !== void 0 ? occupiedBy : passenger, isOpen: showSeatInfo, isSelected: !!occupiedBy }), document.querySelector('body')))));
});
export default React.memo(Seat);
