import * as React from 'react';
import { useCallback, useState } from 'react';
import cn from 'classnames';
import MediaQuery from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../../../theme';
import { MOBILE_MIN_WIDTH } from '../../../utils';
import { SlideBottom } from '../../../index';
import { overridedIcons } from '../../../Icons/index';
import { SELECTED_DISHES_CATEGORY_ID, ALL_DISHES_CATEGORY_ID } from '../../constants';
import List from './List/List';
import CloseIcon from '../../../CloseIcon/components/CloseIcon';
const Categories = props => {
    const { CategoriesStyles: theme } = useTheme('MealService');
    const { t } = useTranslation('MealService');
    const [isOpenSlide, setOpenSlide] = useState(false);
    const toggleOpenSlide = () => setOpenSlide(!isOpenSlide);
    const mobileButtonTitle = React.useMemo(() => {
        switch (props.selectedCategory) {
            case ALL_DISHES_CATEGORY_ID:
                return t('All');
            case SELECTED_DISHES_CATEGORY_ID:
                return t('Selected dishes');
            default:
                return props.categories[props.selectedCategory];
        }
    }, [props.categories, props.selectedCategory]);
    const defaultCategory = {
        id: ALL_DISHES_CATEGORY_ID,
        title: t('All')
    };
    const categories = React.useMemo(() => {
        const res = props.categories.length === 1
            ? [defaultCategory]
            : [...props.categories.map((title, index) => ({ id: index, title }))];
        if (!res.includes(defaultCategory) && props.categories.length > 1) {
            if (props.defaultGroupPlacement === 'end') {
                res.push(defaultCategory);
            }
            else {
                res.unshift(defaultCategory);
            }
        }
        if (props.numOfSelectedMeals > 0) {
            res.push({
                id: SELECTED_DISHES_CATEGORY_ID,
                title: `${t('Selected dishes')} (${props.numOfSelectedMeals})`
            });
        }
        return res;
    }, [props.categories, props.defaultGroupPlacement, props.numOfSelectedMeals]);
    const mobileOnSelect = useCallback((id) => {
        props.onSelect(id);
        toggleOpenSlide();
    }, [props.onSelect, isOpenSlide]);
    return (React.createElement("div", { className: theme.wrapper },
        React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement(List, { categories: categories, selectedCategory: props.selectedCategory, onSelect: props.onSelect })),
        React.createElement(MediaQuery, { maxWidth: MOBILE_MIN_WIDTH },
            React.createElement("button", { className: cn(theme.category__button), onClick: toggleOpenSlide },
                React.createElement("span", null, mobileButtonTitle),
                React.createElement("span", { className: theme.button__icon }, overridedIcons['ArrowIcon'])),
            React.createElement(SlideBottom, { isOpen: isOpenSlide, onClose: toggleOpenSlide },
                React.createElement("div", { className: theme.mobile__header },
                    React.createElement("span", { className: theme.mobile__title }, t('Select menu')),
                    React.createElement(CloseIcon, { isGray: true, onClick: toggleOpenSlide })),
                React.createElement(List, { categories: categories, selectedCategory: props.selectedCategory, onSelect: mobileOnSelect })))));
};
export default Categories;
