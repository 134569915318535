import { __awaiter } from "tslib";
import * as React from 'react';
import { useCallback } from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import SeatMapService from './SeatMapService/SeatMapService';
import { useGraphQLClient } from '../../graphql-client';
import { getSelectedServices, updateSeatServices } from '../utils';
import SimpleLoader from '../../SimpleLoader';
import { OverrideComponent } from '../../renderProps';
const SeatMapComponentStore = React.memo(({ className, orderId, passengers, seatMap, segmentIndex, passengerIndex, runServiceRequest, onProceed, setSegment, setPassenger, segments, selectedExtraSeats, fillOrder, showRecommendations, travellers, fullScreen = true, setSeatCallback, setClearSeatCallback, onSegmentClear, onPassengerClear, isLoading, confirmRecommendedSeatsWithoutDialog, onClose, isNewOrder, dialogRef, isReturnFlight }) => {
    const seatSelectHandler = useCallback((passengerId, seats, segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        const currentPassenger = passengers.find(orderPassenger => orderPassenger.id === passengerId);
        if (runServiceRequest) {
            runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seats));
        }
        else {
            setSeatCallback(seats, currentPassenger.id, segmentId.toString());
        }
    }), [runServiceRequest, passengers, seatMap]);
    const clearSeatHandler = useCallback((passengerId, seats, segmentId) => __awaiter(void 0, void 0, void 0, function* () {
        const currentPassenger = passengers.find(orderPassenger => orderPassenger.id === passengerId);
        if (runServiceRequest) {
            runServiceRequest(updateSeatServices(passengers, seatMap.segments[segmentId], currentPassenger.id, seats));
        }
        else {
            setClearSeatCallback(seats, currentPassenger.id, segmentId.toString());
        }
    }), [runServiceRequest, passengers, seatMap]);
    // do not make two request per passenger in order to clear segment seats
    const clearOnSegment = useCallback((segmentIndex) => {
        if (runServiceRequest) {
            const passengersList = [];
            const currentSegmentId = seatMap.segments[segmentIndex].segmentsInfo.id;
            passengers.forEach((passenger, passengerIndex) => {
                passengersList.push({
                    passengerId: passenger.id,
                    seats: getSelectedServices(passenger, seatMap.segments[segmentIndex], passengerIndex, null)
                        .filter(seat => (seat === null || seat === void 0 ? void 0 : seat.isConfirmed) || seat.segmentId !== currentSegmentId)
                        .map(seat => ({
                        row: parseInt(seat.row),
                        letter: seat.letter,
                        isConfirmed: seat.isConfirmed,
                        isChangeable: seat.isChangeable,
                        segment: {
                            id: seat.id
                        },
                        seat: null,
                        product: null,
                        type: seat.seatType
                    }))
                });
            });
            runServiceRequest(passengersList);
        }
        else {
            onSegmentClear(segmentIndex);
        }
    }, [runServiceRequest, passengers, seatMap]);
    return seatMap ? (React.createElement(React.Fragment, null,
        isLoading && React.createElement(SimpleLoader, null),
        React.createElement(OverrideComponent, { componentProps: {
                className: className,
                clearOnSegment: clearOnSegment,
                passengers: passengers,
                seatMaps: seatMap,
                passengerIndex,
                segmentIndex,
                onProceed: onProceed,
                setSegment,
                setPassenger,
                onSelect: seatSelectHandler,
                fullScreen: fullScreen,
                onCancelSeat: clearSeatHandler,
                travellers: travellers,
                seatRecommendEnabled: showRecommendations && !!segments && !!travellers && !!fillOrder,
                segments: segments,
                selectedExtraSeats: selectedExtraSeats,
                orderId: orderId,
                runServiceRequest: runServiceRequest,
                setSeatCallback: setSeatCallback,
                confirmRecommendedSeatsWithoutDialog: confirmRecommendedSeatsWithoutDialog,
                onClose: onClose,
                onPassengerClear: onPassengerClear,
                isNewOrder: isNewOrder,
                dialogRef: dialogRef,
                isReturnFlight: isReturnFlight
            }, component: { SeatMapService } }))) : null;
});
const SeatMapWrapperWithGql = props => {
    const client = useGraphQLClient();
    return (React.createElement(ApolloProvider, { client: client },
        React.createElement(SeatMapComponentStore, Object.assign({}, props))));
};
export default SeatMapWrapperWithGql;
