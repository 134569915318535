import { __awaiter } from "tslib";
import { Log } from '../../sentry';
const loadScript = (url) => new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = url;
    script.type = 'text/javascript';
    script.async = true;
    script.onload = () => resolve(null);
    script.onerror = () => {
        console.error(`unable to load script: ${url}`);
        Log({ message: `unable to load script: ${url}`, level: 'error' });
        reject(new Error(`Script load error for: ${url}`));
    };
    document.body.appendChild(script);
});
const loadSvgSprite = (url) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield fetch(url);
        const content = yield response.text();
        // вставляем svg в начало body
        document.body.insertAdjacentHTML('afterbegin', content);
    }
    catch (e) {
        console.error(`unable to load engine icons: ${url}`);
        Log({ message: `unable to load engine icons: ${url}`, level: 'error' });
    }
});
const addExternalResources = (options) => __awaiter(void 0, void 0, void 0, function* () {
    const { scripts = [], svgs = [] } = options;
    // Параллельно загружаем все скрипты
    yield Promise.all(scripts.map((scriptUrl) => __awaiter(void 0, void 0, void 0, function* () {
        yield loadScript(scriptUrl);
    })));
    // Параллельно загружаем все svg-спрайты
    yield Promise.all(svgs.map((svgUrl) => __awaiter(void 0, void 0, void 0, function* () {
        yield loadSvgSprite(svgUrl);
    })));
});
// Функция инициализации приложения
export const bootstrap = (config) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d;
    try {
        yield addExternalResources({
            svgs: (_b = (_a = config.global.additionalResources) === null || _a === void 0 ? void 0 : _a.svgs) !== null && _b !== void 0 ? _b : [],
            scripts: (_d = (_c = config.global.additionalResources) === null || _c === void 0 ? void 0 : _c.scripts) !== null && _d !== void 0 ? _d : []
        });
        console.info('All external resources were loaded successfully.');
    }
    catch (error) {
        console.error('Bootstrap error:', error);
    }
});
