import * as React from 'react';
import { memo, useMemo } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../../../theme';
import { useTranslation } from 'react-i18next';
import { InfoDetails } from '../../../../../Icons';
import { Checkbox, FormControlLabel } from '@mui/material';
import { Field } from 'react-final-form';
const List = memo(props => {
    const theme = useTheme('PassengerForm').Disabilities;
    const { t } = useTranslation('Passenger');
    const idsToDisable = useMemo(() => {
        var _a;
        const ids = [];
        Object.entries((_a = props.passengerDisabilitiesState.values) !== null && _a !== void 0 ? _a : {}).forEach(([key, isChecked]) => {
            var _a;
            if (isChecked) {
                const disabilityInfo = props.availableDisabilities.find(availableDisability => availableDisability.id === key);
                ids.push(...((_a = disabilityInfo === null || disabilityInfo === void 0 ? void 0 : disabilityInfo.notCombinedWithAny) !== null && _a !== void 0 ? _a : []));
            }
        });
        return ids;
    }, [props.passengerDisabilitiesState]);
    return (React.createElement("div", { className: cn(theme.disabilities, { [theme.disabilities_error]: props.isError }) },
        React.createElement("div", { className: theme.disabilities__text },
            React.createElement("div", null, InfoDetails),
            React.createElement("div", null, t('Passengers with disabilities and other persons with reduced mobility and persons accompanying them shall board the aircraft before boarding'))),
        React.createElement("div", { className: theme.checkboxes }, props.availableDisabilities.map(disability => (React.createElement(Field, { name: `${props.fieldNameBasePath}.values.${disability.id}`, initialValue: false, type: 'checkbox', key: disability.id, render: ({ input }) => (React.createElement(FormControlLabel, { label: disability.name, control: React.createElement(Checkbox, { color: "primary", classes: {
                        colorPrimary: theme.checkbox,
                        checked: theme.checkbox_checked,
                        disabled: theme.checkbox_disabled,
                        root: theme.checkbox_root
                    }, checked: input.checked, onChange: input.onChange, disabled: idsToDisable.includes(disability.id) }) })) }))))));
});
export default List;
