import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { get, SEATS_RECOMMENDATION_SHOWN, set } from '../../../../cache';
import { MotivationGoal, reachGoal } from '../../../../analytics';
import { useMediaQuery } from 'react-responsive';
import { TABLET_MIDDLE_WIDTH } from '../../../../utils';
export const SeatsRecommendContext = React.createContext({});
const STICKY_HEADER_HEIGHT = 80;
const SeatsRecommendation = ({ confirmRecommendedSeatsWithoutDialog, orderId, travellers, segments, runServiceRequest, setSeat, fillOrder, children, segmentIndex, passengerId, isEnabled, seatMap }) => {
    var _a, _b, _c, _d;
    const [isOpen, setIsOpen] = useState(false);
    const rowsMap = {};
    const isMobile = useMediaQuery({ maxWidth: TABLET_MIDDLE_WIDTH });
    const firstSegmentId = segments === null || segments === void 0 ? void 0 : segments[0].id;
    const firstPreselected = (_d = (_c = (_b = (_a = travellers === null || travellers === void 0 ? void 0 : travellers[0]) === null || _a === void 0 ? void 0 : _a.preselectedServices) === null || _b === void 0 ? void 0 : _b.seats) === null || _c === void 0 ? void 0 : _c.find(seat => seat.segment.id === firstSegmentId)) === null || _d === void 0 ? void 0 : _d.seat;
    const onOpen = () => {
        setIsOpen(true);
        set(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_${segmentIndex}`, true);
    };
    useEffect(() => {
        if (confirmRecommendedSeatsWithoutDialog) {
            onConfirm();
        }
    }, [confirmRecommendedSeatsWithoutDialog]);
    useEffect(() => {
        setIsOpen(false);
        if (!(get(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_${segmentIndex}`) ||
            get(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_ALL`)) &&
            isEnabled &&
            !confirmRecommendedSeatsWithoutDialog) {
            if (travellers.some(traveller => traveller.preselectedServices.seats.length)) {
                onOpen();
            }
        }
    }, [isEnabled, travellers, segmentIndex]);
    const needToScrollToRecommendedSeat = typeof confirmRecommendedSeatsWithoutDialog === 'boolean' && confirmRecommendedSeatsWithoutDialog;
    const onDisagree = useCallback(() => {
        setIsOpen(false);
        reachGoal(MotivationGoal.RefuseProposedSeat);
    }, []);
    const onConfirm = useCallback(() => {
        const seat = document.querySelector(`[data-seat-number="${firstPreselected === null || firstPreselected === void 0 ? void 0 : firstPreselected.number}"]`);
        const STICKY_HEADER_HEIGHT = 80;
        const HALF_OF_WINDOW = window.innerHeight / 2;
        setIsOpen(false);
        if (runServiceRequest) {
            const request = travellers.map(({ preselectedServices, id }) => {
                return {
                    passengerId: id,
                    seats: preselectedServices.seats.map(({ seat, segment }) => ({
                        isConfirmed: false,
                        isChangeable: true,
                        row: parseInt(seat.row),
                        letter: seat.letter,
                        seat: null,
                        segment,
                        type: null,
                        product: null
                    }))
                };
            });
            runServiceRequest(request, fillOrder);
        }
        else if (setSeat) {
            travellers.map(({ preselectedServices, id }) => {
                preselectedServices.seats
                    .filter(it => {
                    if (confirmRecommendedSeatsWithoutDialog) {
                        return true;
                    }
                    else {
                        return it.segment.id === segmentIndex.toString();
                    }
                })
                    .map(seat => {
                    setSeat([
                        {
                            number: seat.seat.number,
                            row: seat.seat.row,
                            price: seat.seat.price,
                            letter: seat.seat.letter,
                            service: null,
                            rfisc: null,
                            isAvailable: null,
                            isAisle: null,
                            isExistent: null,
                            isSmoking: null,
                            isRecline: null,
                            seatService: null,
                            seatServices: null,
                            services: [],
                            restrictions: null,
                            permissions: []
                        }
                    ], id, seat.segment.id);
                });
            });
        }
        if (firstPreselected && seat && window.hasOwnProperty('scrollTo') && needToScrollToRecommendedSeat) {
            window.scrollTo({
                top: seat.getBoundingClientRect().top - STICKY_HEADER_HEIGHT - HALF_OF_WINDOW,
                behavior: 'smooth'
            });
        }
        if (confirmRecommendedSeatsWithoutDialog) {
            set(`${SEATS_RECOMMENDATION_SHOWN}_${orderId}_ALL`, true);
        }
        reachGoal(MotivationGoal.ConfirmProposedSeat);
    }, [travellers, fillOrder, confirmRecommendedSeatsWithoutDialog, needToScrollToRecommendedSeat, segmentIndex]);
    travellers === null || travellers === void 0 ? void 0 : travellers.forEach(({ preselectedServices, id: passengerId }) => preselectedServices.seats.forEach(seat => {
        const id = seat.segment.id;
        if (rowsMap[id]) {
            rowsMap[id].price.amount += seat.seat.price.amount;
            rowsMap[id].seats[passengerId] = seat;
            rowsMap[id].seatsMap[seat.seat.number] = seat.seat;
        }
        else {
            rowsMap[id] = {
                price: Object.assign({}, seat.seat.price),
                seats: {
                    [passengerId]: seat
                },
                seatsMap: {
                    [seat.seat.number]: seat.seat
                }
            };
        }
    }));
    const scrollToPreselectedSeat = () => {
        var _a, _b;
        const seat = (_b = (_a = rowsMap[segmentIndex.toString()]) === null || _a === void 0 ? void 0 : _a.seats) === null || _b === void 0 ? void 0 : _b[passengerId];
        const HALF_OF_WINDOW = !isMobile ? window.innerHeight / 2 + STICKY_HEADER_HEIGHT : 0;
        if (seat === null || seat === void 0 ? void 0 : seat.seat) {
            const seatDOM = document.querySelector(`[data-seat-number="${seat.seat.number}"]`);
            window.scrollTo({
                top: seatDOM.getBoundingClientRect().top - HALF_OF_WINDOW + window.scrollY,
                behavior: 'smooth'
            });
        }
    };
    React.useEffect(() => {
        if (isOpen) {
            setTimeout(scrollToPreselectedSeat);
        }
    }, [segmentIndex, passengerId, isOpen]);
    return (React.createElement(SeatsRecommendContext.Provider, { value: {
            isOpen,
            onOpen,
            onConfirm,
            onDisagree,
            rowsMap: rowsMap,
            isEnabled,
            availableSeatsCount: seatMap.segments[segmentIndex].availableSeatsCount
        } }, children));
};
export default SeatsRecommendation;
