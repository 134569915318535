import { __awaiter } from "tslib";
import React, { useCallback } from 'react';
import { useTheme } from '../../../../theme';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '../../../../Link';
import { Field, Form } from 'react-final-form';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { WelcomeIcon } from '../../../Icons';
import SubmitButton from '../SubmitButton/SubmitButton';
import { useConfig } from '../../../../context';
import cn from 'classnames';
import { LoginType, useSendSecureCodeMutation } from '@websky/graphql';
const RegistrationForm = ({ login, loginType, onSuccess, onError }) => {
    const { RegistrationForm: css } = useTheme('LoginPage');
    const { t } = useTranslation('LoginPage');
    const [sendCode, { loading }] = useSendSecureCodeMutation();
    const { global: { companyInfo } } = useConfig();
    const validate = useCallback((values) => {
        const errors = {};
        if (!values.agree) {
            errors['agree'] = t('Please, read the rules of service and accept it');
        }
        return errors;
    }, []);
    const onSubmit = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (loginType === LoginType.FFP) {
            onSuccess();
        }
        else {
            const data = yield sendCode({
                variables: {
                    params: {
                        login,
                        loginType
                    }
                }
            });
            if (data.data.SendSecureCode.result) {
                onSuccess();
            }
            else {
                onError('Something went wrong');
            }
        }
    }), [onSuccess, login, loginType]);
    return (React.createElement("div", { className: css.root },
        React.createElement("div", { className: css.title },
            React.createElement("span", { className: css.icon_wrapper }, WelcomeIcon),
            t('Welcome!')),
        React.createElement("div", { className: css.message }, t('You are logged in for the first time, please read the terms and conditions and give consent')),
        React.createElement(Form, { validate: validate, onSubmit: onSubmit, render: ({ handleSubmit }) => (React.createElement("form", { onSubmit: handleSubmit },
                React.createElement("div", { className: css.row },
                    React.createElement("div", { className: css.input_wrapper },
                        React.createElement(Field, { name: "agree", type: "checkbox", render: ({ input, meta }) => {
                                const notValid = !!meta.error && meta.touched;
                                return (React.createElement(FormControl, { error: notValid },
                                    React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { className: cn(css.checkbox, {
                                                [css.checked]: input.checked
                                            }), color: "primary", checked: input.checked, onChange: input.onChange }), label: React.createElement("span", { className: css.label },
                                            t('I agree to the'),
                                            ' ',
                                            React.createElement(Link, { target: "_blank", action: companyInfo.privacyPolicy }, t('Terms of Service and Privacy Policy'))) }),
                                    notValid ? (React.createElement(FormHelperText, { className: css.error }, meta.error)) : null));
                            } })),
                    React.createElement("div", { className: css.button_wrapper },
                        React.createElement(SubmitButton, { loading: loading }, t('Continue')))))) })));
};
export default RegistrationForm;
