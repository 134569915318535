import { ApolloLink } from 'apollo-boost';
import { UTM_MARKS_KEY, sessionStorageGet, sessionStorageSet } from './cache';
const paximizerUTMLink = new ApolloLink((operation, forward) => {
    const utmMarksFromStorage = sessionStorageGet(UTM_MARKS_KEY);
    if (!(utmMarksFromStorage === null || utmMarksFromStorage === void 0 ? void 0 : utmMarksFromStorage.source)) {
        const searchParams = new URLSearchParams(window.location.search);
        if (searchParams.get('utm_source') === 'paximizer') {
            let utmMarks = {};
            for (const [key, value] of searchParams.entries()) {
                if (key.startsWith('utm_')) {
                    utmMarks = Object.assign(Object.assign({}, utmMarks), { [key.replace('utm_', '')]: value });
                }
            }
            sessionStorageSet(UTM_MARKS_KEY, utmMarks);
        }
    }
    return forward(operation);
});
export default paximizerUTMLink;
