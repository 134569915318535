import * as React from 'react';
import { useContext } from 'react';
import cn from 'classnames';
import { useTheme } from '../../../theme';
import { useTranslation } from 'react-i18next';
import { OrderCardContext } from '../OrderCard';
import Button from '../../../Button';
import { Download, Included, InfoDetails } from '../../../Icons';
import Money from '../../../Money';
import CommonItem from '../CommonItem/CommonItem';
import { OrderStatus } from '@websky/graphql';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../../../utils';
const OrderBooking = ({ className, onDownloadTicket, hidePrice }) => {
    var _a, _b;
    const { OrderBooking: css } = useTheme('OrderCard');
    const { t } = useTranslation('OrderCard');
    const { order, type } = useContext(OrderCardContext);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const onDownloadTicketHandler = e => {
        e.preventDefault();
        onDownloadTicket();
    };
    return (React.createElement(CommonItem, { icon: type === 'review' ? InfoDetails : order.status === OrderStatus.Confirmed ? Included : null, type: type, iconClassName: css.icon, className: cn(css.root, className, {
            [css.type_review]: type === 'review',
            [css.type_account]: type === 'account'
        }), text: React.createElement(React.Fragment, null,
            type === 'account' && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.orderCode },
                    t('Booking ID'),
                    ":",
                    ' ',
                    React.createElement("span", { className: css.value }, order.locator ? order.locator : order.id)))),
            type === 'review' && (React.createElement(React.Fragment, null,
                React.createElement("span", { className: css.orderCode },
                    t('Booking ID'),
                    ":",
                    ' ',
                    React.createElement("span", { className: css.value }, order.locator ? order.locator : order.id)),
                React.createElement("span", { className: css.security_code },
                    t('Security code'),
                    ": ",
                    React.createElement("span", { className: css.value }, order.accessCode)),
                !hidePrice && (React.createElement("span", { className: css.price },
                    t('Total'),
                    ": ",
                    React.createElement(Money, { money: (_a = order.price) === null || _a === void 0 ? void 0 : _a.total })))))), buttons: type === 'review'
            ? order.status === OrderStatus.Confirmed && (React.createElement("div", { className: css.download_wrapper },
                React.createElement(Button, { size: 'small', className: css.download, onClick: onDownloadTicketHandler },
                    t('Download'),
                    Download)))
            : !isMobile &&
                !hidePrice && (React.createElement(React.Fragment, null,
                t('Total'),
                ":",
                React.createElement(Money, { money: (_b = order.price) === null || _b === void 0 ? void 0 : _b.total }))) }));
};
export default OrderBooking;
