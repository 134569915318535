import * as React from 'react';
import { useCallback, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CheckinOrderStatus } from '@websky/graphql';
import { getBaggage, getBaggagePrice, getBaggageServices, getCheckinBrandIncludedBaggage, getUpgradeBaggage, getAdditionalBaggage, getSpecialEquipment, getOverweightBaggage, getCheckinOrder, getPassengersNames, getSegmentsForServices, getTravellers, getTotalPrice } from '../../../../store/order/selectors';
import { useSteps } from '../../../../utils';
import { CheckinStep } from '../../../../types';
import BaggagePage from '../../../../../../BaggagePage/components/BaggagePage';
import { Money } from '../../../../../../Money';
import { useTheme } from '../../../../../../theme';
import ServicesToolbar from '../../../../../../ServicesToolbar';
import { addService, equalizePassengerBaggage, removeService, replaceService, saveServices, setServices } from '../../../../store/selectedServices/actions';
import { getSelectedBaggage } from '../../../../store/selectedServices/selectors';
import SimpleLoader from '../../../../../../SimpleLoader';
import { useConfig } from '../../../../../../context';
import { useGetGoBack } from '../utils';
const Baggage = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    const order = useSelector(getCheckinOrder);
    const totalPrice = useSelector(getTotalPrice);
    const baggagePriceToPay = useSelector(getBaggagePrice);
    const travellers = useSelector(getTravellers);
    const segments = useSelector(getSegmentsForServices);
    const baggageServices = useSelector(getBaggageServices);
    const baggage = useSelector(getBaggage);
    const passengersNames = useSelector(getPassengersNames);
    const selectedBaggage = useSelector(getSelectedBaggage);
    const includedBaggage = useSelector(getCheckinBrandIncludedBaggage);
    const upgradeBaggage = useSelector(getUpgradeBaggage);
    const additionalBaggage = useSelector(getAdditionalBaggage);
    const specialBaggage = useSelector(getSpecialEquipment);
    const overweightBaggage = useSelector(getOverweightBaggage);
    const { goToNextStep } = useSteps();
    const { t } = useTranslation('Checkin');
    const { Checkin: css } = useTheme('Checkin');
    const listOfSegmentIds = segments.map(seg => seg.id);
    const { global: { dynamicBaggagePricing } } = useConfig();
    const passengerLabels = passengersNames.reduce((result, { id, name }) => {
        result[id] = name;
        return result;
    }, {});
    const servicesByPassengers = travellers.map(traveller => {
        var _a, _b, _c;
        return ({
            id: traveller.id,
            services: (_c = (_b = (_a = traveller.services) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services.map(({ count, serviceId, segmentIds, confirmedCount }) => ({
                count,
                segmentIds,
                serviceId,
                confirmedCount
            }))) !== null && _c !== void 0 ? _c : []
        });
    });
    const onIncrease = useCallback((baggage, segmentId, passengerId, forAllSegments) => {
        setIsLoading(true);
        const segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        const canBeAddedForAllSegments = !baggage.segmentIds.some(segmentIds => segmentIds.length > 1 && segmentIds.includes(segmentId));
        batch(() => {
            if (canBeAddedForAllSegments) {
                segmentIds.forEach(segmentId => {
                    dispatch(addService({
                        service: baggageServices.find(({ id }) => id === baggage.id),
                        segmentId,
                        passengerId
                    }));
                });
            }
            else {
                dispatch(addService({
                    service: baggageServices.find(({ id }) => id === baggage.id),
                    segmentId,
                    passengerId
                }));
            }
        });
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    const onDecrease = useCallback((baggage, segmentId, passengerId, forAllSegments) => {
        setIsLoading(true);
        const segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        batch(() => {
            segmentIds.forEach(segmentId => {
                dispatch(removeService({
                    service: baggageServices.find(({ id }) => id === baggage.id),
                    segmentId,
                    passengerId
                }));
            });
        });
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    const onReplace = useCallback((oldBaggage, newBaggage, segmentId, passengerId, forAllSegments) => {
        setIsLoading(true);
        const segmentIds = forAllSegments ? listOfSegmentIds : [segmentId];
        dispatch(replaceService({
            passengerId,
            segmentsList: new Set(segmentIds),
            oldService: baggageServices.find(({ id }) => id === oldBaggage.id),
            newService: baggageServices.find(({ id }) => id === newBaggage.id)
        }));
        dispatch(saveServices());
        setIsLoading(false);
    }, [baggageServices]);
    const onModeChange = useCallback((passengerId) => {
        setIsLoading(true);
        dispatch(equalizePassengerBaggage({
            passengerId,
            baggageList: new Set(baggageServices.map(({ id }) => id)),
            segmentsList: new Set(listOfSegmentIds)
        }));
        setIsLoading(false);
    }, [baggageServices]);
    const setAdditionalBaggage = useCallback((services) => {
        dispatch(setServices(services));
        dispatch(saveServices());
    }, []);
    const goBack = useGetGoBack(CheckinStep.Baggage);
    return (React.createElement(React.Fragment, null,
        React.createElement(ServicesToolbar, { toolbarProps: {
                totalPrice,
                onBackButtonClick: goBack,
                onButtonClick: () => goToNextStep(CheckinStep.Baggage),
                additional: baggagePriceToPay.amount ? (React.createElement("div", { className: css.servicePrice },
                    t('Baggage'),
                    ": ",
                    React.createElement(Money, { money: baggagePriceToPay }))) : null
            }, mobileToolbarProps: {
                onContinue: () => goToNextStep(CheckinStep.Baggage),
                onBack: goBack
            } },
            React.createElement(BaggagePage, { baggage: baggage, includedBaggage: includedBaggage, upgradeBaggage: upgradeBaggage, additionalBaggage: additionalBaggage, specialBaggage: specialBaggage, overweightBaggage: overweightBaggage, selectedBaggage: selectedBaggage, passengers: servicesByPassengers, passengerLabels: passengerLabels, segments: segments, onIncrease: onIncrease, onDecrease: onDecrease, onReplace: onReplace, onModeChange: onModeChange, setAdditionalBaggage: setAdditionalBaggage, disabled: order.status === CheckinOrderStatus.Expired, dynamicBaggagePricing: dynamicBaggagePricing })),
        isLoading && React.createElement(SimpleLoader, null)));
};
export default Baggage;
