import { SET_SERVICES_IS_SAVING, SET_SERVICES_SAVED } from './actions';
const initialServicesIsSavingState = false;
export const servicesIsSavingReducer = (state = initialServicesIsSavingState, action) => {
    switch (action.type) {
        case SET_SERVICES_IS_SAVING:
            return true;
        case SET_SERVICES_SAVED:
            return false;
    }
    return initialServicesIsSavingState;
};
