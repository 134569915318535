import { StepType } from './types';
import { StepsEnum } from '../types';
import { seatMapHasAnySeats } from '../SeatMap/utils';
import { BaggageType, OrderAdditionalServiceGdsServiceServiceType, OrderStatus, PaymentStatusEnum } from '@websky/graphql';
import { removeServicesStepsByTypes } from './utils';
const SeatsStep = {
    url: 'seats',
    type: StepsEnum.Seats
};
const BaggageStep = {
    url: 'baggage',
    type: StepsEnum.Baggage
};
const MealStep = {
    url: 'meal',
    type: StepsEnum.Meal
};
const PassengersStep = {
    url: 'passengers',
    type: StepsEnum.Passengers
};
const InsuranceStep = {
    url: 'insurance',
    type: StepsEnum.Insurance
};
const OrderStep = {
    url: 'order',
    type: StepsEnum.Order
};
const PaymentStep = {
    url: 'payment',
    type: StepsEnum.Payment
};
export const DEFAULT_STEPS_CONFIG = [
    SeatsStep,
    BaggageStep,
    MealStep,
    PassengersStep,
    InsuranceStep,
    OrderStep,
    PaymentStep
];
export const SHOW_SERVICES_AFTER_BOOKING_STEPS = [
    PassengersStep,
    SeatsStep,
    BaggageStep,
    MealStep,
    InsuranceStep,
    OrderStep,
    PaymentStep
];
export const PRICE_FREEZE_STEPS_CONFIG = [PassengersStep, OrderStep, PaymentStep];
const isSeatsAvailable = (order, availableServices) => {
    var _a, _b;
    return ((seatMapHasAnySeats(order.flight.seatMap) &&
        availableServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Seat)) ||
        ((_b = (_a = order.flight.seatMap) === null || _a === void 0 ? void 0 : _a.segments) === null || _b === void 0 ? void 0 : _b.some(segment => segment.isSeatsAvailableInCheckinOnly)));
};
const isInsuranceAvailable = (order) => {
    var _a, _b, _c, _d;
    if (!((_b = (_a = order.additionalServices.insurances) === null || _a === void 0 ? void 0 : _a.insurancePrograms) === null || _b === void 0 ? void 0 : _b.length) ||
        !((_d = (_c = order.additionalServices) === null || _c === void 0 ? void 0 : _c.insurances) === null || _d === void 0 ? void 0 : _d.separateStep)) {
        return false;
    }
    return order.status !== OrderStatus.Confirmed;
};
const isMealsAvailable = (order, availableServices) => {
    var _a, _b, _c, _d, _e, _f;
    const mealServices = (_d = (_c = (_b = (_a = order.additionalServices) === null || _a === void 0 ? void 0 : _a.gdsServices) === null || _b === void 0 ? void 0 : _b.services) === null || _c === void 0 ? void 0 : _c.filter(service => (service === null || service === void 0 ? void 0 : service.type) === OrderAdditionalServiceGdsServiceServiceType.Meal)) !== null && _d !== void 0 ? _d : [];
    const segmentsIds = (_f = (_e = order.flight) === null || _e === void 0 ? void 0 : _e.segments) === null || _f === void 0 ? void 0 : _f.map(segment => { var _a; return (_a = segment === null || segment === void 0 ? void 0 : segment.segment) === null || _a === void 0 ? void 0 : _a.id; });
    const hasAvailableMealServices = mealServices.some(meal => { var _a; return (_a = meal === null || meal === void 0 ? void 0 : meal.allowedSegments) === null || _a === void 0 ? void 0 : _a.some(groups => groups === null || groups === void 0 ? void 0 : groups.some(segment => segmentsIds.includes(segment))); });
    return (availableServices.hasOwnProperty(OrderAdditionalServiceGdsServiceServiceType.Meal) && hasAvailableMealServices);
};
const isBaggageAvailable = (availableServices, configServicesAvailable) => {
    const animalAsService = !!(configServicesAvailable === null || configServicesAvailable === void 0 ? void 0 : configServicesAvailable.animal);
    return Object.values(BaggageType)
        .filter(type => animalAsService
        ? ![BaggageType.PetInCabin, BaggageType.PetInHold, BaggageType.PetInHandCarrier].includes(type)
        : ![BaggageType.PetInHandCarrier].includes(type))
        .some(type => availableServices.hasOwnProperty(type));
};
const isNeedToSwapPassengersAndOrder = (order) => order.isPriceFreezeProductSelected && order.paymentStatus === PaymentStatusEnum.Part;
const swapPassengersAndOrder = (steps) => {
    const passengersIndex = steps.findIndex(step => step.type === StepsEnum.Passengers), orderIndex = steps.findIndex(step => step.type === StepsEnum.Order);
    if (passengersIndex < orderIndex && passengersIndex >= 0 && orderIndex >= 0) {
        const tmpArray = [...steps];
        tmpArray[passengersIndex] = steps[orderIndex];
        tmpArray[orderIndex] = steps[passengersIndex];
        return tmpArray;
    }
    return steps;
};
const isStepAvailable = (step, params) => {
    var _a;
    const { order, configStepAvailable, hasAvailableSeats, availableServices, isPaymentRequired } = params;
    if (((_a = step === null || step === void 0 ? void 0 : step.conditions) === null || _a === void 0 ? void 0 : _a.bookedOnly) && order.status !== OrderStatus.Booked) {
        return false;
    }
    if ((configStepAvailable === null || configStepAvailable === void 0 ? void 0 : configStepAvailable[step.type]) === false) {
        return false;
    }
    switch (step.type) {
        case StepsEnum.Seats:
            return hasAvailableSeats && isSeatsAvailable(order, availableServices);
        case StepsEnum.Baggage:
            return isBaggageAvailable(availableServices, params.configServicesAvailable);
        case StepsEnum.Insurance:
            return isInsuranceAvailable(order);
        case StepsEnum.Meal:
            return isMealsAvailable(order, availableServices);
        case StepsEnum.Payment:
            return isPaymentRequired;
    }
    return true;
};
const createStepCheckingFunc = (params) => {
    return (step) => isStepAvailable(step, params);
};
export const getSteps = (params) => {
    const isStepAvailable = createStepCheckingFunc(params);
    let filteredSteps = getStepsConfig(params);
    const passengersIndex = filteredSteps.findIndex(step => step.type === StepsEnum.Passengers);
    filteredSteps = filteredSteps.map((step, stepIndex) => (Object.assign(Object.assign({}, step), { hidden: !isStepAvailable(step), disabled: stepIndex <= passengersIndex &&
            params.order.status === OrderStatus.Booked &&
            !params.order.isPriceFreezeProductSelected })));
    if (isNeedToSwapPassengersAndOrder(params.order)) {
        return swapPassengersAndOrder(filteredSteps);
    }
    return filteredSteps;
};
const getStepsConfig = (params) => {
    if (params.order.isPriceFreezeProductSelected &&
        (params.order.status === OrderStatus.New || !params.order.isTravellersDataFilled)) {
        return PRICE_FREEZE_STEPS_CONFIG;
    }
    if (!params.showServicesBeforeBooking) {
        return SHOW_SERVICES_AFTER_BOOKING_STEPS;
    }
    return DEFAULT_STEPS_CONFIG;
};
export const removeStepsWhenRedirectFromMetasearch = (steps) => {
    return removeServicesStepsByTypes(steps, [StepType.Seats, StepType.Meal, StepType.Baggage]);
};
