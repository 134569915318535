import * as React from 'react';
import { useEffect } from 'react';
import cn from 'classnames';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ThanksBanner from '../ThanksBanner/ThanksBanner';
import { MOBILE_MIN_WIDTH, scrollTo } from '../../../../utils';
import { StepType } from '../../../types';
import { useTheme } from '../../../../theme';
import { fillOrder as fillOrderAction } from '../../../store/order/actions';
import { getAnimalServices, getIsBooked, getIsBookedOrConfirmed, getIsCancelled, getIsConfirmed, getIsConfirmedOrCancelled, getIsFareLockConfirmed, getIsFareLockSelected, getIsReadonly, getIsTravellersDataFilled, getOrder, getIsNeedToHideFlightPrice } from '../../../store/order/selectors';
import WarningModal from '../../../../WarningModal';
import { useCheckoutServicesCardsAvailability } from '../../../../CheckoutAdditionalService/components/hooks';
import SimpleLoader from '../../../../SimpleLoader';
import OrderCard from '../../../../OrderCard';
import { Aeroexpress, Animal, Baggage, Loyalty, Meal, Seats, Transfer, Business, PriorityBoarding, CapsuleHotel, BaggageDelivery, UpgradeFlightClass } from '../../../../CheckoutAdditionalService/components';
import { FlightSegmentStatus, InfoMessagePositionsEnum } from '@websky/graphql';
import { OrderIsArchivedTicketDownloadError, useDownloadOrderDocument } from '../hooks';
import { useFareLock } from '../../../../FareLock/hooks';
import FareLock from '../../../../FareLock';
import SelectedFlight from '../SelectedFlight/SelectedFlight';
import { WarningRounded } from '../../../../Icons';
import { useConfig } from '../../../../context';
import PassengerToolbar from '../Form/PassengersToolbar/PassengersToolbar';
import { OverrideComponent, useRenderers } from '../../../../renderProps';
import Consents from '../../../../Consents';
import OrderExareStatus from '../OrderExareStatus/OrderExareStatus';
import getOrderDataLayer from '../../../../DataLayerAnalytics/orderDataLayerInstance';
import WebskyServicesAnalytics from '../../../../DataLayerAnalytics/WebskyServicesAnalytics';
import { useIsCheckinAvailable } from '../../../hooks';
import OrderInsuranceBlockSlot from '../OrderInsuranceBlockSlot/OrderInsuranceBlockSlot';
// import ServicesV2 from '../ServicesV2/ServicesV2';
import InfoMessages from '../../../../InfoMessages';
const Order = ({ setBookingError, bookingError, showServiceCards = true, goToExchange, goToRefund, goToChangePassenger, userInfo, refetchUser, currentStep, paymentSuccess, setStep, setNextStep }) => {
    var _a, _b, _c;
    const css = useTheme('Checkout').CheckoutStyles;
    const { t } = useTranslation('Checkout');
    const servicesAvailability = useCheckoutServicesCardsAvailability();
    const order = useSelector(getOrder);
    const isTravellersDataFilled = useSelector(getIsTravellersDataFilled);
    const noAvailableServices = Object.keys(servicesAvailability).every(key => servicesAvailability[key] === false);
    const isFareLockSelected = useSelector(getIsFareLockSelected);
    const isFareLockConfirmed = useSelector(getIsFareLockConfirmed);
    const animalServices = useSelector(getAnimalServices);
    const isCancelled = useSelector(getIsCancelled);
    const isReadonly = useSelector(getIsReadonly);
    const isBooked = useSelector(getIsBooked);
    const isBookedOrConfirmed = useSelector(getIsBookedOrConfirmed);
    const isConfirmed = useSelector(getIsConfirmed);
    const isConfirmedOrCancelled = useSelector(getIsConfirmedOrCancelled);
    const isNeedToHideFlightPrice = useSelector(getIsNeedToHideFlightPrice);
    const { CheckoutServices } = useRenderers();
    const dispatch = useDispatch();
    const fillOrder = (orderData) => dispatch(fillOrderAction(orderData));
    const hasAnimal = ((_b = (_a = useConfig()) === null || _a === void 0 ? void 0 : _a.global.servicesAvailable) === null || _b === void 0 ? void 0 : _b.animal) === true;
    const isCheckinAvailable = useIsCheckinAvailable(order);
    const clearError = () => setBookingError(null);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const [showThanksBanner, setShowThanksBanner] = React.useState(false);
    useEffect(() => {
        if (paymentSuccess) {
            setShowThanksBanner(true);
        }
    }, [paymentSuccess]);
    useEffect(() => {
        // if there is no available services in mobile, redirect to payment
        if (Object.keys(servicesAvailability).every(key => servicesAvailability[key] === false) &&
            isBooked &&
            isMobile) {
            setStep(StepType.Payment);
        }
    }, [isBooked]);
    useEffect(() => {
        if (isBooked) {
            getOrderDataLayer().beginCheckout(WebskyServicesAnalytics.getCheckoutSelectedServices(order));
        }
    }, [isBooked, order.isPriceFreezeProductSelected]);
    const { handleChangeFareLock, isSelected, price, fareLockService } = useFareLock(order, fillOrder);
    const canceledFlights = React.useMemo(() => {
        return order.flight.segmentGroups.filter(group => group.segments.some(segment => segment.status === FlightSegmentStatus.Canceled));
    }, [order.flight]);
    const { loading: orderDocDownloading, handleDownload, error: docError, warningIsOpen: docWarningIsOpen, warningClose: docWarningClose } = useDownloadOrderDocument();
    useEffect(() => {
        scrollTo(0);
    }, []);
    return (React.createElement("div", { className: cn(css.checkout, {
            [css.checkout_margined]: isCancelled
        }) },
        orderDocDownloading && React.createElement(SimpleLoader, null),
        React.createElement(WarningModal, { title: t('Oops...'), content: t('An unexpected error has occurred during the booking process. Please try again later or contact a customer support service.'), errorMessage: bookingError ? bookingError.message.replace('GraphQL error: ', '') : '', onClose: clearError, onButtonClick: clearError, buttonText: t('OK, thank you'), isOpen: !!bookingError }),
        React.createElement(WarningModal, { title: t('Oops...'), content: docError instanceof OrderIsArchivedTicketDownloadError ? (React.createElement("div", { dangerouslySetInnerHTML: { __html: t(docError.message) } })) : (t('An unexpected error has occurred during the tickets downloading process. Please try again later or contact a customer support service.')), errorMessage: docError && !(docError instanceof OrderIsArchivedTicketDownloadError)
                ? docError.message.replace('GraphQL error: ', '')
                : '', onClose: docWarningClose, onButtonClick: docWarningClose, buttonText: t('OK, thank you'), isOpen: !!docWarningIsOpen }),
        order.messages && !isCancelled && (React.createElement(React.Fragment, null,
            React.createElement(InfoMessages, { messages: order.messages, position: [InfoMessagePositionsEnum.default, InfoMessagePositionsEnum.bookingTop], className: css.topInfoMessage, itemHtmlClassName: css.topInfoMessage__htmlMessage, itemClassName: css.messages_item }),
            React.createElement("div", { className: css.messages },
                React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutPassengersNotifications: () => null } })))),
        isConfirmed && showThanksBanner && React.createElement(ThanksBanner, null),
        isConfirmed && canceledFlights.length > 0 && (React.createElement("div", { className: css.notice },
            WarningRounded,
            ' ',
            t('Attention, times have changed for flight {{route}}', {
                route: `${canceledFlights[0].segments[0].departure.airport.city.name} - ${canceledFlights[0].segments[canceledFlights[0].segments.length - 1].arrival.airport.city
                    .name}`
            }))),
        React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutOrderAdditionalBanner: () => null } }),
        React.createElement(OverrideComponent, { componentProps: { locator: order.locator }, component: { CheckoutOrderExtraHeader: () => null } }),
        !isReadonly && (React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
            React.createElement("div", { className: css.passengers__header },
                React.createElement("h1", { className: css.passengers__header__title }, t('Passengers'))))),
        React.createElement(OrderExareStatus, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger }),
        isConfirmedOrCancelled && (React.createElement("div", { className: css.paidOrderWrapper },
            React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, onDownloadClick: handleDownload, order: order, isCheckinOpen: isCheckinAvailable, type: 'review', hidePrice: isNeedToHideFlightPrice }))),
        isBooked && (React.createElement("div", { className: css.summary__wrp },
            React.createElement(MediaQuery, { minWidth: MOBILE_MIN_WIDTH },
                React.createElement("h3", { className: css.summary__header }, t('Review your trip'))),
            !isConfirmed && isFareLockSelected && (React.createElement(FareLock, { freezeUntil: fareLockService.freezeUntil, price: price, onChange: handleChangeFareLock, isActive: isSelected, confirmed: isSelected || isFareLockConfirmed, addedToOrder: true })),
            React.createElement("div", { className: css.summary },
                React.createElement(OrderCard, { goToRefund: goToRefund, goToExchange: goToExchange, goToChangePassenger: goToChangePassenger, order: order, isCheckinOpen: isCheckinAvailable, type: 'review' })))),
        (isBookedOrConfirmed || isConfirmedOrCancelled) && (React.createElement(OverrideComponent, { component: { CheckoutSelectedFlight: SelectedFlight }, componentProps: {
                flight: order.flight,
                confirmed: isConfirmedOrCancelled,
                orderId: order.id,
                hidePrice: isNeedToHideFlightPrice
            } })),
        React.createElement(InfoMessages, { messages: order.messages, position: InfoMessagePositionsEnum.serviceTop, className: css.infoMessage, itemHtmlClassName: css.infoMessage__htmlMessage }),
        showServiceCards && isBookedOrConfirmed && !noAvailableServices && isTravellersDataFilled && (React.createElement(React.Fragment, null, Object.values(servicesAvailability).includes(true) && (React.createElement("div", { className: css.checkoutServices }, CheckoutServices ? (React.createElement(CheckoutServices, { userInfo: userInfo, updateUser: refetchUser })) : (React.createElement(React.Fragment, null,
            servicesAvailability.upgradeFlightClass && React.createElement(UpgradeFlightClass, null),
            servicesAvailability.Seat && React.createElement(Seats, { isCheckinAvailable: isCheckinAvailable }),
            servicesAvailability.Baggage && React.createElement(Baggage, null),
            servicesAvailability.Meal && React.createElement(Meal, null),
            servicesAvailability.Insurance && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutInsurance: OrderInsuranceBlockSlot } })),
            servicesAvailability.Aeroexpress && React.createElement(Aeroexpress, null),
            servicesAvailability.Transfer && React.createElement(Transfer, null),
            ((_c = order === null || order === void 0 ? void 0 : order.ffpInfo) === null || _c === void 0 ? void 0 : _c.ffpSupported) && (React.createElement(OverrideComponent, { component: { CheckoutLoyalty: Loyalty }, componentProps: {
                    userInfo,
                    refetchUser
                } })),
            servicesAvailability.BusinessLounge && (React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutBusiness: Business } })),
            servicesAvailability.PriorityBoarding && React.createElement(PriorityBoarding, null),
            servicesAvailability.BusinessLounge && React.createElement(CapsuleHotel, null),
            hasAnimal && animalServices.length > 0 && React.createElement(Animal, null),
            servicesAvailability.BaggageDelivery && React.createElement(BaggageDelivery, null),
            React.createElement(InfoMessages, { renderMessagesWithoutWrapper: true, htmlClassName: css.bookingAncillaries__root, itemHtmlClassName: css.bookingAncillaries__item, messages: order.messages, position: [InfoMessagePositionsEnum.bookingAncillaries], className: css.topInfoMessage }))))))),
        React.createElement(InfoMessages, { messages: order.messages, position: InfoMessagePositionsEnum.serviceBottom, className: css.infoMessage }),
        React.createElement(OverrideComponent, { componentProps: {}, component: { CheckoutOrderPreConsentsContent: () => null } }),
        React.createElement(Consents, null, ({ onConfirm }) => (React.createElement(PassengerToolbar, { currentStep: currentStep, setNextStep: setNextStep, onConfirmConsents: onConfirm })))));
};
export default Order;
