import { onError } from 'apollo-link-error';
import { SHOW_RECAPTCHA, captureErrorSentry, captureEventSentry } from './utils';
import { ApolloErrorType } from './types';
export const APOLLO_ERRORS_EVENT = 'apollo-errors-event';
export const ACCESS_DENIED_ERROR_EVENT = 'access-denied-error-event';
const ignoredOperations = new Set(['GetTravellerProfiles']);
const seatErrorRegExp = new RegExp(`^Seat \\S+ not available`);
const SeatNotAvailable = 'Selected seat is not available';
export const pnrBlockedRegExp = /The PNR is blocked by another user \(.*\). Please try again later\./m;
const pnrBlockedMessage = 'The service is temporarily unavailable. It is possible that the order is open on another device/browser or being handled by an airline specialist. Please try again later.';
const isAccessDeniedError = (error) => {
    return Array.isArray(error) ? error.some(e => /Access denied/.test(e)) : false;
};
const isIgnoredErrors = (graphQLErrors) => {
    return graphQLErrors.some(err => {
        if (isAccessDeniedError(err)) {
            return true;
        }
        return false;
    });
};
const errorLink = onError(({ networkError, operation, graphQLErrors, response, forward }) => {
    if (networkError && networkError['statusCode']) {
        const statusCode = parseInt(networkError['statusCode']);
        if (statusCode === 429) {
            document.dispatchEvent(new CustomEvent(SHOW_RECAPTCHA));
        }
        else if (statusCode >= 500) {
            if (!ignoredOperations.has(operation.operationName) && !isIgnoredErrors(graphQLErrors)) {
                const event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: `${operation.operationName}: ${networkError.message ? networkError.message : `Server responded with code = ${statusCode}.`}`
                    }
                });
                captureEventSentry(event);
                document.dispatchEvent(event);
            }
        }
    }
    if ((graphQLErrors === null || graphQLErrors === void 0 ? void 0 : graphQLErrors.length) > 0) {
        graphQLErrors.forEach(err => {
            if (pnrBlockedRegExp.test(err.message)) {
                const event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: pnrBlockedMessage,
                        type: ApolloErrorType.PnrBlocked
                    }
                });
                document.dispatchEvent(event);
            }
            else if (seatErrorRegExp.test(err.message)) {
                const event = new window.CustomEvent(APOLLO_ERRORS_EVENT, {
                    detail: {
                        operationName: operation.operationName,
                        message: SeatNotAvailable
                    }
                });
                document.dispatchEvent(event);
            }
            else if (isAccessDeniedError(err)) {
                const event = new window.CustomEvent(ACCESS_DENIED_ERROR_EVENT);
                document.dispatchEvent(event);
                captureErrorSentry('Access denied error');
            }
        });
    }
});
export default errorLink;
