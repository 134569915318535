import { __rest } from "tslib";
import React from 'react';
import { useTheme } from '../../theme';
import { useTranslation } from 'react-i18next';
import { initI18n } from '../../utils';
import ModalX from '../../BaseComponents/ModalX';
import classNames from 'classnames';
import { BaseButton } from '../../BaseComponents';
initI18n('ModalConfirm');
const ModalConfirm = (_a) => {
    var _b;
    var { classes, title, cancelBtn, confirmBtn, customButtons, hideFooter = false, hideTitle = false, children, scroll, isOpen, onClose, onCancel, onConfirm, ignoreClose, disableConfirmBtn, buttonsExtra, isLoading } = _a, props = __rest(_a, ["classes", "title", "cancelBtn", "confirmBtn", "customButtons", "hideFooter", "hideTitle", "children", "scroll", "isOpen", "onClose", "onCancel", "onConfirm", "ignoreClose", "disableConfirmBtn", "buttonsExtra", "isLoading"]);
    const theme = (_b = useTheme('ModalConfirm')) === null || _b === void 0 ? void 0 : _b.ModalConfirm;
    const { t } = useTranslation('ModalConfirm');
    const hideCancelBtn = !onCancel;
    const hideConfirmBtn = !onConfirm;
    function ConfirmButton() {
        if (confirmBtn && typeof confirmBtn !== 'string') {
            return confirmBtn;
        }
        return (React.createElement(BaseButton, { variant: "smallPrimary", isLoading: isLoading, classes: {
                root: classNames(theme.confirmButton, classes === null || classes === void 0 ? void 0 : classes.confirmButton),
                disabled: theme.disabled
            }, onClick: onConfirm, disabled: disableConfirmBtn }, !confirmBtn ? t('Confirm') : confirmBtn));
    }
    function CancelButton() {
        if (cancelBtn && typeof cancelBtn !== 'string') {
            return cancelBtn;
        }
        return (React.createElement(BaseButton, { variant: "smallSecondary", disabled: isLoading, classes: {
                root: classNames(theme.cancelButton, classes === null || classes === void 0 ? void 0 : classes.cancelButton),
                disabled: theme.disabled
            }, onClick: onCancel }, !cancelBtn ? t('Cancel') : cancelBtn));
    }
    function Buttons() {
        if (customButtons) {
            return (React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons, theme[props.size]) }, customButtons));
        }
        return (React.createElement("div", { className: classNames(theme.buttons, classes === null || classes === void 0 ? void 0 : classes.buttons, theme[props.size]) },
            !hideCancelBtn && React.createElement(CancelButton, null),
            !hideConfirmBtn && React.createElement(ConfirmButton, null)));
    }
    function Title() {
        return (React.createElement("div", { className: classNames(theme.header, classes === null || classes === void 0 ? void 0 : classes.header) },
            React.createElement("div", { className: classNames(theme.title, classes === null || classes === void 0 ? void 0 : classes.title) }, title)));
    }
    function Footer() {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: classNames(theme.footer, classes === null || classes === void 0 ? void 0 : classes.footer) },
                buttonsExtra && (React.createElement("div", { className: classNames(theme.buttonsExtra, classes === null || classes === void 0 ? void 0 : classes.buttonsExtra) }, buttonsExtra)),
                React.createElement(Buttons, null))));
    }
    return (React.createElement(ModalX, Object.assign({ isOpen: isOpen, onClose: onClose, ignoreClose: ignoreClose, scroll: scroll, classes: Object.assign(Object.assign({}, theme), { paper: classNames(theme.paper, classes === null || classes === void 0 ? void 0 : classes.paper), closeButton: classes === null || classes === void 0 ? void 0 : classes.closeButton, swipeBlock: classes === null || classes === void 0 ? void 0 : classes.swipeBlock }), title: title && !hideTitle && React.createElement(Title, null), footer: !hideFooter && React.createElement(Footer, null) }, props), children && React.createElement("div", { className: theme.content }, children)));
};
export default ModalConfirm;
