import * as React from 'react';
import { useTheme } from '../theme';
import Dialog from '../Service/components/Dialog/Dialog';
import InsuranceInner from './InsuranceInner/InsuranceInner';
import Link from '../Link';
import { useMediaQuery } from 'react-responsive';
import { MOBILE_MIN_WIDTH } from '../utils';
import MobileScreen from './MobileScreen/MobileScreen';
import MUIDialog from '@material-ui/core/Dialog/Dialog';
import { useTranslation } from 'react-i18next';
import { Button } from '../index';
import SimpleLoader from '../SimpleLoader';
import { WxEvents } from '../DataLayerAnalytics/types';
import WebskyAnalyticsApollo from '../DataLayerAnalytics/WebskyAnalyticsApollo';
import { getInsurancesDiff } from './utils';
const initReducer = (state) => {
    return state;
};
const insuranceReducer = (state, action) => {
    if (action.type === 'add') {
        return Object.assign(Object.assign({}, state), { selectedInsurances: [...state.selectedInsurances, action.payload] });
    }
    else if (action.type === 'remove') {
        return Object.assign(Object.assign({}, state), { selectedInsurances: state.selectedInsurances.filter(insurance => insurance.code !== action.payload.code) });
    }
    else if (action.type === 'reset') {
        return initReducer(action.payload);
    }
    else if (action.type === 'set_document') {
        const payload = action.payload;
        return Object.assign(Object.assign({}, state), { passengers: state.passengers.map(passenger => (Object.assign(Object.assign({}, passenger), { value: passenger.id === payload.id ? payload.value : passenger.value, isValid: passenger.id === payload.id ? payload.isValid : passenger.isValid }))) });
    }
    else if (action.type === 'validate_documents') {
        return Object.assign(Object.assign({}, state), { passengers: state.passengers.map(passenger => (Object.assign(Object.assign({}, passenger), { isValid: new RegExp(passenger.regex.error).test(passenger.value) || !passenger.regex }))) });
    }
    return state;
};
export const infoIcon = (React.createElement("svg", { width: "20", height: "20", viewBox: "0 0 20 20", fill: "none", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("g", { opacity: "0.7" },
        React.createElement("path", { fillRule: "evenodd", clipRule: "evenodd", d: "M1.66699 9.99996C1.66699 5.39996 5.40033 1.66663 10.0003 1.66663C14.6003 1.66663 18.3337 5.39996 18.3337 9.99996C18.3337 14.6 14.6003 18.3333 10.0003 18.3333C5.40033 18.3333 1.66699 14.6 1.66699 9.99996ZM10.8337 9.16663V14.1666H9.16699V9.16663H10.8337ZM10.0003 16.6666C6.32532 16.6666 3.33365 13.675 3.33365 9.99996C3.33365 6.32496 6.32532 3.33329 10.0003 3.33329C13.6753 3.33329 16.667 6.32496 16.667 9.99996C16.667 13.675 13.6753 16.6666 10.0003 16.6666ZM10.8337 5.83329V7.49996H9.16699V5.83329H10.8337Z", fill: "currentColor" }))));
const Insurance = props => {
    var _a, _b;
    const inititalState = {
        selectedInsurances: props.selectedInsurances.map(selected => selected.insuranceProgram),
        passengers: (_b = (_a = props.passengers) === null || _a === void 0 ? void 0 : _a.map(passenger => (Object.assign(Object.assign({}, passenger), { isValid: !passenger.regex || !passenger.value || new RegExp(passenger.regex.pattern).test(passenger.value) })))) !== null && _b !== void 0 ? _b : []
    };
    const { t } = useTranslation('AdditionalServiceCard');
    const [emptyDocumentError, setDocumentError] = React.useState(false);
    const [reducer, dispatcher] = React.useReducer(insuranceReducer, inititalState, initReducer);
    const theme = useTheme('Insurance').InsuranceStyles;
    React.useEffect(() => {
        dispatcher({
            type: 'reset',
            payload: inititalState
        });
    }, [props.selectedInsurances, props.passengers]);
    const isMobile = useMediaQuery({ maxWidth: MOBILE_MIN_WIDTH });
    const totalPrice = React.useMemo(() => {
        var _a, _b;
        const price = {
            amount: 0,
            currency: (_b = (_a = props.insurances[0]) === null || _a === void 0 ? void 0 : _a.totalPrice) === null || _b === void 0 ? void 0 : _b.currency
        };
        reducer.selectedInsurances.forEach(insurance => {
            var _a;
            price.amount += (_a = insurance.totalPrice) === null || _a === void 0 ? void 0 : _a.amount;
        });
        return price;
    }, [reducer]);
    React.useEffect(() => {
        if (props.open) {
            WebskyAnalyticsApollo.dispatchWxViewEvent(WxEvents.InsurancesList, {
                servicesIds: props.insurances.map(insurance => insurance.code)
            });
        }
    }, [props.open]);
    const renderContent = () => {
        return (React.createElement(React.Fragment, null,
            React.createElement(InsuranceInner, { passengers: reducer.passengers, insurances: props.insurances, selectedInsurances: reducer.selectedInsurances, dispatcher: dispatcher, documentNeeds: props.documentNeeds, documentError: emptyDocumentError, error: props.error }),
            props.loading && React.createElement(SimpleLoader, null),
            !isMobile && (React.createElement("div", null,
                React.createElement("div", { className: theme.controls },
                    React.createElement("div", { className: theme.buttons },
                        React.createElement(Button, { onClick: onClear, className: theme.dialog__button_clear }, t('Clear')),
                        React.createElement(Button, { onClick: onClose, className: theme.dialog__button_done }, t('Done'))))))));
    };
    const onClose = () => {
        if (!props.documentNeeds ||
            !reducer.selectedInsurances.length ||
            reducer.passengers.every(document => (document.value && document.isValid) || !document.isRequired)) {
            const insurancesDiff = getInsurancesDiff(inititalState.selectedInsurances, reducer.selectedInsurances), passengerDocChanged = inititalState.passengers
                .filter(passenger => passenger.isRequired)
                .some(passenger => {
                const newDocValue = reducer.passengers.find(({ id }) => id === passenger.id);
                return newDocValue.value !== passenger.value;
            });
            if (insurancesDiff.added.length > 0 || insurancesDiff.removed.length > 0 || passengerDocChanged) {
                props.onSave(reducer);
            }
            else {
                props.onClose();
            }
            setDocumentError(false);
        }
        else {
            setDocumentError(true);
            dispatcher({
                type: 'validate_documents',
                payload: null
            });
        }
    };
    const onClear = () => {
        const clearedState = Object.assign(Object.assign({}, reducer), { selectedInsurances: [] });
        dispatcher({
            type: 'reset',
            payload: clearedState
        });
        props.onSave(clearedState);
    };
    return isMobile ? (React.createElement(MUIDialog, { open: props.open, onClose: onClose, fullScreen: true, classes: {
            paper: theme.mobileWrapper
        } },
        React.createElement(MobileScreen, { content: renderContent(), onClose: onClose, rulesURL: props.rulesURL }))) : (React.createElement(Dialog, { dialogType: isMobile ? 'fullscreen' : 'popup', content: renderContent(), dialogHeader: t('Insurance'), totalPrice: totalPrice, bodyScroll: true, dialogClassName: theme.dialog, dialogPopupHeaderClassName: theme.dialog__header, rules: React.createElement(Link, { target: '_blank', action: props.rulesURL, className: theme.rules },
            infoIcon,
            t('Terms of service')), isOpen: props.open, onClose: props.onClose, onClear: onClear, withoutControls: true }));
};
export default Insurance;
