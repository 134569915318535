import { createAction } from 'redux-actions';
export const CLEAR = 'CLEAR';
export const DIFFERENT_FOR_RETURN_FLIGHT = 'DIFFERENT_FOR_RETURN_FLIGHT';
export const REMOVE_SERVICES = 'REMOVE_SERVICES';
export const DISABLE = 'DISABLE';
export const SET_SERVICES = 'SET_SERVICES';
export const SET_BAGGAGE_TAB = 'SET_BAGGAGE_TAB';
export const SET_ALL_SERVICES = 'SET_ALL_SERVICES';
export const clear = createAction(CLEAR);
export const differentForReturnFlight = createAction(DIFFERENT_FOR_RETURN_FLIGHT);
export const disable = createAction(DISABLE);
export const setServices = createAction(SET_SERVICES);
export const setBaggageTab = createAction(SET_BAGGAGE_TAB);
export const setAllServices = createAction(SET_ALL_SERVICES);
