import * as React from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useTheme } from '../theme';
import { initI18n } from '../utils';
import { BaseButton } from '../BaseComponents';
initI18n('MobileToolbar');
const MobileToolbar = ({ onBack, onContinue, continueButtonText, disabled, className, classes }) => {
    const { MobileToolbar: css } = useTheme('MobileToolbar');
    const { t } = useTranslation('MobileToolbar');
    return (React.createElement("div", { className: cn(css.wrapper, className) },
        !!onBack && (React.createElement(BaseButton, { variant: "smallSecondary", className: cn(css.button, css.button_back, classes === null || classes === void 0 ? void 0 : classes.backButton), onClick: onBack }, t('Back'))),
        typeof onContinue !== 'undefined' && (React.createElement(BaseButton, { type: onContinue ? 'button' : 'submit', variant: "smallPrimary", className: cn(css.button, css.button_continue, classes === null || classes === void 0 ? void 0 : classes.continueButton), disabled: disabled, onClick: onContinue, "data-next-button": true }, continueButtonText !== null && continueButtonText !== void 0 ? continueButtonText : t('Continue')))));
};
export default MobileToolbar;
